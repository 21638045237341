/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  IHmoDetails - a model defined in OpenAPI      id: The id of this IHmoDetails [Optional].     patient_hmo_code: The patient_hmo_code of this IHmoDetails [Optional].     hmo_code: The hmo_code of this IHmoDetails [Optional].     hmo_id: The hmo_id of this IHmoDetails [Optional].     hmo_name: The hmo_name of this IHmoDetails [Optional].
 */
export interface IHmoDetails { 
    /**
     * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  IAntenatalEnrollmentPatientId - a model defined in OpenAPI
     */
    _id?: object;
    patientHmoCode?: string;
    hmoCode?: string;
    hmoId?: string;
    hmoName?: string;
}

