/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AppointmentServiceDto { 
    id?: number;
    service_name: string;
    category?: object;
    category_key?: string;
    department_route?: string;
    grouped_fields?: object;
    sub_category?: object;
    sub_category_key?: string;
    service_type?: object;
    service_key?: string;
    packages?: Array<object>;
    slug?: string;
    service_cost?: number;
    sex?: object;
    follow_up_cost?: number;
    service_validity_in_days?: number;
    special_cost?: object;
    age_range?: object;
    findings?: Array<object>;
    percentage_discount?: number;
    fields?: Array<object>;
    preset?: boolean;
    presets_data?: Array<object>;
    accessType?: object;
    createdAt?: string;
    updatedAt?: string;
}

