import { createReducer, on } from "@ngrx/store";
import { VisitorRequestDto } from "proxy-sdk";
import { fetchedVisitors, generatingVisitorCodeStatus, loadingVisitors, singleVisitor, totalVisitors } from "./visitors.actions";

const visitorReducer = createReducer(
  {} as VisitorRequestDto,
  on(singleVisitor, (state, props) => {
    return props.visitor;
  })
);

const totalVisitorsReducer = createReducer(
  0,
  on(totalVisitors, (state, props) => {
    return props.total;
  })
);

const fetchingVisitorsReducer = createReducer(
  false,
  on(loadingVisitors, (state, props) => {
    return props.loading;
  })
);
const generatingCodeReducer = createReducer(
  false,
  on(generatingVisitorCodeStatus, (state, props) => {
    return props.loading;
  })
);

const visitorsReducer = createReducer(
  [] as VisitorRequestDto[],
  on(fetchedVisitors, (state, props) => {
    return props.visitors;
  })
);

export const visitorsReducers = {
  visitor: visitorReducer,
  visitors: visitorsReducer,
  generatingVisitor: generatingCodeReducer,
  fetchingVisitors: fetchingVisitorsReducer,
  totalVisitors: totalVisitorsReducer
};

export interface VisitorsReducers {
  visitor: VisitorRequestDto;
  visitors: VisitorRequestDto[];
  generatingVisitor: boolean;
  fetchingVisitors: boolean;
  totalVisitors: number;
}

