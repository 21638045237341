/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  KioskPinChangeData - a model defined in OpenAPI      old_pin: The old_pin of this KioskPinChangeData.     new_pin: The new_pin of this KioskPinChangeData.     hospital_number: The hospital_number of this KioskPinChangeData.
 */
export interface KioskPinChangeData { 
    old_pin: string;
    new_pin: string;
    hospital_number: string;
}

