import { createAction, props } from '@ngrx/store';
import {
  Bill,
  FetchBillsDto,
  FetchBillsResponse,
  FetchWalletResponse,
  InitPaymentDto,
  InitPaymentResponse,
  PaymentDto,
} from 'proxy-sdk';

export const fetchingBills = createAction(
  'FetchingBills',
  props<{ status: boolean }>()
);

export const fetchBills = createAction(
  'FetchBills',
  props<{ startDate: Date; endDate: Date }>()
);

export const fetchBeneficiaryBills = createAction(
  'FetchBeneficiaryBills',
  props<{ hospitalNumber: string; startDate: Date; endDate: Date }>()
);

export const storeBills = createAction(
  'SaveBills',
  props<{ groupedBills: FetchBillsResponse[] }>()
);

export const storeBeneficiaryBills = createAction(
  'SaveBeneficiaryBills',
  props<{ groupedBills: FetchBillsResponse[] }>()
);

export const initPayment = createAction(
  'InitPayment',
  props<{ paymentInfo: InitPaymentDto }>()
);

export const payWithWallet = createAction(
  'PayWithWallet',
  props<{ paymentInfo: PaymentDto }>()
);

export const payWithWalletBeneficiary = createAction(
  'payWithWalletBenfeiciary',
  props<{ paymentInfo: PaymentDto, hospital_number: string }>()
);

export const storeInitPaymentResp = createAction(
  'StoreInitPaymentInfo',
  props<{ paymentInfo: InitPaymentResponse }>()
);

export const fetchWallets = createAction('FetchWallets');

export const storeWallets = createAction(
  'SaveWallets',
  props<{ wallets: FetchWalletResponse['list'] }>()
);

export const fetchingWallets = createAction(
  'FetchingWallets',
  props<{ status: boolean }>()
);

export const fetchWalletTransactions = createAction('FetchWalletTransactions');

export const storeWalletTransactions = createAction(
  'SaveWalletsTransactions'
  // props<{ walletsTansactions: FetchWalletTransactionsResponse['list'] }>()
);

export const fetchingWalletTransactions = createAction(
  'FetchingWalletTransactions',
  props<{ status: boolean }>()
);
