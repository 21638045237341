/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  IPatientAppointmentsAndExpirationsInner - a model defined in OpenAPI      type_of_consultation: The type_of_consultation of this IPatientAppointmentsAndExpirationsInner [Optional].     practitioner_full_name: The practitioner_full_name of this IPatientAppointmentsAndExpirationsInner [Optional].     practitioner_id: The practitioner_id of this IPatientAppointmentsAndExpirationsInner [Optional].     expiration_date: The expiration_date of this IPatientAppointmentsAndExpirationsInner [Optional].     appointment_id: The appointment_id of this IPatientAppointmentsAndExpirationsInner [Optional].     department_route: The department_route of this IPatientAppointmentsAndExpirationsInner [Optional].     service_id: The service_id of this IPatientAppointmentsAndExpirationsInner.     id: The id of this IPatientAppointmentsAndExpirationsInner [Optional].
 */
export interface IPatientAppointmentsAndExpirationsInner { 
    typeOfConsultation?: string;
    practitionerFullName?: string;
    practitionerId?: string;
    expirationDate?: string;
    appointmentId?: string;
    departmentRoute?: string;
    serviceId: string;
    _id?: string;
}

