import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reasonForAppointment'
})
export class ReasonForAppointmentPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'new-appointment':
        return 'New Appointment';
      case 'full-health-check':
        return 'Full Health Check';
      case 'investigation':
        return 'Investigation';
      case 'follow-up-appointment':
        return 'Follow Up Appointment';
      case 'treatment':
        return 'Treatment';
        case 'admission/procedure':
        return 'Admission / Procedure';
        case 'emergency':
        return 'Emergency';
      default:
        return '';
    }
  }

}
