/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Bill { 
    id: number;
    status: object;
    txType?: object;
    paymentMethod?: object;
    online_reference?: string;
    payment_gateway?: object;
    invoiceNumber: number;
    amount: number;
    quantity: number;
    itemId?: string;
    itemName: string;
    itemCode?: string;
    customerId?: string;
    customerName?: string;
    customerUsername?: string;
    customerEmail?: string;
    customerEmailVerified?: boolean;
    customerPhone?: string;
    customerSex?: string;
    departmentRoute?: string;
    hospitalNumber: string;
    appointmentId?: string;
    originalAmount?: number;
    hmoAmount?: number;
    hmoApprovalCode?: string;
    hmoStatus?: object;
    hmoCode?: string;
    customerHmoCode?: string;
    hmoVerifiedById?: string;
    hmoVerifiedByName?: string;
    customerPackage?: string;
    meta?: object;
    summary?: string;
    receiverId?: string;
    receiverName?: string;
    receiverUsername?: string;
    receivedAt?: string;
    raisedById?: string;
    raisedByName?: string;
    raisedByUsername?: string;
    requestId?: string;
    requestCategory?: string;
    organizationId?: string;
    created: string;
    updated: string;
}

