import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss']
})
export class CardInfoComponent implements OnInit {

  whyPremium = [
    {
      title: "Access to HomeClinic (Mobile App)",
      icon: "➡"
    },
    {
      title: "Online Appointment Booking",
      icon: "➡"
    },
    {
      title: "Online Monitoring of the patient queue for fast Track Services",
      icon: "➡"
    },
    {
      title: "Access to Premium Lounge and Complimentary Services",
      icon: "➡"
    },
    {
      title: "Local/International Access to your Medical Records",
      icon: "➡"
    },
    {
      title: "Access to Savealife HomeClinic Kiosk",
      icon: "➡"
    },
    {
      title: "Access to Digital Vital Assay",
      icon: "➡"
    },
    {
      title: " Access to Hospital Offline Queue",
      icon: "➡"
    },
    {
      title: "Notification Services",
      icon: "➡"
    },
    {
      title: "Access to Savealife HomeClinic Admission",
      icon: "➡"
    },
    {
      title: "Online Payment of App Bills",
      icon: "➡"
    }
  ]

  whyRegular = [
    {
      title: "Access to Savealife HomeClinic Kiosk",
      icon: "➡"
    },
    {
      title: "Access to Digital Vital Assay",
      icon: "➡"
    },
    {
      title: " Access to Hospital Queue",
      icon: "➡"
    },
    {
      title: "Notification Services",
      icon: "➡"
    },
    {
      title: "Access to Savealife HomeClinic Admission",
      icon: "➡"
    },
    {
      title: "Online Payment of App Bills",
      icon: "➡"
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }
}
