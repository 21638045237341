import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AntenatalService } from "proxy-sdk";
import { catchError, map, of, switchMap } from "rxjs";
import { clearAntenatalEnrollment, fetchAntenatalEnrollment, fetchedAntenatalEnrollment } from "./antenatal-enrollment.actions";

@Injectable()
export class AntenatalEnrollmentEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private antenatalService: AntenatalService
  ) {}

  fetchAntenatalEnrollmentService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAntenatalEnrollment),
      switchMap(() => this.antenatalService.antenatalControllerGetValidAntenatalEnrollment().pipe(
        map((data) => fetchedAntenatalEnrollment(data)),
        catchError((error) => {
          return of(clearAntenatalEnrollment());
        })
      ))
    );
  });
}