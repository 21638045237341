import { createReducer, on } from "@ngrx/store";
import { AppointmentDto, PatientData } from "proxy-sdk";
import { fetchedBeneficiaries, fetchedBeneficiary, fetchedBeneficiaryAppointments, fetchingBeneficiaries, fetchingBeneficiary, fetchingBeneficiaryAppointments } from "./beneficiary.actions";

const beneficiariesReducer = createReducer(
    [] as PatientData[],
    on(fetchedBeneficiaries, (state, props) => {
        return props.beneficiaries;
    })
)

const loadingBeneficiariesReducer = createReducer(
    false as boolean,
    on(fetchingBeneficiaries, (state, props) => {
        return props.loading;
    })
)

const beneficiaryReducer = createReducer(
    {} as PatientData,
    on(fetchedBeneficiary, (state, props) => {
        return props.beneficiary;
    })
)

const loadingBeneficiaryReducer = createReducer(
    false as boolean,
    on(fetchingBeneficiary, (state, props) => {
        return props.loading;
    })
)

const beneficiaryAppointmentsReducer = createReducer(
    [] as AppointmentDto[],
    on(fetchedBeneficiaryAppointments, (state, props) => {
        return props.appointments;
    })
)

const loadingBeneficiaryAppointmentsReducer = createReducer(
    false as boolean,
    on(fetchingBeneficiaryAppointments, (state, props) => {
        return props.loading;
    })
)

export const beneficiaryReducers = {
    beneficiariesList: beneficiariesReducer,
    fetchingBeneficiaries: loadingBeneficiariesReducer,
    beneficiary: beneficiaryReducer,
    fetchingBeneficiary: loadingBeneficiaryReducer,
    beneficiaryAppointmentList: beneficiaryAppointmentsReducer,
    fetchingBeneficiaryAppointment: loadingBeneficiaryAppointmentsReducer
}

export interface BeneficiaryReducers {
    beneficiariesList: PatientData[];
    fetchingBeneficiaries: boolean;
    beneficiary: PatientData;
    fetchingBeneficiary: boolean;
    beneficiaryAppointmentList: AppointmentDto[];
    fetchingBeneficiaryAppointment: boolean;
}
