/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateMenuStatusDto { 
    menuId?: number;
    orderId?: number;
    quantity?: number;
    menuCost?: number;
    status?: CreateMenuStatusDto.StatusEnum;
    rating?: number;
    updated_by_full_name?: string;
    comment?: string;
    updated_by_staff_id?: string;
    completed_at?: string;
}
export namespace CreateMenuStatusDto {
    export type StatusEnum = 'pending' | 'accepted' | 'inprogress' | 'completed' | 'delivered';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Accepted: 'accepted' as StatusEnum,
        Inprogress: 'inprogress' as StatusEnum,
        Completed: 'completed' as StatusEnum,
        Delivered: 'delivered' as StatusEnum
    };
}


