/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  INextOfKinDetails - a model defined in OpenAPI      id: The id of this INextOfKinDetails [Optional].     next_of_kin_fname: The next_of_kin_fname of this INextOfKinDetails.     next_of_kin_lname: The next_of_kin_lname of this INextOfKinDetails.     next_of_kin_sex: The next_of_kin_sex of this INextOfKinDetails.     next_of_kin_email: The next_of_kin_email of this INextOfKinDetails.     next_of_kin_phone_number: The next_of_kin_phone_number of this INextOfKinDetails.     next_of_kin_occupation: The next_of_kin_occupation of this INextOfKinDetails.     next_of_kin_address: The next_of_kin_address of this INextOfKinDetails.     next_of_kin_relationship: The next_of_kin_relationship of this INextOfKinDetails.
 */
export interface INextOfKinDetails { 
    /**
     * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  IAntenatalEnrollmentPatientId - a model defined in OpenAPI
     */
    _id?: object;
    nextOfKinFname: string;
    nextOfKinLname: string;
    nextOfKinSex: string;
    nextOfKinEmail: string;
    nextOfKinPhoneNumber: string;
    nextOfKinOccupation: string;
    nextOfKinAddress: string;
    nextOfKinRelationship: string;
}

