import { createReducer, on } from '@ngrx/store';
import {
  endVideoCall,
  VideoCallData,
  startVideoCall,
  GetVideoLinkSuccess,
  GetVideoLinkFailure,
  GetVideoLink,
  JoinMeeting,
  LeaveMeeting,
} from './video-call.actions';

export const videoCallStateReducer = createReducer(
  {} as VideoCallData,
  on(startVideoCall, (state, props) => {
    console.log('Starting call', props);
    return props;
  }),
  on(endVideoCall, (state, props) => {
    return { loading: false };
  }),
  on(GetVideoLink, (state, props) => {
    return { ...props, loading: true };
  }),
  on(GetVideoLinkFailure, (state, props) => {
    return { ...props, loading: false };
  })
);

export const joinMeetingReducer = createReducer(
  {} as { meetingId: string; attendantId: string, participantType: 'guest' | 'host' },
  on(JoinMeeting, (state, props) => {
    return { ...props };
  }),
  on(LeaveMeeting, (state) => {
    return {} as any;
  })
);

export const videoLinkSuccessReducer = createReducer(
  {} as {
    token?: string;
    link?: string;
    originalLink?: string;
    error?: boolean;
  },
  on(GetVideoLinkSuccess, (state, props) => {
    return { ...props };
  }),
  on(endVideoCall, (state, props) => {
    return {};
  })
);

export const videoCallReducers = {
  videoCallData: videoCallStateReducer,
  videoLinkSuccess: videoLinkSuccessReducer,
  joinMeetingState: joinMeetingReducer,
};

export interface VideoCallState {
  videoCallData: VideoCallData;
  videoLinkSuccess: {
    token?: string;
    link?: string;
    originalLink?: string;
    error?: boolean;
  };
  joinMeetingState: {
    meetingId: string;
    attendantId: string;
    participantType: 'guest' | 'host';
  };
}
