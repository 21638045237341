/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateEmergencyRecord { 
    name: string;
    sex: CreateEmergencyRecord.SexEnum;
    phone: string;
    age_bracket?: CreateEmergencyRecord.AgeBracketEnum;
    email?: string;
    _long?: number;
    lat?: number;
    address?: string;
    description?: string;
    assigned_staff_identity?: string | null;
    assigned_staff_name?: string | null;
    assigned_driver_identity?: string | null;
    assigned_driver_name?: string | null;
    assigned_driver_long?: number | null;
    assigned_driver_lat?: number | null;
    staff_note?: string | null;
}
export namespace CreateEmergencyRecord {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type AgeBracketEnum = '0-9' | '10-19' | '20-29' | '30-39' | '40-49' | '50-59' | '60-69' | '70-79' | '80+';
    export const AgeBracketEnum = {
        _09: '0-9' as AgeBracketEnum,
        _1019: '10-19' as AgeBracketEnum,
        _2029: '20-29' as AgeBracketEnum,
        _3039: '30-39' as AgeBracketEnum,
        _4049: '40-49' as AgeBracketEnum,
        _5059: '50-59' as AgeBracketEnum,
        _6069: '60-69' as AgeBracketEnum,
        _7079: '70-79' as AgeBracketEnum,
        _80: '80+' as AgeBracketEnum
    };
}


