/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CopySurveySurveyRecordFilterProps } from './copySurveySurveyRecordFilterProps';


export interface CopyFetchSurveyRecords { 
    /**
     * A String value
     */
    end_date?: string;
    filter?: CopySurveySurveyRecordFilterProps;
    /**
     * A string value
     */
    limit?: number;
    /**
     * A string value
     */
    order?: string;
    /**
     * A string value
     */
    order_field?: string;
    /**
     * A numeric value
     */
    page?: number;
    /**
     * A String value
     */
    search_string?: string;
    /**
     * A String value
     */
    start_date?: string;
}

