import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, of, switchMap } from 'rxjs';
import * as FeatureActions from './emergency.actions';
import { Store } from '@ngrx/store';
import {
  CreateEmergencyRecord,
  CreateEmergencyRecordResponseDto,
  EmergencyService,
  FilteredEmergencyRecord,
} from 'proxy-sdk';
import { TypedAction } from '@ngrx/store/src/models';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class EmergencyEffect {
  constructor(
    private httpClient: HttpClient,
    private emergencyService$: EmergencyService,
    private actions$: Actions,
    public route: Router,
    private store: Store
  ) {}

  fetchCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FeatureActions.fetchEmergencyCategories),
      switchMap((data) =>
        this.httpClient
          .get('https://api.drsavealifeapp.com/browse-emergency-category')
          .pipe(
            map((data) =>
              FeatureActions.fetchEmergencyCategoriesSuccess({
                emergencyCategories: (data as any) ? (data as any).d : [],
              })
            ),
            catchError((error) =>
              of(FeatureActions.fetchEmergencyCategoriesFailure({ error }))
            )
          )
      )
    );
  });

  runGuestEmergency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureActions.storeGuestEmergencyRequest),
      switchMap((d) => {
        this.store.dispatch(
          FeatureActions.processingGuestEmergencyRequest({ loading: true })
        );
        return this.emergencyService$
          .emergencyControllerCreateEmergencyRecord(d.emergency)
          .pipe(
            map((val) => {
              this.store.dispatch(
                FeatureActions.processingGuestEmergencyRequest({
                  loading: false,
                })
              );
              let returnAction:
                | ({
                    emergency: FilteredEmergencyRecord;
                  } & TypedAction<'GuestEmergencyRequestSuccessful'>)
                | ({
                    emergency: { message: string };
                  } & TypedAction<'GuestEmergencyRequestFailed'>);

              if (val?.data?.id) {
                Swal.fire({
                  // position: 'top-end',
                  icon: 'success',
                  iconColor: '#1e88e5',
                  text: 'Please wait. We will contact you shortly',
                  showConfirmButton: false,
                  confirmButtonColor: '#1e88e5',
                  timer: 6000,
                });
                this.route.navigateByUrl('/?emergency=yes');

                returnAction = FeatureActions.guestEmergencyRequestSuccessful({
                  emergency: val.data,
                });
              } else {
                Swal.fire({
                  // position: 'top-end',
                  icon: 'error',
                  iconColor: '#ff1223',
                  text: 'Something Went Wrong',
                  showConfirmButton: false,
                  confirmButtonColor: '#1e88e5',
                  timer: 2000,
                });
                returnAction = FeatureActions.guestEmergencyRequestFailed({
                  emergency: {
                    message: val.error?.message || 'Could not create request',
                  },
                });
              }
              return returnAction;
            }),
            catchError((e) => {
              this.store.dispatch(
                FeatureActions.processingGuestEmergencyRequest({
                  loading: false,
                })
              );
              Swal.fire({
                // position: 'top-end',
                icon: 'error',
                iconColor: '#ff1223',
                text: 'Something Went Wrong',
                showConfirmButton: false,
                confirmButtonColor: '#1e88e5',
                timer: 2000,
              });

              return of(
                FeatureActions.guestEmergencyRequestFailed({
                  emergency: {message: "Could not create request"},
                })
              );
            })
          );
      })
    )
  );

  fetchEmergencies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FeatureActions.fetchEmergencies),
      switchMap((data) =>
        this.httpClient
          .get(
            data.categoryId
              ? 'https://api.drsavealifeapp.com/filter-emergencies'
              : 'https://api.drsavealifeapp.com/browse-emergency',
            {
              params: {
                data: JSON.stringify({ emergencyCategory: data.categoryId }),
              },
            }
          )
          .pipe(
            map((data) =>
              FeatureActions.fetchEmergenciesSuccess({
                emergencies: (data as any) ? (data as any).d : [],
              })
            ),
            catchError((error) =>
              of(FeatureActions.fetchEmergenciesFailure({ error }))
            )
          )
      )
    );
  });
}
