<div>
  <h4>Rate {{ data.staff_name }}'s performance during your visit.</h4>
  <div>
    <mat-icon *ngFor="let rating of ratings" (click)="selectRating(rating)" color="primary">
    {{ rating > selected_rating ? 'star_border': 'star'}}
    </mat-icon>
  </div>
  <hr />

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Write Your Comment Here</mat-label>
    <textarea matInput placeholder="Write Your Comment Here" [(ngModel)]="message" name="review_text"></textarea>
  </mat-form-field>
  <div class="p-2" fxLayout="row" fxLayoutAlign="center center">
    <button *ngIf="!loading else loader" mat-raised-button color="primary" (click)="rateStaff()">
      Rate
    </button>
    <ng-template  #loader>
      <mat-spinner [diameter]="20"></mat-spinner>
    </ng-template>
  </div>
</div>