/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Menu } from './menu';
import { TimeTable } from './timeTable';


export interface TimeGroup { 
    id: number;
    name: string;
    description?: string;
    start_hour: number;
    start_min: number;
    end_hour: number;
    end_min: number;
    priority?: number;
    days_available?: Array<number>;
    start_time?: string;
    end_time?: string;
    status?: TimeGroup.StatusEnum;
    menus: Array<Menu>;
    timeTables: Array<TimeTable>;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}
export namespace TimeGroup {
    export type StatusEnum = 'active' | 'inactive';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Inactive: 'inactive' as StatusEnum
    };
}


