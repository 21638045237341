// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  care_url: 'https://develop.drsavealife.com/v1/care-service',
  kiosk_url: 'https://develop.drsavealife.com/v1/kiosk-service-fastapi-test',
  my_home_clinic_url: 'https://develop.drsavealife.com/v1/my-home-clinic',
  proxy: 'https://savealifetechhub.com/mobile-clinic-api',
  // proxy: 'http://192.168.0.165:3000',
  messaging_mode: 'TWILIO', // 'TWILIO' or 'INVIDEO'
  ZEGO_APP_ID: 1484647939, //356424243,

  notification_url: 'https://develop.notification.drsavealife.com',
  firebase: {
    projectId: 'balotest',
    appId: '1:94411470680:web:42559db08fe07ddaa5172d',
    storageBucket: 'balotest.appspot.com',
    apiKey: 'AIzaSyAHnUDUhkdoYKKG77NIY6R5J9WHkSSXfu4',
    authDomain: 'balotest.firebaseapp.com',
    messagingSenderId: '94411470680',
    measurementId: 'G-ZPKWLQHE4G',
  },
  NOTIFICATION_BASE_PATH:
    'https://lobster-app-2-qa6mj.ondigitalocean.app/sal-comms2',
  V_KEY:
    'BNsuOrNQImtJgR4eRUYL3Xfn1BH6PHRAoA4w6zZaYbB3Kc5oSmR6rdsbiPTvfAdNc1ffOCZsgLxtkcm7EsqlDok',
  NOTIFICATION_SOCKET_URL:
    'wss://lobster-app-2-qa6mj.ondigitalocean.app/sal-comms2/notification/ws',
  NOTIFICATION_SOCKET_URL_V2:
    'wss://lobster-app-2-qa6mj.ondigitalocean.app/sal-comms2/notification/ws-v2',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
