import { createReducer, on } from '@ngrx/store';
import {
  hideFullScreenLoader,
  showFullScreenLoader,
  storePushNotificationToken,
} from './general.actions';

const fullScreenLoaderReducer = createReducer(
  { show: false },
  on(showFullScreenLoader, (state) => {
    return { show: true };
  }),
  on(hideFullScreenLoader, (state) => {
    return { show: false };
  })
);

const pushNoficationTokenReducer = createReducer(
  { token: '' },
  on(storePushNotificationToken, (state, props) => {
    return props;
  })
);

export const generalReducers = {
    pushNotificationToken: pushNoficationTokenReducer
};
