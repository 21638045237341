/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartialRating } from './partialRating';


export interface FilterRatingsDto { 
    filter: PartialRating;
    limit: number;
    page: number;
    start_date: string;
    end_date: string;
}

