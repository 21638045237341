/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Rating } from './rating';
import { Approval } from './approval';


export interface CareGiverScheduler { 
    id: number;
    staff_name: string;
    staff_fname: string;
    staff_email: string;
    staff_phone: string;
    staff_identity: string;
    staff_image: string;
    patient_approval: Approval;
    appointment_id: string;
    rating: number;
    _rating: Rating;
    created: string;
    updated: string;
    deleted_at: string;
}

