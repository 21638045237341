import { createAction, props } from '@ngrx/store';
import { CreateEmergencyRecord, FilteredEmergencyRecord } from 'proxy-sdk';

export interface EmergencyCategory {
  _id: string;
  id: string;
  title: string;
}

export interface Emergency {
  _id: string;
  id: string;
  title: string;
  videoUrl: string;
  whatToDo: string;
  whatNotToDo: string;
  emergencyCategory: string;
}

export const fetchEmergencies = createAction(
  '[API] Fetch Emergencies',
  props<{ categoryId?: string }>()
);

export const fetchEmergencyCategories = createAction(
  '[API] Fetch Emergency Categories'
);

export const fetchEmergenciesSuccess = createAction(
  '[API] Fetch Emergencies Success',
  props<{ emergencies: Emergency[] }>()
);

export const fetchEmergencyCategoriesSuccess = createAction(
  '[API] Fetch Emergency Categories Success',
  props<{ emergencyCategories: EmergencyCategory[] }>()
);

export const fetchEmergenciesFailure = createAction(
  '[API] Fetch Emergencies Failure',
  props<{ error: any }>()
);

export const fetchEmergencyCategoriesFailure = createAction(
  '[API] Fetch Emergency Categories Failure',
  props<{ error: any }>()
);

export const storeEmergencyCategories = createAction(
  'Store Categories',
  props<{ categories: EmergencyCategory[] }>()
);

export const storeEmergencies = createAction(
  'Store Emergencies',
  props<{ emergencies: Emergency[] }>()
);
export const storeGuestEmergencyRequest = createAction(
  'Store Guest Emergency',
  props<{ emergency: CreateEmergencyRecord }>()
);
export const processingGuestEmergencyRequest = createAction(
  'processing Guest Emergency Request',
  props<{ loading: boolean }>()
);
export const guestEmergencyRequestSuccessful = createAction(
  'GuestEmergencyRequestSuccessful',
  props<{ emergency: FilteredEmergencyRecord }>()
);

export const guestEmergencyRequestFailed = createAction(
  'GuestEmergencyRequestFailed',
  props<{ emergency: { message: string } }>()
);

export const selectGuide = createAction(
  'Select Guide',
  props<{ emergency: Emergency }>()
);
