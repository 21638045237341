import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AntenatalService } from "proxy-sdk";
import { switchMap, map, catchError, of } from "rxjs";
import { clearBabyMumData, fetchBabyMumData, fetchedBabyMumData, loadingBabyMumDataStatus } from "./baby-mum.actions";

@Injectable()
export class BabyMumEffect {
  constructor(
    private actions$: Actions,
    private antenatalService: AntenatalService,
    private store: Store
  ) {}

  fetchAntenatalEnrollmentService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchBabyMumData),
      switchMap((d) => {
        this.store.dispatch(loadingBabyMumDataStatus({ loading: true }));
        return this.antenatalService.antenatalControllerGetWeeklyAntenatalData({
          limit: 1,
          page: 0,
          filter: {
            week_number: d.week
          }
        }).pipe(
          map((data: any) => {
            this.store.dispatch(loadingBabyMumDataStatus({ loading: false }));
            return fetchedBabyMumData(data.result[0]);
          }),
          catchError((error) => {
            this.store.dispatch(loadingBabyMumDataStatus({ loading: false }));
            return of(clearBabyMumData());
          })
        );
      }
      )
    );
  });
}