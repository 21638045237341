<p>Why I should choose the <span class="title-form">Premium Card</span></p>
<hr>
<mat-dialog-content class="mat-typography">
    <div fxLayout="row" *ngFor="let premium of whyPremium">
        <div fxFlex="10">{{premium.icon}}</div>
        <div fxFlex="90"> {{premium.title}}</div>
    </div>

    <br>
    <p>Why I should choose the <span class="title-form">Regular Card</span></p>
    <hr>

    <div fxLayout="row" *ngFor="let regular of whyRegular">
        <div fxFlex="10">{{regular.icon}}</div>
        <div fxFlex="90"> {{regular.title}}</div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">

    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>👍 I get it</button>
</mat-dialog-actions>