/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RunDiagnosisDto { 
    /**
     * An array of QuestionAnswer keys. E.g if there are 2 questions with keys q1, q2 and the answers given a1, a2 respectively, then the value of qa will be [\'q1a1\', \'q2a2\']
     */
    qa: Array<string>;
    /**
     * An array of ComplaintQuestionAnswer keys. E.g If under a complaint c0, there are 2 questions with keys q1, q2 and the answers given a1, a2 respectively, then the value of qa will be [\'c0q1a1\', \'c0q2a2\']
     */
    cqa: Array<string>;
    /**
     * Determines the degree of accuracy of diagnosis. 0 least precision, 1 most precise. Default value is 0.3
     */
    precision?: number;
    sex?: RunDiagnosisDto.SexEnum;
    lower_age_limit_in_days?: number;
    upper_age_limit_in_days?: number;
}
export namespace RunDiagnosisDto {
    export type SexEnum = 'Male' | 'Female' | '';
    export const SexEnum = {
        Male: 'Male' as SexEnum,
        Female: 'Female' as SexEnum,
        Empty: '' as SexEnum
    };
}


