import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { MaterialModule } from '../material/material.module';
import { LoadingComponent } from './loading/loading.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { ImgFallbackDirective } from './img-fallback.directive';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { TopMenuBarComponent } from './top-menu-bar/top-menu-bar.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { LogoComponent } from './logo/logo.component';
import { RouterModule } from '@angular/router';
import { AgePipe } from './pipe/age.pipe';
import { CalenderComponent } from './calender/calender.component';
import { QuickAccessComponent } from './quick-access/quick-access.component';
import { SalInputComponent } from './sal-input/sal-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardInfoComponent } from './card-info/card-info.component';
import { ReasonForAppointmentPipe } from './pipe/reason-for-appointment.pipe';
import { IncomingCallComponent } from './incoming-call/incoming-call.component';
import { HospitalIdComponent } from './hospital-id/hospital-id.component';
import { RatingStaffDialogComponent } from './rating-staff-dialog/rating-staff-dialog.component';

@NgModule({
  declarations: [
    TopMenuBarComponent,
    StarRatingComponent,
    LoadingComponent,
    BackButtonComponent,
    ImgFallbackDirective,
    SafeHtmlPipe,
    SideNavComponent,
    LogoComponent,
    AgePipe,
    CalenderComponent,
    QuickAccessComponent,
    SalInputComponent,
    CardInfoComponent,
    ReasonForAppointmentPipe,
    IncomingCallComponent,
    HospitalIdComponent,
    RatingStaffDialogComponent,
  ],
  imports: [CommonModule, MaterialModule, RouterModule, ReactiveFormsModule, FormsModule],
  exports: [
    TopMenuBarComponent,
    SideNavComponent,
    LoadingComponent,
    StarRatingComponent,
    BackButtonComponent,
    ImgFallbackDirective,
    SafeHtmlPipe,
    LogoComponent,
    AgePipe,
    CalenderComponent,
    SalInputComponent,
    CardInfoComponent,
    ReasonForAppointmentPipe,
    IncomingCallComponent,
    RatingStaffDialogComponent
  ],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}
