import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    ImmunizationAppointmentData,
  ImmunizationPatientData,
  ImmunizationPatientService,
  PatientData,
  PatientService,
} from 'proxy-sdk';
import {
  fetchImmunizationsVaccine,
  fetchNextImmunizationAppointments,
  fetchPastImmunizationsAppointments,
  fetchedImmunizationsVaccine,
  fetchedNextImmunizationAppointments,
  fetchedPastImmunizationsAppointments,
  fetchingImmunizationsVaccine,
  fetchingNextImmunizationAppointments,
  fetchingPastImmunizationsAppointments,
} from './immunization.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { NewAuthService } from 'src/app/services/new-auth.service';

@Injectable()
export class ImmunizationEffect {
  constructor(
    private action$: Actions,
    private store: Store,
    private immunizationService: ImmunizationPatientService,
  ) {}

  fetchPastImmunizationsAppointmentService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fetchPastImmunizationsAppointments),
      switchMap((prop) => {
        this.store.dispatch(fetchingPastImmunizationsAppointments({ loading: true }));
        return this.immunizationService.immunzationPatientControllerGetImmunizationPatientsAppointments(
            {
                patientId: prop.patientID as any
            } as ImmunizationAppointmentData,
            20,
            0,
            '-created_at',
            undefined,
            undefined,
            undefined,
            new Date().toISOString()
          )
          .pipe(
            map((data) => {
              this.store.dispatch(
                fetchingPastImmunizationsAppointments({ loading: false })
              );
              return fetchedPastImmunizationsAppointments({
                appointments: data.details.data ?? [],
              });
            }),
            catchError((error) => {
                console.log(error);
              this.store.dispatch(
                fetchingPastImmunizationsAppointments({ loading: false })
              );
              return of(fetchedPastImmunizationsAppointments({ appointments: [] }));
            })
          );
      })
    );
  });

  fetchNextImmunizationAppointmentService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fetchNextImmunizationAppointments),
      switchMap((prop) => {
        this.store.dispatch(fetchingNextImmunizationAppointments({ loading: true }));
        return this.immunizationService
          .immunzationPatientControllerGetImmunizationPatientsAppointments(
            {
                patientId: prop.patientID as any
            } as ImmunizationAppointmentData,
            1,
            0,
            'created_at',
            undefined,
            undefined,
            new Date().toISOString(),
            undefined
          )
          .pipe(
            map((data) => {
              this.store.dispatch(fetchingNextImmunizationAppointments({ loading: false }));
              return fetchedNextImmunizationAppointments({ appointment: data.details.data![0] });
            }),
            catchError((error) => {
              this.store.dispatch(fetchingNextImmunizationAppointments({ loading: false }));
              console.log(error);
              return of(fetchedNextImmunizationAppointments({ appointment: {} as any }));
            })
          );
      })
    );
  });
}
