/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VitalsDetailsDto } from './vitalsDetailsDto';
import { VitalsDtoBloodPressure } from './vitalsDtoBloodPressure';


export interface VitalsDto { 
    _id?: string;
    patientFirstName?: string;
    patientLastName?: string;
    patientHospitalNumber: string;
    patientEmail?: string;
    patientPhone?: string;
    patientSex: string;
    patientDateOfBirth?: string;
    patientId?: object;
    appointmentId?: object;
    admissionId?: string;
    temperature?: VitalsDetailsDto;
    weight?: VitalsDetailsDto;
    fhr?: VitalsDetailsDto;
    urinalysis?: Array<VitalsDetailsDto>;
    bloodPressure: VitalsDtoBloodPressure;
    height?: VitalsDetailsDto;
    bmi?: VitalsDetailsDto;
    headCircumference?: VitalsDetailsDto;
    pulseRate?: VitalsDetailsDto;
    respiratoryRate?: VitalsDetailsDto;
    sp02?: VitalsDetailsDto;
    timeTaken: string;
    recordedByUsername: string;
    recordedByFirstName?: string;
    recordedByLastName?: string;
    recordedByDesignation?: string;
    recordedById?: string;
    created_at?: string;
    updated_at?: string;
    error?: string;
    mode?: object;
    machineLocation?: string;
    machineId?: string;
}

