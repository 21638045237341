import { createReducer, on } from '@ngrx/store';
import { AppointmentDto, AppointmentServiceDto } from 'proxy-sdk';
import {
  appointmentActionState, fetchingAppointmentList, storeAppointmentList,
  storeAppointmentServices,
  storeDefaultComplaints
} from './appointment.actions';

const appointmentStateReducer = createReducer(
  { action: 'default' },
  on(appointmentActionState, (state, props) => {
    return { action: props.action };
  })
);

const appointmentServicesReducer = createReducer(
  [] as AppointmentServiceDto[],
  on(storeAppointmentServices, (state, props) => {
    return props.services;
  })
);

const storedDefaultComplaintsReducer = createReducer(
  [] as Record<string, any>,
  on(storeDefaultComplaints, (state, props) => {
    return props.complaints;
  })
);

const storeAppointmentListReducer = createReducer(
  [] as Array<AppointmentDto>,
  on(storeAppointmentList, (state, props) => {
    return props.appointments;
  })
);

const loadingAppointmentListReducer = createReducer(
  false as boolean,
  on(fetchingAppointmentList, (state, props) => {
    return props.loading;
  })
);

export const appointmentReducers = {
  appointmentActionState: appointmentStateReducer,
  appointmentServices: appointmentServicesReducer,
  defaultComplaints: storedDefaultComplaintsReducer,
  appointmentList: storeAppointmentListReducer,
  loadingAppointmentList: loadingAppointmentListReducer
};
