/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CopySurveyRecords { 
    assigned_at?: string;
    assigned_to_id?: string;
    assigned_to_name?: string;
    completed_at?: string;
    completed_by_id?: string;
    completed_by_name?: string;
    createdAt?: string;
    created_at?: string;
    deletedAt?: object;
    dob?: string;
    id?: number;
    last_answered_at?: string;
    last_answered_question_id?: number;
    questions_and_answers?: Array<object>;
    requested_at?: string;
    requested_by_id?: string;
    requested_by_name?: string;
    sex?: object;
    status?: object;
    survey_id: string;
    survey_type: object;
    update_at?: string;
    updatedAt?: string;
    user_id: string;
    user_identity: string;
    user_name?: string;
    user_type: object;
    uuid?: string;
}

