import { createAction, props } from "@ngrx/store";
import { ImmunizationAppointmentData, ImmunizationPatientData, PatientData } from "proxy-sdk";

export const fetchImmunizationsVaccine = createAction(
    'fetchImmunizationsVaccine',
    props<{ appointmentID: string}>()
)

export const fetchingImmunizationsVaccine = createAction(
    'fetchingImmunizationsVaccine',
    props<{loading: boolean}>()
)

export const fetchedImmunizationsVaccine = createAction(
    'fetchedImmunizationsVaccine',
    props<{ immunizations: ImmunizationPatientData[] }>()
)

export const fetchPastImmunizationsAppointments = createAction(
    'fetchPastImmunizationsAppointments',
    props<{ patientID: string}>()
)

export const fetchingPastImmunizationsAppointments = createAction(
    'fetchingPastImmunizationsAppointments',
    props<{loading: boolean}>()
)

export const fetchedPastImmunizationsAppointments = createAction(
    'fetchedPastImmunizationsAppointments',
    props<{ appointments: ImmunizationAppointmentData[] }>()
)

export const fetchNextImmunizationAppointments = createAction(
    'fetchNextImmunizationAppointments',
    props<{ patientID: string}>()
)

export const fetchingNextImmunizationAppointments = createAction(
    'fetchingNextImmunizationAppointments',
    props<{loading: boolean}>()
)

export const fetchedNextImmunizationAppointments = createAction(
    'fetchedNextImmunizationAppointments',
    props<{appointment: ImmunizationAppointmentData}>()
)