import { createAction, props } from "@ngrx/store";
import { AdmissionData } from "proxy-sdk";

export const fetchAdmissionInfo = createAction(
  'fetchAdmissionInfo'
);

export const fetchedAdmissionInfo = createAction(
  'fetchedAdmissionInfo',
  props<{ admission: AdmissionData; }>()
);
