/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilterWeeklyAntenatalDataDto } from './filterWeeklyAntenatalDataDto';


export interface FetchWeeklyAntenatalDataDto { 
    searchString?: string;
    limit?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    dateField?: string;
    filter?: FilterWeeklyAntenatalDataDto;
    select?: string;
    orderField?: string;
    order?: FetchWeeklyAntenatalDataDto.OrderEnum;
}
export namespace FetchWeeklyAntenatalDataDto {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
}


