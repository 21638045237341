import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, Inject, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[appBloodPressure]',
})
export class BloodPressureDirective {
  @Input('cystolic') cystolic = 0;
  @Input('diastolic') diastolic = 0;
  @Input('displayDefaultText') displayDefaultText = true;
  @Input('customText') customText = '';
  @Input('showIcon') showIcon = false;

  iconContainer: ComponentRef<MatIcon> = this.viewContainer.createComponent<MatIcon>(MatIcon);
  textSpan: HTMLElement = this.document.createElement('b');

  constructor(
    private renderer: Renderer2,
    private eleRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: Document,
    private viewContainer: ViewContainerRef
  ) {

  }

  ngAfterViewInit(): void {
    
    this.eleRef.nativeElement.appendChild(this.iconContainer.location.nativeElement);
    this.eleRef.nativeElement.appendChild(this.textSpan);
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
    // this.eleRef.nativeElement.style.color = this.returnResponse().color
  }

  ngOnChanges(): void {
    this.iconContainer.instance._elementRef.nativeElement.innerHTML = this.returnResponse().icon!;
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
    this.eleRef.nativeElement.style.color = this.returnResponse().color;
  }

  returnResponse() {
    if (!this.cystolic && !this.diastolic)
      return {
        text: this.displayDefaultText ? '' : this.customText,
        color: '',
      };
    switch (true) {
      case this.cystolic < 80 || this.diastolic < 60:
        return {
          text: this.displayDefaultText ? 'Low BP' : this.customText,
          color: 'red',
          icon: 'arrow_downward',
        };
      case this.cystolic >= 80 &&
        this.cystolic < 120 &&
        this.diastolic >= 60 &&
        this.diastolic < 80:
        return {
          text: this.displayDefaultText ? 'Normal' : this.customText,
          color: 'green',
          icon: 'check'
        };
      case (this.cystolic >= 120 && this.cystolic <= 139) ||
        (this.diastolic >= 80 && this.diastolic <= 89):
        return {
          text: this.displayDefaultText ? 'Pre-hypertension' : this.customText,
          color: 'orange',
          icon: 'arrow_upward'
        };
      case (this.cystolic >= 140 && this.cystolic <= 159) ||
        (this.diastolic >= 90 && this.diastolic <= 99):
        return {
          text: this.displayDefaultText
            ? 'High BP (Hypertension Stage 1)'
            : this.customText,
          color: '#DC143C',
          icon: 'arrow_upward'
        };
      case (this.cystolic >= 160 && this.cystolic <= 180) ||
        (this.diastolic >= 100 && this.diastolic <= 110):
        return {
          text: this.displayDefaultText
            ? 'High BP (Hypertension Stage 2)'
            : this.customText,
          color: 'maroon',
          icon: 'arrow_upward'
        };
      case this.cystolic > 180 || this.diastolic > 110:
        return {
          text: this.displayDefaultText
            ? 'High BP Crisis (Emergency)'
            : this.customText,
          color: 'red',
          icon: 'arrow_upward'
        };
      default:
        return {
          text: this.displayDefaultText ? '' : this.customText,
          color: '',
          icon: ''
        };
    }
  }
}
