
<mat-form-field
  style="width: 100%"
  [style.width.w]="100"
  *ngIf="!attributes.options && attributes.type !== 'date'"
  [appearance]="attributes.appearance"
  [formGroup]="attributes.formGroup"
>
  <mat-label>{{ attributes.label }}</mat-label>

  <input
    *ngIf="attributes.formControlName"
    matInput
    [type]="attributes.type"
    [formControlName]="attributes.formControlName"
    [matAutocomplete]="auto"
  />
  <mat-icon (click)="toggleVisibility($event)"
            *ngIf="attributes.formControlName.includes('pin')" matSuffix>
    {{attributes.type == 'password' ? 'visibility' : 'visibility_off'}}</mat-icon>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option
      *ngFor="let option of attributes.autocomplete?.options"
      [title]="attributes.autocomplete?.optionLabel(option) || ''"
      (click)="attributes.autocomplete?.onClick(option)">
      {{ attributes.autocomplete?.optionLabel(option) }}
    </mat-option>
  </mat-autocomplete>
  <div [ngTemplateOutlet]="formErrors"></div>
</mat-form-field>

<mat-form-field
  style="width: 100%"
  [style.width.w]="80"
  *ngIf="attributes.type == 'date'"
  [appearance]="attributes.appearance"
  [formGroup]="attributes.formGroup"
>
  <mat-label>{{ attributes.label }}</mat-label>
  <div>
    <input
      *ngIf="attributes.formControlName"
      matInput
      [formControlName]="attributes.formControlName"
      [matDatepicker]="picker"
      (click)="picker.open()"
      (focus)="picker.open()"
      [max]="maxDate"
    />
    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
    <!-- <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle> -->
    <mat-datepicker #picker startView="multi-year"></mat-datepicker>
  </div>
  <div [ngTemplateOutlet]="formErrors"></div>
</mat-form-field>

<div class="form-field-group">
  <mat-form-field
    style="width: 100%"
    [style.width.w]="100"
    *ngIf="attributes.options"
    [appearance]="attributes.appearance"
    [formGroup]="attributes.formGroup"
  >
    <mat-label>{{ attributes.label }}</mat-label>
    <mat-select
      [formControlName]="attributes.formControlName"
      [multiple]="attributes.multiple">
      <mat-option
        *ngFor="let option of attributes.options"
        [value]="option.value"
      >{{ option.title }}</mat-option
      >
    </mat-select>
    <div [ngTemplateOutlet]="formErrors"></div>
  </mat-form-field>
</div>

<ng-template #formErrors>
  <ng-container *ngIf="attributes.formGroup.get(attributes.formControlName)?.dirty ||
  attributes.formGroup.get(attributes.formControlName)?.touched">
    <div *ngIf="attributes.formGroup.get(attributes.formControlName)?.hasError('required')">
      <small class="text-danger">{{attributes.label}} is required</small>
    </div>
    <div *ngIf="attributes.formGroup.get(attributes.formControlName)?.hasError('pattern')">
      <small class="text-danger">Check the pattern for {{attributes.label}} </small>
    </div>
    <div *ngIf="attributes.formGroup.get(attributes.formControlName)?.hasError('maxlength')">
      <small class="text-danger">{{attributes.label}} Max length is
        {{getLengthErrorValue(attributes.formControlName, 'maxlength')}} </small>
    </div>
    <div *ngIf="attributes.formGroup.get(attributes.formControlName)?.hasError('minlength')">
      <small class="text-danger">{{attributes.label}} Min length is
        {{getLengthErrorValue(attributes.formControlName, 'minlength')}} </small>
    </div>

    <div *ngIf="(attributes.formControlName == 'confirm_pin') && (attributes.formGroup.get('pin')?.value != attributes.formGroup.get('confirm_pin')?.value)">
      <small class="text-danger">{{'Pin those not match'}} </small>
    </div>
  </ng-container>
</ng-template>
