/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApprovalResponse { 
    id?: number;
    isApproved: boolean;
    hospital_number?: string;
    firebase_id?: string;
    notification_token?: string;
}

