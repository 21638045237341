import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { AppointmentService } from 'proxy-sdk';
import { LoadingService } from 'src/app/services/loading.service';
import { NewAuthService } from 'src/app/services/new-auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss'],
})
export class QuickAccessComponent implements OnInit {
  activities = [
    {
      title: 'Book a new appointment',
      location: '/appointment/home',
      key: 'new-appointment',
      action: 'navigate',
      forAppointment: true,
      selected: false,
    },
    {
      title: 'To do a full health check',
      location: '',
      key: 'full-health-check',
      action: '',
      forAppointment: true,
      selected: false,
    },

    {
      title: 'To do my investigation / test or scan',
      location: '',
      key: 'investigation',
      action: '',
      forAppointment: true,
      selected: false,
    },
    {
      title: 'To review my test results with my doctor',
      location: '',
      key: 'follow-up-appointment',
      action: '',
      forAppointment: true,
      selected: false,
    },
    {
      title: 'To follow up my treatments (drugs or injection)',
      location: '',
      key: 'treatment',
      action: '',
      forAppointment: true,
      selected: false,
    },
    {
      title: 'To come for admission/procedure',
      key: 'admission/procedure',
      location: '',
      action: '',
      forAppointment: true,
      selected: false,
    },
    {
      title: 'To do dialysis',
      key: 'to-do-dialysis',
      location: '',
      action: () => {},
      forAppointment: true,
      selected: false,
    },
    // {
    //   title: 'I want to pay my bills',
    //   location: '',
    //   action: '',
    //   forAppointment: false,
    //   selected: false,
    // },
  ];

  constructor(
    private apptService: AppointmentService,
    private authService: NewAuthService,
    private dialogRef: MatDialogRef<QuickAccessComponent>,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA)
    public data: { hideWelcomeMessage: boolean; isQuickAppt: boolean }
  ) {}

  ngOnInit(): void {}

  get comments() {
    return this.activities
      .filter((val) => val.forAppointment && val.selected)
      .map((val) => val.key)
      .join();
  }

  get cantProceed() {
    return this.activities.filter((val) => val.selected).length < 1;
  }

  createAppointment() {
    if (this.activities.find((val) => val.key === 'emergency')?.selected) {
      console.log('selected emergency');
      this.closeDialog(false);
      return;
    }

    // if (
    //   this.activities.find(
    //     (val) =>
    //       (val.key === 'new-appointment' ||
    //         val.key === 'follow-up-appointment') &&
    //       val.selected
    //   )
    // ) {
    //   this.closeDialog(false);
    //   return;
    // }

    if (!this.data.isQuickAppt) {
      this.closeDialog(false);
      return;
    }

    this.loadingService.showLoading();
    this.apptService
      .appointmentControllerCreateAppointment({
        date_of_appointment: dayjs().toISOString(),
        hospital_number: this.authService.patient$.value.hospital_number,
        comment: this.comments,
      })
      .subscribe({
        next: (val) => {
          Swal.fire({
            text: val._id
              ? 'Appointment Created'
              : val.message ?? BOOKING_ERROR_MESSAGE,
            timer: 4000,
          }).then((val) => {
            this.activities = this.activities.map((val) => {
              val.selected = false;
              return val;
            });
            this.closeDialog(false);
          });
        },
        error: () => {
          this.loadingService.simpleEndLoading({
            title: BOOKING_ERROR_MESSAGE,
          });
        },
      });
  }

  closeDialog(isCancelled: boolean) {
    this.dialogRef.close({
      isEmergency: this.activities.find((val) => val.key === 'emergency')
        ?.selected,
      comment: this.comments,
      isAppointment: this.activities.find(
        (val) =>
          val.key === 'new-appointment' || val.key === 'follow-up-appointment'
      )?.selected,
      isCancelled,
    });
  }
}

const BOOKING_ERROR_MESSAGE =
  'Could not confirm your booking. Please visit our Client Service Desk.';
