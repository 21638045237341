/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Bill } from './bill';


export interface InitPaymentDto { 
    bills: Array<Bill>;
    gateway: object;
    email: string;
    amount: number;
    hospital_number?: string;
    chargeType?: object;
    phone?: string;
    bankCode?: string;
    bankAccountNumber?: string;
    ussdCode?: string;
    description?: string;
    branch?: string;
    redirect_url?: string;
    service_type?: object;
}

