import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivacyComponent } from './privacy/privacy.component';
import { DataDeletionRequestComponent } from './data-deletion-request/data-deletion-request.component';

const routes: Routes = [
  // { path: 'login', component: LoginComponent },
  // { path: 'registration', component: RegisterComponent },
  // { path: 'home', component: HomeComponent },
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '**', component: LoginComponent },

  {
    path: '',
    redirectTo: 'authentication',
    pathMatch: 'full',
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'removal-request',
    loadChildren: () =>
      import('./data-deletion-request/data-deletion-request.module').then(
        (m) => m.DataDeletionRequestModule
      ),
  },
  {
    path: 'authentication',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: '**',
    redirectTo: 'authentication',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
