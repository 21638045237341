export * from './activityMonitor.service';
import { ActivityMonitorService } from './activityMonitor.service';
export * from './antenatal.service';
import { AntenatalService } from './antenatal.service';
export * from './appNotifications.service';
import { AppNotificationsService } from './appNotifications.service';
export * from './appointment.service';
import { AppointmentService } from './appointment.service';
export * from './approval.service';
import { ApprovalService } from './approval.service';
export * from './careGiver.service';
import { CareGiverService } from './careGiver.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './emergency.service';
import { EmergencyService } from './emergency.service';
export * from './immunizationPatient.service';
import { ImmunizationPatientService } from './immunizationPatient.service';
export * from './messaging.service';
import { MessagingService } from './messaging.service';
export * from './patient.service';
import { PatientService } from './patient.service';
export * from './rating.service';
import { RatingService } from './rating.service';
export * from './ratingOption.service';
import { RatingOptionService } from './ratingOption.service';
export * from './result.service';
import { ResultService } from './result.service';
export * from './visitorRequest.service';
import { VisitorRequestService } from './visitorRequest.service';
export * from './vitals.service';
import { VitalsService } from './vitals.service';
export const APIS = [ActivityMonitorService, AntenatalService, AppNotificationsService, AppointmentService, ApprovalService, CareGiverService, DefaultService, EmergencyService, ImmunizationPatientService, MessagingService, PatientService, RatingService, RatingOptionService, ResultService, VisitorRequestService, VitalsService];
