import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import Swal from 'sweetalert2';
import { capitalizeFirstLetter } from '../functions';
import { AppointmentStateAction } from '../store/appointment/appointment.actions';
import { NewAuthService } from './new-auth.service';
// import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class EmergencyService {
  constructor(
    public authService: NewAuthService,
    private store: Store<{
      appointmentActionState: AppointmentStateAction;
      emergencyOptions: { title: string; key: string; ref: string }[];
    }>,
    private router: Router
  ) {}

  emergencyOptions: Record<string, string> = {};
  rawEmergencyOptions: { title: string; key: string; ref: string }[] = [];

  emergencyOptions$ = this.store
    .select('emergencyOptions')
    .pipe(take(1))
    .subscribe((res) => {
      this.emergencyOptions = res.reduce((prev, next) => {
        return { ...prev, [next.key]: next.title };
      }, {});
      this.rawEmergencyOptions = res;
    });

  async haveEmergency() {
    const params = {
      needsAmbulance: false,
      location: '{}',
      sex: capitalizeFirstLetter(this.authService.patient$.value?.sex!),
      selfBooking: true,
      emergencyKeys: '',
      address: '',
    };
    const hasEmergency = await this.emergencyStartDialog();
    if (hasEmergency === undefined) return;

    if (!hasEmergency) {
      this.router.navigateByUrl('/emergency');
      return;
    }

    const selfBooking = await this.selfBookingDialog();
    if (selfBooking === undefined) return;

    if (!selfBooking) {
      const sex = await this.selectSexDialog();
      sex ? (params.sex = sex) : null;
    }

    const selectedEmergency = await this.selectEmergencyDialog();
    params.emergencyKeys = selectedEmergency!;

    const needsAmbulance = await this.ambulanceRequestDialog();
    if (needsAmbulance === undefined) return;
    params.needsAmbulance = needsAmbulance;

    if (needsAmbulance) {
      console.log('getting location');
      navigator.geolocation.getCurrentPosition((val) => {
        params.location = JSON.stringify({
          accuracy: val.coords.accuracy,
          latitude: val.coords.latitude,
          longitude: val.coords.longitude,
        });
      });
      console.log('Ambulance section');
      const address = await this.enterAddressDialog();
      console.log('collected address', address);
      params.address = address;
      this.sendEmergencyRequest(params);
    } else {
      this.sendEmergencyRequest(params);
    }
  }
  async emergencyStartDialog() {
    return Swal.fire({
      title: 'Do you have an active emergency?',
      icon: 'question',
      showDenyButton: true,
      denyButtonText: 'No',
      confirmButtonText: 'Yes',
      showCancelButton: false,
      confirmButtonColor: '#1e88e5',
    }).then((res) => {
      return res.isConfirmed;
    });
  }
  async selectEmergencyDialog() {
    return await Swal.fire({
      title: 'Select the type(s) of Emergency you have',
      confirmButtonColor: '#1e88e5',
      // icon: 'question',
      html: this.rawEmergencyOptions
        .map(
          (val, index) =>
            `
      <div class="form-check">
          <input class="form-check-input" type="checkbox" value="${val.key}" id="${val.key}">
          <label style="text-align: start" for="${val.key}">
             ${val.title}
          </label>
      </div>
`
        )
        .join(''),
      focusConfirm: false,
      preConfirm: () => {
        const checked = this.rawEmergencyOptions
          .map((val) =>
            (document.getElementById(val.key) as any)?.checked ? val.key : ''
          )
          .filter((val) => val !== '');

        console.log(checked);
        return checked.join();
      },
    }).then((selfReq) => {
      if (selfReq.isDismissed) {
        return;
      } else return selfReq.value;
    });
  }

  async selfBookingDialog() {
    return await Swal.fire({
      title: 'Is this request for yourself?',
      icon: 'question',
      showDenyButton: true,
      denyButtonText: 'No',
      confirmButtonText: 'Yes',
      showCancelButton: true,

      confirmButtonColor: '#1e88e5',
    }).then((selfReq) => {
      if (selfReq.isDismissed) {
        return;
      }
      return selfReq.isConfirmed;
    });
  }

  async selectSexDialog() {
    return await Swal.fire({
      title: 'Is the patient male or female?',
      icon: 'question',
      showDenyButton: true,
      denyButtonText: 'Male',
      confirmButtonText: 'Female',
      showCancelButton: true,
      confirmButtonColor: '#1e88e5',
    }).then((sex) => {
      if (sex.isDismissed) {
        return;
      }

      return sex.isConfirmed ? 'Female' : 'Male';
    });
  }

  async ambulanceRequestDialog() {
    return await Swal.fire({
      title: 'Do you need an ambulance?',
      icon: 'question',
      showDenyButton: true,
      denyButtonText: 'No',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#1e88e5',
    }).then((amb) => {
      if (amb.isDismissed) {
        return;
      }
      return amb.isConfirmed;
    });
  }

  async enterAddressDialog() {
    console.log('firing address dialog');
    return await Swal.fire({
      title: 'Tell us your location',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Done',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#1e88e5',
    }).then((loc) => {
      if (loc.isDismissed) {
        return;
      }
      return loc.value;
    });
  }

  async sendEmergencyRequest({
    needsAmbulance,
    selfBooking,
    sex,
    emergencyKeys,
    location,
    address,
  }: {
    needsAmbulance: boolean;
    selfBooking: boolean;
    sex: string;
    emergencyKeys: string;
    location: string;
    address: string;
  }) {
    await Swal.fire({
      timer: 3000,
      timerProgressBar: true,
      icon: 'info',
      title: 'Please wait...',
      showConfirmButton: false,
      confirmButtonColor: '#1e88e5',
    }).then(() => {
      this.router.navigateByUrl(
        `/emergency?needsAmbulance=${needsAmbulance}&self=${selfBooking}&sex=${sex}&emergencyKeys=${emergencyKeys}&location=${location}&address=${address}`
      );
    });
  }
}
