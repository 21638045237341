import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import {
  fetchRegistrationPackages, processingRegistration,
  registrationFailed,
  registrationSuccessful,
  runRegistration,
  storeRegistrationPackages
} from './register.actions';
import { PartialIPatient, PatientService } from 'kiosk-service-sdk';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

@Injectable()
export class RegisterEffects {
  constructor(
    private actions$: Actions,
    private patientService$: PatientService,
    public route: Router,
    private store: Store
  ) {}

  runRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(runRegistration),
      switchMap((d) => {
        this.store.dispatch(processingRegistration({loading: true}));
        return this.patientService$
          .registerPatientPatientRegisterPost({
            data: d.patientData,
            autoGenerateHospitalNumber: true,
            groupId: d.packageId,
          })
          .pipe(
            map((val) => {
              this.store.dispatch(processingRegistration({loading: false}));
              let returnAction:  ({patientData: PartialIPatient} & TypedAction<"registrationSuccessful">)
                | ({patientData: PartialIPatient} & TypedAction<"registrationFailed">);

              if (val?.error === false) {
                Swal.fire({
                  // position: 'top-end',
                  icon: 'success',
                  iconColor: '#1e88e5',
                  text: 'Registration was successful',
                  showConfirmButton: false,
                  confirmButtonColor: '#1e88e5',
                  timer: 2000,
                });
                this.route.navigateByUrl('/');

                returnAction = registrationSuccessful({
                  patientData: (val as any).result,
                });
              } else {
                Swal.fire({
                  // position: 'top-end',
                  icon: 'error',
                  iconColor: '#ff1223',
                  text: 'Something Went Error',
                  showConfirmButton: false,
                  confirmButtonColor: '#1e88e5',
                  timer: 2000,

                });
                returnAction = registrationFailed({
                  patientData: (val as any).result,
                });
              }
              return returnAction;
            }),
            catchError((e) => {
              this.store.dispatch(processingRegistration({loading: false}));
              Swal.fire({
                // position: 'top-end',
                icon: 'error',
                iconColor: '#ff1223',
                text: 'Something Went Error',
                showConfirmButton: false,
                confirmButtonColor: '#1e88e5',
                timer: 2000,
              });

              return of(
                registrationFailed({
                  patientData: d.patientData,
                })
              );
            })
          );
      })
    )
  );

  fetchPackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchRegistrationPackages),
      switchMap((d) => {
        return this.patientService$
          .fetchGroupsPatientGroupsGet()
          .pipe(
            map((val) => storeRegistrationPackages({ packages: val.result }))
          );
      })
    )
  );
}
