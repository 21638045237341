/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  IUserGroupGroupId - a model defined in OpenAPI      id: The id of this IUserGroupGroupId [Optional].     title: The title of this IUserGroupGroupId.     number_of_persons: The number_of_persons of this IUserGroupGroupId.     cost: The cost of this IUserGroupGroupId.     is_default: The is_default of this IUserGroupGroupId [Optional].     is_deleted: The is_deleted of this IUserGroupGroupId [Optional].     created_at: The created_at of this IUserGroupGroupId [Optional].     updated_at: The updated_at of this IUserGroupGroupId [Optional].     error: The error of this IUserGroupGroupId [Optional].     is_public: The is_public of this IUserGroupGroupId [Optional].
 */
export interface IUserGroupGroupId { 
    _id?: string;
    title: string;
    numberOfPersons: number;
    cost: number;
    isDefault?: boolean;
    isDeleted?: boolean;
    created_at?: string;
    updated_at?: string;
    error?: string;
    isPublic?: boolean;
}

