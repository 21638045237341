import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AntenatalEnrollmentDto,
  AntenatalService,
  IFollowUpVisit,
} from 'proxy-sdk';
import { AntenatalEnrollmentReducers } from '../store/antenatal-enrollment/antenatal-enrollment.reducers';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class AntenatalEnrollmentService {
  antenatal?: AntenatalEnrollmentDto;
  $nextAppt = new BehaviorSubject<IFollowUpVisit | undefined>(undefined);

  constructor(
    private store: Store<AntenatalEnrollmentReducers>,
    private antenatalService: AntenatalService
  ) {
    this.store.select('antenatalEnrollment').subscribe({
      next: (antenatal) => {
        this.antenatal = antenatal;
      },
    });
  }

  get weeks(): number {
    return Math.floor(this.days / 7);
  }

  get remainingDaysInWeek(): number {
    return this.days % 7;
  }

  get days(): number {
    const currentDate = new Date();
    const startDate = new Date(this.antenatal?.lastMenstrualPeriod!);
    const days = Math.floor(
      (currentDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    return days;
  }

  get trimester(): string {
    const weeks = this.weeks;
    if (weeks <= 13) {
      return '1st';
    }
    if (weeks <= 26) {
      return '2nd';
    }
    return '3rd';
  }

  get minWeek(): number {
    const weeks = this.weeks;
    if (weeks <= 13) return 1;
    if (weeks <= 26) return 14;
    return 27;
  }

  get daysLeft(): number {
    const currentDate = new Date();
    const futureDate = new Date(this.antenatal?.expectedDeliveryDate!);
    const days = Math.floor(
      (futureDate.getTime() - currentDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    return days;
  }

  get totalWeeks(): number {
    return Math.floor((this.days + this.daysLeft) / 7);
  }

  get percent(): number {
    return Math.floor((this.weeks / this.totalWeeks) * 100);
  }

  get backgroundRadial(): string {
    const color = this.daysLeft <= 0 ? '#f00' : '#fff';
    return `conic-gradient(${color} ${this.percent}%, #9851F3
      ${this.percent}%)`;
  }

  fetchNextAppointment() {
    if (this.antenatal?._id) {
      return this.antenatalService
        .antenatalControllerGetNextAppointments(
          this.antenatal?._id,
          1,
          0,
          '-returnDate'
        )
        .subscribe({
          next: (val) => {
            if (
              val &&
              val[0] &&
              dayjs(val[0].returnDate).endOf('day').isAfter(dayjs())
            ) {
              this.$nextAppt.next(val[0]);
            } else {
              this.$nextAppt.next(undefined);
            }
          },
        });
    }
    return;
  }
}
