/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  ChangePin200Response - a model defined in OpenAPI      error: The error of this ChangePin200Response.     message: The message of this ChangePin200Response.     result: The result of this ChangePin200Response.
 */
export interface ChangePin200Response { 
    error: boolean;
    message: string;
    result?: any | null;
}

