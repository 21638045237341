import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, Inject, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[appPulseRate]',
})
export class PulseRateDirective {
  @Input('normal') normal = 0;
  @Input('bradycardia') bradycardia = 0;
  @Input('tachycardia') tachycardia = 0;
  @Input('displayDefaultText') displayDefaultText = true;
  @Input('customText') customText = '';
  @Input('showIcon') showIcon = false;

  iconContainer: ComponentRef<MatIcon> = this.viewContainer.createComponent<MatIcon>(MatIcon);
  textSpan: HTMLElement = this.document.createElement('b');
  
  constructor(
    private renderer: Renderer2,
    private eleRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: Document,
    private viewContainer: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    
    this.eleRef.nativeElement.appendChild(this.iconContainer.location.nativeElement);
    this.eleRef.nativeElement.appendChild(this.textSpan);
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
  }

  ngOnChanges(): void {
    this.iconContainer.instance._elementRef.nativeElement.innerHTML = this.returnResponse().icon!;
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
    this.eleRef.nativeElement.style.color = this.returnResponse().color;
  }

  returnResponse() {
    if (!this.normal || !this.bradycardia || !this.tachycardia)
      return { text: '', color: '', icon: '' };
    switch (true) {
      case this.bradycardia <= 60:
        return {
          text: this.displayDefaultText
            ? 'Bradycardia for adult'
            : this.customText,
          color: 'orange',
          icon: 'arrow_downward'
        };
      case this.normal > 60 && this.normal <= 100:
        return {
          text: this.displayDefaultText ? 'Normal for adult' : this.customText,
          color: 'green',
          icon: 'check'
        };
      case this.tachycardia > 100:
        return {
          text: this.displayDefaultText
            ? 'tachycardia for adult'
            : this.customText,
          color: 'red',
          icon: 'arrow_upward'
        };
      default:
        return {
          text: this.displayDefaultText ? '' : customElements,
          color: '',
          icon: 'minus'
        };
    }
  }
}
