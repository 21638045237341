/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateOrderDto { 
    id: number;
    ordered_by?: string;
    room_number?: string;
    ordered_by_name?: string;
    total_cost?: number;
    amount_paid?: number;
    discount?: number;
    rating?: number;
    payment_status?: UpdateOrderDto.PaymentStatusEnum;
    hospital_number?: string;
    hospital_id?: string;
    status?: UpdateOrderDto.StatusEnum;
    accepted_by?: string;
    delivered_by?: string;
    time_completed?: string;
}
export namespace UpdateOrderDto {
    export type PaymentStatusEnum = 'notpaid' | 'paid' | 'hmo' | 'discount' | 'partial';
    export const PaymentStatusEnum = {
        Notpaid: 'notpaid' as PaymentStatusEnum,
        Paid: 'paid' as PaymentStatusEnum,
        Hmo: 'hmo' as PaymentStatusEnum,
        Discount: 'discount' as PaymentStatusEnum,
        Partial: 'partial' as PaymentStatusEnum
    };
    export type StatusEnum = 'pending' | 'accepted' | 'inprogress' | 'completed' | 'delivered';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Accepted: 'accepted' as StatusEnum,
        Inprogress: 'inprogress' as StatusEnum,
        Completed: 'completed' as StatusEnum,
        Delivered: 'delivered' as StatusEnum
    };
}


