/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IUserGroupGroupId } from './iUserGroupGroupId';


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  IBeneficiaryUserGroupId - a model defined in OpenAPI      id: The id of this IBeneficiaryUserGroupId [Optional].     group_id: The group_id of this IBeneficiaryUserGroupId [Optional].     primary_owner_hospital_number: The primary_owner_hospital_number of this IBeneficiaryUserGroupId [Optional].     hospital_code: The hospital_code of this IBeneficiaryUserGroupId [Optional].     created_at: The created_at of this IBeneficiaryUserGroupId [Optional].     updated_at: The updated_at of this IBeneficiaryUserGroupId [Optional].
 */
export interface IBeneficiaryUserGroupId { 
    _id?: string;
    groupId?: IUserGroupGroupId;
    primaryOwnerHospitalNumber?: string;
    hospitalCode?: string;
    created_at?: string;
    updated_at?: string;
}

