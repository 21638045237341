/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IGroup { 
    _id?: string;
    title: string;
    numberOfPersons: number;
    cost: number;
    isDefault?: boolean;
    isDeleted?: boolean;
    created_at?: string;
    updated_at?: string;
    error?: string;
    isPublic?: boolean;
}

