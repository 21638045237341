/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Wallet } from './wallet';


export interface WalletTransaction { 
    id: number;
    amount: number;
    transactionType: WalletTransaction.TransactionTypeEnum;
    wallet: Wallet;
    createdByName: string;
    createdById: string;
    created: string;
    updated: string;
    deletedAt?: string;
}
export namespace WalletTransaction {
    export type TransactionTypeEnum = 'CREDIT' | 'DEBIT';
    export const TransactionTypeEnum = {
        Credit: 'CREDIT' as TransactionTypeEnum,
        Debit: 'DEBIT' as TransactionTypeEnum
    };
}


