/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PatientDataHmo { 
    patientHmoCode?: string;
    hmoCode?: string;
    hmoId?: string;
    hmoName?: string;
    _id?: string;
}

