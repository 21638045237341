/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  IPatientAdditionalHistoriesInner - a model defined in OpenAPI      is_special: The is_special of this IPatientAdditionalHistoriesInner [Optional].     date_added_to_patient_record: The date_added_to_patient_record of this IPatientAdditionalHistoriesInner [Optional].     text: The text of this IPatientAdditionalHistoriesInner [Optional].     key: The key of this IPatientAdditionalHistoriesInner [Optional].     id: The id of this IPatientAdditionalHistoriesInner [Optional].
 */
export interface IPatientAdditionalHistoriesInner { 
    isSpecial?: boolean;
    dateAddedToPatientRecord?: string;
    text?: string;
    key?: string;
    _id?: string;
}

