/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VisitorRequestDto { 
    status?: VisitorRequestDto.StatusEnum;
    _id?: string;
    patientFirstName: string;
    patientLastName: string;
    patientHospitalNumber: string;
    patientUid: string;
    visitorFullName: string;
    numberOfVisitors: number;
    visitorCode?: string;
    expiryTime?: string;
    visitorTimeIn?: string;
    visitorTimeOut?: string;
    visitorPhoneNumber?: string;
    patientRoomNumber?: string;
    created_at?: string;
    updated_at?: string;
}
export namespace VisitorRequestDto {
    export type StatusEnum = 'pending' | 'ongoing' | 'completed' | 'cancelled' | 'expired';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Ongoing: 'ongoing' as StatusEnum,
        Completed: 'completed' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        Expired: 'expired' as StatusEnum
    };
}


