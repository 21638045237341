/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  GatewayChargeParamsDto - a model defined in OpenAPI      email: The email of this GatewayChargeParamsDto.     amount: The amount of this GatewayChargeParamsDto.     hospital_number: The hospital_number of this GatewayChargeParamsDto [Optional].     charge_type: The charge_type of this GatewayChargeParamsDto [Optional].     phone: The phone of this GatewayChargeParamsDto [Optional].     bank_code: The bank_code of this GatewayChargeParamsDto [Optional].     bank_account_number: The bank_account_number of this GatewayChargeParamsDto [Optional].     ussd_code: The ussd_code of this GatewayChargeParamsDto [Optional].     description: The description of this GatewayChargeParamsDto [Optional].     branch: The branch of this GatewayChargeParamsDto [Optional].     redirect_url: The redirect_url of this GatewayChargeParamsDto [Optional].
 */
export interface GatewayChargeParamsDto { 
    email: string;
    amount: number;
    hospital_number?: string;
    chargeType?: string;
    phone?: string;
    bankCode?: string;
    bankAccountNumber?: string;
    ussdCode?: string;
    description?: string;
    branch?: string;
    redirect_url?: string;
}

