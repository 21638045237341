import { createReducer, on } from "@ngrx/store";
import { AntenatalQuestionAnswerDto } from "proxy-sdk";
import { ComplaintModel } from "src/app/interfaces/qa";
import { clearAntenatalDiagnosis, clearAntenatalDiagnosisByKey, clearWeeklyChecklist, fetchedAnsweredWeeklyChecklist, fetchedAntenatalDiagnosis, fetchedAntenatalDiagnosisByKey, fetchedWeeklyChecklist, loadingDiagnosisDataStatus, loadingWeeklyDataStatus } from "./weekly-checklist.actions";

const weeklyChecklistReducer = createReducer(
  {},
  on(fetchedWeeklyChecklist, (state, props) => {
    return props;
  }),
  on(clearWeeklyChecklist, (state, props) => {
    return {};
  })
);


const loadingWeeklyDataReducer = createReducer(
  {},
  on(loadingWeeklyDataStatus, (state, props) => {
    return props.loading;
  })
);

const loadingDiagnosisDataReducer = createReducer(
  {},
  on(loadingDiagnosisDataStatus, (state, props) => {
    return props.loading;
  })
);

const questionsAndAnswers: AntenatalQuestionAnswerDto[] = [];
const answeredWeeklyChecklistReducer = createReducer(
  questionsAndAnswers,
  on(fetchedAnsweredWeeklyChecklist, (state, props) => {
    return props.qa;
  })
);

const antenatalDiagnosisReducer = createReducer(
  {},
  on(fetchedAntenatalDiagnosisByKey, (state, props) => props),
  on(clearAntenatalDiagnosisByKey, (state, props) => {
    return {};
  })
);

const runAntenatalDiagnosisReducer = createReducer(
  {},
  on(fetchedAntenatalDiagnosis, (state, props) => props),
  on(clearAntenatalDiagnosis, (state, props) => {
    return {};
  })
);

export const weeklyChecklistReducers = {
  weeklyChecklist: weeklyChecklistReducer,
  questionAndAnswers: answeredWeeklyChecklistReducer,
  weeklyChecklistResult: antenatalDiagnosisReducer,
  weeklyDiagnosisKey: runAntenatalDiagnosisReducer,
  loadingWeeklyData: loadingWeeklyDataReducer,
  loadingDiagnosisData: loadingDiagnosisDataReducer,
};

export interface WeeklyChecklistReducers {
  weeklyChecklist: ComplaintModel;
  questionAndAnswers: AntenatalQuestionAnswerDto[];
  weeklyChecklistResult: any;
  weeklyDiagnosisKey: RunDiagnosisResult;
  loadingWeeklyData: boolean;
  loadingDiagnosisData: boolean;
}

export interface RunDiagnosisResult {
  diagnosis_key: string;
  score: string;
}