import { createReducer, on } from "@ngrx/store";
import { ImmunizationAppointmentData, ImmunizationPatientData, PatientData } from "proxy-sdk";
import { fetchedImmunizationsVaccine, fetchedNextImmunizationAppointments, fetchedPastImmunizationsAppointments, fetchingImmunizationsVaccine, fetchingNextImmunizationAppointments, fetchingPastImmunizationsAppointments } from "./immunization.actions";

const pastImmunizationAppointmentsReducer = createReducer(
    [] as ImmunizationAppointmentData[],
    on(fetchedPastImmunizationsAppointments, (state, props) => {
        return props.appointments;
    })
)

const loadingPastImmunizationAppointmentsReducer = createReducer(
    false as boolean,
    on(fetchingPastImmunizationsAppointments, (state, props) => {
        return props.loading;
    })
)

export const immunizationVaccinesReducer = createReducer(
    [] as ImmunizationPatientData[],
    on(fetchedImmunizationsVaccine, (state, props) => {
        return props.immunizations;
    })
)

export const loadingImmunizationVaccineReducer = createReducer(
    false as boolean,
    on(fetchingImmunizationsVaccine, (state, props) => {
        return props.loading;
    })
)

const nextImmunizationAppointmentReducer = createReducer(
    {} as ImmunizationAppointmentData,
    on(fetchedNextImmunizationAppointments, (state, props) => {
        return props.appointment;
    })
)

const loadingNextImmunizationAppointmentReducer = createReducer(
    false as boolean,
    on(fetchingNextImmunizationAppointments, (state, props) => {
        return props.loading;
    })
)

export const immunizationReducers = {
    pastImmunizationAppointments: pastImmunizationAppointmentsReducer,
    nextImmunizationAppointment: nextImmunizationAppointmentReducer,
    fetchingPastImmunizations: loadingPastImmunizationAppointmentsReducer,
    fetchingNextImmunization: loadingNextImmunizationAppointmentReducer
}

export interface ImmunizationReducers {
    pastImmunizationAppointments: ImmunizationAppointmentData[];
    nextImmunizationAppointment: ImmunizationAppointmentData;
    fetchingPastImmunizations: boolean;
    fetchingNextImmunization: boolean;
}

export const singleVaccinceReducers = {
    vaccineList: immunizationVaccinesReducer,
    loadingVaccine: loadingImmunizationVaccineReducer
}

export interface SingleVaccineReducers {
    vaccineList: ImmunizationPatientData[];
    loadingVaccine: boolean;
}