/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CopyQuestionsAndAnswer } from './copyQuestionsAndAnswer';


export interface CopyQuestionAndAnswerFilterDto { 
    end_date?: string;
    filter: CopyQuestionsAndAnswer;
    limit?: number;
    order?: object;
    order_field?: string;
    page?: number;
    search_string?: string;
    start_date?: string;
}

