import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { NativeSocketService } from 'src/app/services/native-socket.service';
import { NewAuthService } from 'src/app/services/new-auth.service';

@Component({
  selector: 'app-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss'],
})
export class IncomingCallComponent implements OnInit {
  audio = new Audio('assets/sounds/ring_ring.mp3');
  isCalling = true;

  userId = this.authService.patient$.value.id;
  constructor(
    private generalService: GeneralService,
    private authService: NewAuthService,
    private nativeSocketService: NativeSocketService,
    public dialogRef: MatDialogRef<IncomingCallComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      callerName: string;
      callerId: string;
      callerImage: string;
      meetingUUID: string;
    }
  ) {}

  ngOnInit(): void {
    this.audio.play();
  }

  handleCallAction(action: any) {
    if (action.detail === 'reject') {
      this.isCalling = false;
      this.nativeSocketService.socket.send(
        JSON.stringify({
          event: 'REJECTED',
          data: {
            from: this.generalService.patientName,
            id: this.generalService.patient.hospital_number,
            room: this.data.callerId,
            message: 'Call Rejected',
            time: new Date().toISOString(),

          }
        })
      );
      this.audio.pause();
      this.generalService.actionsEvents.next('END_CALL');
      this.dialogRef.close();
      return;
    }
    if (action.detail === 'accept') {
      this.isCalling = false;
      this.audio.pause();
      this.generalService.joinMeeting(this.data.meetingUUID, 'guest');
      this.nativeSocketService.socket.send(
        JSON.stringify({
          event: 'ACCEPTED',
          data: {
            from: this.generalService.patientName,
            id: this.generalService.patient.hospital_number,
            room: this.data.callerId,
            message: 'Call Accepted',
            time: new Date().toISOString(),

          }
        })
      );
      this.dialogRef.close();
      return;
    }
  }
}
