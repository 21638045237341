import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApprovalService, PatientData, PatientService } from 'proxy-sdk';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class NewAuthService {
  hasAccessedHome = false;
  patient$: BehaviorSubject<PatientData & { id?: string }> =
    new BehaviorSubject({} as unknown as any);

  constructor(
    private afAuth: AngularFireAuth,
    private approvalService: ApprovalService,
    private patientService: PatientService,
    private store: Store<{
      pushNotificationToken: { token: string };
    }>,
    private router: Router
  ) {}

  login(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((val) => {
        if (val.user) {
          this.afterLogin(val.user);
        }
        return val;
      });
  }

  fetchPatient(): void {
    this.patientService.patientControllerGetPatientInfo().subscribe({
      next: (resp: any) => {
        this.patient$.next(resp);
      },
    });
  }

  forgotPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email).then(
      () => {
        this.router.navigateByUrl('/');
        // this.router.navigate(['/authentication/verify-email']);
      },
      (err) => {
        alert('Something went wrong');
        console.log('error', err);
        throw err;
      }
    );
  }

  SendVerificationMail() {
    console.log('hello');
    // return user.sendEmailVerification().then(
    //   (res: any) => {
    //     // this.router.navigate(['/authentication/verify-email']);
    //     console.log('email', res);

    //     alert('email has been sent');
    //   },
    //   (err: any) => {
    //     alert('Something went wrong. Not able to send mail to your email.');
    //   }
    // );
    return this.afAuth.currentUser.then((u) => {
      u?.sendEmailVerification().then(() => {
        this.verificationEmailAlert(3000);
      });
      // console.log(u);
      // alert('email has been sent');
    });
  }

  verificationEmailAlert(duration: number) {
    return Swal.fire({
      // position: 'top-end',
      icon: 'success',
      iconColor: '#1e88e5',
      text: 'Verification Email Sent',
      showConfirmButton: false,
      confirmButtonColor: '#1e88e5',
      timer: duration,
    });
  }

  async signOut() {
    await this.afAuth.signOut().finally(() => {
      this.router.navigateByUrl('/');
    });
    // console.log('fired');

    this.router.navigate(['/']);
  }

  async setNotificationToken(user: firebase.default.User) {
    this.approvalService
      .approvalControllerCheckApprovalStatus(user.uid)
      .subscribe({
        next: async (data) => {
          if (data.isApproved) {
            this.store.select('pushNotificationToken').subscribe({
              next: (val) => {
                if (!val.token) {
                  return;
                }
                const notificationToken = val;
                if (
                  notificationToken.token &&
                  notificationToken.token !== data.notification_token
                ) {
                  this.approvalService
                    .approvalControllerUpdateNotificationToken({
                      notification_token: notificationToken.token,
                    })
                    .subscribe({
                      next: (val) => {
                        alert('Welcome to MyHomeClinic');
                      },
                      error: (e) => {
                        console.log(e);
                      },
                    });
                }
              },
            });
          }
        },
      });
  }

  afterLogin(user: firebase.default.User) {
    this.setNotificationToken(user);
    this.fetchPatient();
  }
}
