import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  AppointmentService,
  ImmunizationAppointmentData,
  ImmunizationPatientData,
  ImmunizationPatientService,
  PatientData,
  PatientService,
} from 'proxy-sdk';
import { catchError, map, of, switchMap } from 'rxjs';
import { NewAuthService } from 'src/app/services/new-auth.service';
import {
  fetchBeneficiaries,
  fetchBeneficiary,
  fetchBeneficiaryAppointments,
  fetchedBeneficiaries,
  fetchedBeneficiary,
  fetchedBeneficiaryAppointments,
  fetchingBeneficiaries,
  fetchingBeneficiary,
  fetchingBeneficiaryAppointments,
} from './beneficiary.actions';

@Injectable()
export class BeneficiaryEffect {
  constructor(
    private action$: Actions,
    private store: Store,
    private patientService: PatientService,
    private apptService: AppointmentService,
    private newAuthService: NewAuthService
  ) {}

  fetchBeneficiariesService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fetchBeneficiaries),
      switchMap((props) => {
        this.store.dispatch(fetchingBeneficiaries({ loading: true }));
        return this.patientService
          .patientControllerGetBeneficiaries(
            {
              userGroupId: this.newAuthService.patient$.value.userGroupId,
            } as PatientData,
            10,
            0,
            '-created_at',
            undefined,
            undefined,
            props.maxAge
          )
          .pipe(
            map((data) => {
              this.store.dispatch(fetchingBeneficiaries({ loading: false }));
              return fetchedBeneficiaries({
                beneficiaries:
                  data.data?.filter(
                    (patient) =>
                      patient._id !== this.newAuthService.patient$.value._id
                  ) ?? [],
              });
            }),
            catchError((error) => {
              this.store.dispatch(fetchingBeneficiaries({ loading: false }));
              return of(fetchedBeneficiaries({ beneficiaries: [] }));
            })
          );
      })
    );
  });

  fetchBeneficiaryService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fetchBeneficiary),
      switchMap((props) => {
        this.store.dispatch(fetchingBeneficiary({ loading: true }));
        return this.patientService
          .patientControllerGetBeneficiaries(
            {
              userGroupId: this.newAuthService.patient$.value.userGroupId,
              hospital_number: props.hospitalNumber,
            } as PatientData,
            10,
            0,
            '-created_at',
            undefined,
            undefined,
            undefined
          )
          .pipe(
            map((data) => {
              this.store.dispatch(fetchingBeneficiaries({ loading: false }));
              return fetchedBeneficiary({
                beneficiary: data.data![0] ?? ({} as PatientData),
              });
            }),
            catchError((error) => {
              this.store.dispatch(fetchingBeneficiary({ loading: false }));
              return of(fetchedBeneficiary({ beneficiary: {} as PatientData }));
            })
          );
      })
    );
  });

  fetchBeneficiaryAppointments$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fetchBeneficiaryAppointments),
      switchMap((props) => {
        this.store.dispatch(fetchingBeneficiaryAppointments({ loading: true }));
        return this.apptService
          .appointmentControllerRecentBeneficiaryAppointments({
            hospital_number: props.hospitalNumber,
          })
          .pipe(
            map((data) => {
              this.store.dispatch(
                fetchingBeneficiaryAppointments({ loading: false })
              );
              return fetchedBeneficiaryAppointments({
                appointments: data ?? [],
              });
            }),
            catchError((error) => {
              this.store.dispatch(
                fetchingBeneficiaryAppointments({ loading: false })
              );
              return of(fetchedBeneficiaryAppointments({ appointments: [] }));
            })
          );
      })
    );
  });
}
