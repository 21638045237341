/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilteredEmergencyRecord } from './filteredEmergencyRecord';
import { ErrorMessage } from './errorMessage';


export interface CreateEmergencyRecordResponseDto { 
    status: CreateEmergencyRecordResponseDto.StatusEnum;
    message: CreateEmergencyRecordResponseDto.MessageEnum;
    data?: FilteredEmergencyRecord;
    id?: number;
    error?: ErrorMessage;
}
export namespace CreateEmergencyRecordResponseDto {
    export type StatusEnum = 'SUCCESS' | 'FAILED';
    export const StatusEnum = {
        Success: 'SUCCESS' as StatusEnum,
        Failed: 'FAILED' as StatusEnum
    };
    export type MessageEnum = 'REQUEST SUCCESSFUL' | 'REQUEST FAILED' | 'REQUEST IN PROGRESS';
    export const MessageEnum = {
        Successful: 'REQUEST SUCCESSFUL' as MessageEnum,
        Failed: 'REQUEST FAILED' as MessageEnum,
        InProgress: 'REQUEST IN PROGRESS' as MessageEnum
    };
}


