/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateComplaintSubmissionDto { 
    reviewer?: string;
    reviewer_name?: string;
    room_number?: string;
    review_text?: string;
    rating?: number;
    hospital_number?: string;
    hospital_id?: string;
    notes?: string;
    complaintId?: number;
    complaintTypeId?: number;
    status?: UpdateComplaintSubmissionDto.StatusEnum;
    completed_by?: string;
    time_completed?: string;
    id: number;
}
export namespace UpdateComplaintSubmissionDto {
    export type StatusEnum = 'pending' | 'inprogress' | 'submitted' | 'resolved' | 'cannotresolve';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Inprogress: 'inprogress' as StatusEnum,
        Submitted: 'submitted' as StatusEnum,
        Resolved: 'resolved' as StatusEnum,
        Cannotresolve: 'cannotresolve' as StatusEnum
    };
}


