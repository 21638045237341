import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-menu-bar',
  templateUrl: './top-menu-bar.component.html',
  styleUrls: ['./top-menu-bar.component.scss'],
})
export class TopMenuBarComponent implements OnInit {
  @Input() menuData: TopMenuBarData = {};
  iconLocations: TopMenuItemLocations[] = [
    TopMenuItemLocations.LEFT,
    TopMenuItemLocations.CENTER,
    TopMenuItemLocations.RIGHT,
  ];

  constructor() {}

  ngOnInit(): void {}
}

export interface TopMenuBarData {
  [TopMenuItemLocations.LEFT]?: TopMenuBarItem;
  [TopMenuItemLocations.RIGHT]?: TopMenuBarItem;
  [TopMenuItemLocations.CENTER]?: TopMenuBarItem;
}

export type TopMenuBarItem = {
  callback: Function;
  icon?: string;
  text?: string;
  isHome?: boolean;
  color?: string;
  count?: number;
};

export enum TopMenuItemLocations {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}
