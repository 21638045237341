import { createReducer, on } from "@ngrx/store";
import { clearBabyMumData, fetchedBabyMumData, loadingBabyMumDataStatus } from "./baby-mum.actions";

const babyMumDataReducer = createReducer(
  {},
  on(fetchedBabyMumData, (state, props) => {
    return props;
  }),
  on(clearBabyMumData, (state, props) => {
    return {};
  })
);

const loadingBabyMumDataReducer = createReducer(
  false,
  on(loadingBabyMumDataStatus, (state, props) => props.loading)
);

export const babyMumDataReducers = {
  babyMumData: babyMumDataReducer,
  loadingBabyMumDataStatus: loadingBabyMumDataReducer
};

export interface BabyMumDataReducers {
  babyMumData: BabyMumData;
  loadingBabyMumDataStatus: boolean;
}

export interface BabyMumData {
  id: number;
  release_state: string;
  released_by?: string;
  release_audience: string;
  created: string;
  updated?: string;
  deletedAt?: string;
  week_number: number;
  week_name?: string;
  mother_text: string;
  baby_text: string;
  mother_images: string[];
  baby_images: string[];
}