/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FilterWeeklyAntenatalDataDto { 
    mother_text?: string;
    baby_text?: string;
    mother_images?: Array<string>;
    baby_images?: Array<string>;
    release_state?: FilterWeeklyAntenatalDataDto.ReleaseStateEnum;
    released_by?: string;
    release_audience?: FilterWeeklyAntenatalDataDto.ReleaseAudienceEnum;
    week_number?: number;
    week_name?: string;
}
export namespace FilterWeeklyAntenatalDataDto {
    export type ReleaseStateEnum = 'default' | 'released' | 'verified';
    export const ReleaseStateEnum = {
        Default: 'default' as ReleaseStateEnum,
        Released: 'released' as ReleaseStateEnum,
        Verified: 'verified' as ReleaseStateEnum
    };
    export type ReleaseAudienceEnum = 'all' | 'only_doctors' | 'only_patients';
    export const ReleaseAudienceEnum = {
        All: 'all' as ReleaseAudienceEnum,
        OnlyDoctors: 'only_doctors' as ReleaseAudienceEnum,
        OnlyPatients: 'only_patients' as ReleaseAudienceEnum
    };
}


