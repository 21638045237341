import { createReducer, on } from "@ngrx/store";
import { AntenatalEnrollmentDto } from "proxy-sdk";
import { clearAntenatalEnrollment, fetchedAntenatalEnrollment } from "./antenatal-enrollment.actions";

const antenatalEnrollmentReducer = createReducer(
  {},
  on(fetchedAntenatalEnrollment, (state, props) => {
    return props;
  }),
  on(clearAntenatalEnrollment, (state, props) => {
    return {};
  })
);

export const antenatalEnrollmentReducers = {
  antenatalEnrollment: antenatalEnrollmentReducer
};

export interface AntenatalEnrollmentReducers {
  antenatalEnrollment: AntenatalEnrollmentDto;
}