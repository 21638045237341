export * from './complaint.service';
import { ComplaintService } from './complaint.service';
export * from './complaintCategory.service';
import { ComplaintCategoryService } from './complaintCategory.service';
export * from './complaintDetailsAKAComplaintStatus.service';
import { ComplaintDetailsAKAComplaintStatusService } from './complaintDetailsAKAComplaintStatus.service';
export * from './complaintSubmission.service';
import { ComplaintSubmissionService } from './complaintSubmission.service';
export * from './complaintSubmissionProgress.service';
import { ComplaintSubmissionProgressService } from './complaintSubmissionProgress.service';
export * from './complaintType.service';
import { ComplaintTypeService } from './complaintType.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './foodCategory.service';
import { FoodCategoryService } from './foodCategory.service';
export * from './menu.service';
import { MenuService } from './menu.service';
export * from './menuImage.service';
import { MenuImageService } from './menuImage.service';
export * from './order.service';
import { OrderService } from './order.service';
export * from './orderDetailsAKAMenuStatus.service';
import { OrderDetailsAKAMenuStatusService } from './orderDetailsAKAMenuStatus.service';
export * from './report.service';
import { ReportService } from './report.service';
export * from './timeGroup.service';
import { TimeGroupService } from './timeGroup.service';
export * from './timeTables.service';
import { TimeTablesService } from './timeTables.service';
export const APIS = [ComplaintService, ComplaintCategoryService, ComplaintDetailsAKAComplaintStatusService, ComplaintSubmissionService, ComplaintSubmissionProgressService, ComplaintTypeService, DefaultService, FoodCategoryService, MenuService, MenuImageService, OrderService, OrderDetailsAKAMenuStatusService, ReportService, TimeGroupService, TimeTablesService];
