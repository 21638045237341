/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AntenatalQuestionAnswerUpdateDto { 
    _id?: string;
    antenatalEnrollmentId?: object;
    patientFirstName?: string;
    patientLastName?: string;
    patientId?: object;
    hospitalNumber?: string;
    week?: number;
    day?: number;
    questionKey?: string;
    questionText?: string;
    answerKey?: string;
    answerText?: string;
    created_at?: string;
    updated_at?: string;
}

