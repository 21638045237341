import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform(value: string, endDate?: string): unknown {
    let today = new Date();
    
    const date = Date.parse(value);
    let endTime : number;
    if(endDate){
      endTime = Date.parse(endDate)
    } else {
      endTime = new Date().getTime();
    }

    var seconds = Math.floor((endTime - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) return Math.floor(interval) + ' years';
    interval = seconds/ 2592000;
    if (interval > 1) return Math.floor(interval) + ' months';
    interval = seconds/ 86400;
    if (interval > 1) return Math.floor(interval) + ' days';
    interval = seconds/ 3600;
    if (interval > 1) return Math.floor(interval) + ' hours';
    interval = seconds/ 60;
    if (interval > 1) return Math.floor(interval) + ' minutes';
    return Math.floor(interval) + ' seconds';
    

  }
}
