import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, Inject, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[appRespiratoryRate]'
})
export class RespiratoryRateDirective {

  @Input('normal') normal = 0;
  @Input('bradypnea') bradypnea = 0;
  @Input('tachypnea') tachypnea = 0;
  @Input('displayDefaultText') displayDefaultText = true;
  @Input('customText') customText = '';
  @Input('showIcon') showIcon = false;

  iconContainer: ComponentRef<MatIcon> = this.viewContainer.createComponent<MatIcon>(MatIcon);
  textSpan: HTMLElement = this.document.createElement('b');

  constructor(
    private renderer: Renderer2,
    private eleRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: Document,
    private viewContainer: ViewContainerRef
  ) {

  }

  ngAfterViewInit(): void {
    
    this.eleRef.nativeElement.appendChild(this.iconContainer.location.nativeElement);
    this.eleRef.nativeElement.appendChild(this.textSpan);
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
    // this.eleRef.nativeElement.style.color = this.returnResponse().color
  }

  ngOnChanges(): void {
    this.iconContainer.instance._elementRef.nativeElement.innerHTML = this.returnResponse().icon!;
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
    this.eleRef.nativeElement.style.color = this.returnResponse().color;
  }

  returnResponse() {

    if (!this.normal || !this.bradypnea || !this.tachypnea) return { text: "", color: "", icon: '' }
    switch (true) {
      case this.bradypnea < 12 :
        return { text: this.displayDefaultText ? "bradypnea for adult" : this.customText, color: "orange", icon: 'arrow_downward' }
      case this.normal >= 12 && this.normal <= 20:
        return { text: this.displayDefaultText ? "Normal for adult" : this.customText, color: "green", icon: 'check' }
      case this.tachypnea > 20  :
        return { text: this.displayDefaultText ? "tachypnea for adult" : this.customText, color: "red", icon: 'arrow_upward' }
      default:
        return { text: this.displayDefaultText ? "" : this.customText, color: "", icon: '' }
    }
  }


}
