import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BASE_PATH as MY_CARE_SERVICE_BASE_PATH } from 'care-service-sdk';
import { BASE_PATH as MY_HOME_CLINIC_BASE_PATH } from 'my-home-clinic-sdk';
import { BASE_PATH as MY_KIOSK_BASE_PATH } from 'kiosk-service-sdk';
import { BASE_PATH as PROXY_BASE_PATH } from 'proxy-sdk';
import { BASE_PATH as NOTIFICATION_BASE_PATH } from 'sdk/notification/v1';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, HTTP_INTERCEPTORS, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
// import { JwtInterceptor } from './interceptors/jwt.interceptor';
// import { AuthService } from './services/auth.service';
import { EffectsModule } from '@ngrx/effects';
import { AppointmentEffect } from './store/appointment/appointment.effects';
import { StoreModule } from '@ngrx/store';
import { appointmentReducers } from './store/appointment/appointments.reducers';
import { ComponentsModule } from './components/components.module';
import { emergencyReducers } from './store/emergency/emergency.reducers';
import { EmergencyEffect } from './store/emergency/emergency.effects';
import { billReducers } from './store/bill/bill.reducers';
import { BillsEffect } from './store/bill/bill.effects';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { SocketService } from './services/socket.service';
import { generalReducers } from './store/general/general.reducers';
import { antenatalEnrollmentReducers } from './store/antenatal-enrollment/antenatal-enrollment.reducers';
import { AntenatalEnrollmentEffect } from './store/antenatal-enrollment/antenatal-enrollment.effects';
import { babyMumDataReducers } from './store/baby-mum/baby-mum.reducers';
import { BabyMumEffect } from './store/baby-mum/baby-mum.effects';
import { weeklyChecklistReducers } from './store/weekly-checklist/weekly-checklist.reducers';
import { WeeklyChecklistEffect } from './store/weekly-checklist/weekly-checklist.effects';
import { VitalsEffect } from './store/vitals/vitals.effects';
import { vitalsReducers } from './store/vitals/vitals.reducers';
import { VisitorsEffect } from './store/visitors/visitors.effects';
import { visitorsReducers } from './store/visitors/visitors.reducers';
import { AdmissionEffect } from './store/admission/admission.effects';
import { admissionReducers } from './store/admission/admission.reducers';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { NewAuthService } from './services/new-auth.service';
import { ImmunizationEffect } from './store/immunization/immunization.effects';
import { immunizationReducers } from './store/immunization/immunization.reducers';
import { BeneficiaryEffect } from './store/beneficiary/beneficiary.effects';
import { beneficiaryReducers } from './store/beneficiary/beneficiary.reducers';
import { RegisterEffects } from './store/register/register.effects';
import {
  registrationReducer,
  registrationPackagesReducer,
  processingRegistrationReducer,
} from './store/register/register.reducers';
import { VideoCallEffects } from './store/video-call/video-call.effects';
import { videoCallReducers } from './store/video-call/video-call.reducers';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    StoreModule.forRoot({
      ...appointmentReducers,
      ...emergencyReducers,
      ...billReducers,
      ...generalReducers,
      ...antenatalEnrollmentReducers,
      ...babyMumDataReducers,
      ...weeklyChecklistReducers,
      ...vitalsReducers,
      ...visitorsReducers,
      ...admissionReducers,
      ...immunizationReducers,
      ...beneficiaryReducers,
      regData: registrationReducer,
      packages: registrationPackagesReducer,
      processingReg: processingRegistrationReducer,
      ...videoCallReducers,
    }),
    EffectsModule.forRoot([
      AppointmentEffect,
      EmergencyEffect,
      BillsEffect,
      AntenatalEnrollmentEffect,
      BabyMumEffect,
      WeeklyChecklistEffect,
      VitalsEffect,
      VisitorsEffect,
      AdmissionEffect,
      ImmunizationEffect,
      BeneficiaryEffect,
      RegisterEffects,
      VideoCallEffects,
    ]),
    ComponentsModule,
  ],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useFactory: (authService: NewAuthService) => {
    //     return new JwtInterceptor(authService);
    //   },
    //   multi: true,
    //   deps: [NewAuthService],
    // },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
      },
    },
    {
      provide: MY_CARE_SERVICE_BASE_PATH,
      useValue: environment.care_url,
    },
    {
      provide: MY_KIOSK_BASE_PATH,
      useValue: environment.kiosk_url,
    },
    {
      provide: MY_HOME_CLINIC_BASE_PATH,
      useValue: environment.my_home_clinic_url,
    },
    {
      provide: PROXY_BASE_PATH,
      useValue: environment.proxy,
    },
    {
      provide: NOTIFICATION_BASE_PATH,
      useValue: environment.NOTIFICATION_BASE_PATH,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
