/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ComplaintDetail } from './complaintDetail';


export interface Report { 
    id: number;
    reviewer: string;
    reviewer_name: string;
    room_number?: string;
    review_text?: string;
    hospital_number?: string;
    hospital_id?: string;
    status?: object;
    received_by?: string;
    completed_by?: string;
    time_completed?: string;
    rating?: number;
    complaint_details: Array<ComplaintDetail>;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

