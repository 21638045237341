export * from './appointment.service';
import { AppointmentService } from './appointment.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './department.service';
import { DepartmentService } from './department.service';
export * from './finance.service';
import { FinanceService } from './finance.service';
export * from './patient.service';
import { PatientService } from './patient.service';
export * from './service.service';
import { ServiceService } from './service.service';
export const APIS = [AppointmentService, DefaultService, DepartmentService, FinanceService, PatientService, ServiceService];
