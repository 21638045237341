import { createAction, props } from '@ngrx/store';
import { BabyMumData } from './baby-mum.reducers';
// import { / }

export const fetchBabyMumData = createAction(
  'fetchBabyMumData',
  props<{ week: number; }>()
);

export const clearBabyMumData = createAction(
  'clearBabyMumData'
);

export const fetchedBabyMumData = createAction(
  'fetchedBabyMumData',
  props<BabyMumData>()
);

export const loadingBabyMumDataStatus = createAction(
  'loadingBabyMumData',
  props<{ loading: boolean; }>()
);
