import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RatingOption, RatingOptionService, RatingService } from 'proxy-sdk';
import { NewAuthService } from 'src/app/services/new-auth.service';

@Component({
  selector: 'app-rating-staff-dialog',
  templateUrl: './rating-staff-dialog.component.html',
  styleUrls: ['./rating-staff-dialog.component.scss'],
})
export class RatingStaffDialogComponent implements OnInit {
  max_rating: number = 5;
  ratings: number[] = [];
  selected_rating: number = 0;
  message: string = '';
  loading: boolean = false;
  ratingOptions: RatingOption[] = [];
  selectedRatingOption?: RatingOption;

  constructor(
    private dialogRef: MatDialogRef<RatingStaffDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      staff_name: string;
      staff_id: string;
      appointment_id: string;
    },
    private ratingService: RatingService,
    private ratingOptionService: RatingOptionService,
    private snackBar: MatSnackBar,
    private authService: NewAuthService
  ) {
    for (let x = 0; x < this.max_rating; x++) {
      this.ratings.push(x + 1);
    }
  }

  ngOnInit(): void {
    this.fetchRatingOption();
  }
  fetchRatingOption() {
    this.ratingOptionService.ratingOptionControllerFindAll().subscribe({
      next: (response) => {
        this.ratingOptions = response.data;
      }
    })
  }

  selectRating(rating: number){
    this.selected_rating = rating;
    this.selectedRatingOption = this.ratingOptions.find((value) => value.rating == rating);
  }

  rateStaff(): void {
    this.loading = true;
    this.ratingService
      .ratingControllerCreate({
        staff_id: this.data.staff_id,
        appointment_id: this.data.appointment_id,
        staff_name: this.data.staff_name,
        message: this.selectedRatingOption,
        comment: this.message,
        hospital_number: this.authService.patient$.value.hospital_number
      } as any)
      .subscribe({
        next: (response) => {
          if (response.id) {
            this.snackBar.open('Rating Sent', 'SUCCESS');
            this.dialogRef.close();
          } else {
            this.snackBar.open('Something Went Wrong try again', 'ERROR');
          }
        },
      });
  }
}
