/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateAppNotificationDto { 
    title: string;
    message: string;
    icon: string;
    recipient_id: string;
    recipient_id_type: CreateAppNotificationDto.RecipientIdTypeEnum;
    sender_id: string;
    sender_id_type: CreateAppNotificationDto.SenderIdTypeEnum;
    action: CreateAppNotificationDto.ActionEnum;
    action_param: string;
    status: CreateAppNotificationDto.StatusEnum;
}
export namespace CreateAppNotificationDto {
    export type RecipientIdTypeEnum = 'HOSPITAL_ID' | 'PATIENT_ID' | 'STAFF_ID' | 'CAREGIVER_ID' | 'KEYCLOAK_ID' | 'FIREBASE_ID' | 'EMAIL' | 'PHONE_NUMBER' | 'SYSTEM';
    export const RecipientIdTypeEnum = {
        HospitalId: 'HOSPITAL_ID' as RecipientIdTypeEnum,
        PatientId: 'PATIENT_ID' as RecipientIdTypeEnum,
        StaffId: 'STAFF_ID' as RecipientIdTypeEnum,
        CaregiverId: 'CAREGIVER_ID' as RecipientIdTypeEnum,
        KeycloakId: 'KEYCLOAK_ID' as RecipientIdTypeEnum,
        FirebaseId: 'FIREBASE_ID' as RecipientIdTypeEnum,
        Email: 'EMAIL' as RecipientIdTypeEnum,
        PhoneNumber: 'PHONE_NUMBER' as RecipientIdTypeEnum,
        System: 'SYSTEM' as RecipientIdTypeEnum
    };
    export type SenderIdTypeEnum = 'HOSPITAL_ID' | 'PATIENT_ID' | 'STAFF_ID' | 'CAREGIVER_ID' | 'KEYCLOAK_ID' | 'FIREBASE_ID' | 'EMAIL' | 'PHONE_NUMBER' | 'SYSTEM';
    export const SenderIdTypeEnum = {
        HospitalId: 'HOSPITAL_ID' as SenderIdTypeEnum,
        PatientId: 'PATIENT_ID' as SenderIdTypeEnum,
        StaffId: 'STAFF_ID' as SenderIdTypeEnum,
        CaregiverId: 'CAREGIVER_ID' as SenderIdTypeEnum,
        KeycloakId: 'KEYCLOAK_ID' as SenderIdTypeEnum,
        FirebaseId: 'FIREBASE_ID' as SenderIdTypeEnum,
        Email: 'EMAIL' as SenderIdTypeEnum,
        PhoneNumber: 'PHONE_NUMBER' as SenderIdTypeEnum,
        System: 'SYSTEM' as SenderIdTypeEnum
    };
    export type ActionEnum = 'CALL' | 'CHAT' | 'ANTENATAL' | 'APPOINTMENT' | 'GENERIC' | 'MESSAGE';
    export const ActionEnum = {
        Call: 'CALL' as ActionEnum,
        Chat: 'CHAT' as ActionEnum,
        Antenatal: 'ANTENATAL' as ActionEnum,
        Appointment: 'APPOINTMENT' as ActionEnum,
        Generic: 'GENERIC' as ActionEnum,
        Message: 'MESSAGE' as ActionEnum
    };
    export type StatusEnum = 'READ' | 'NEW' | 'HIDDEN';
    export const StatusEnum = {
        Read: 'READ' as StatusEnum,
        New: 'NEW' as StatusEnum,
        Hidden: 'HIDDEN' as StatusEnum
    };
}


