import { createAction, props } from '@ngrx/store';
import { VitalsDto } from 'proxy-sdk';
import { IVitalField } from 'src/app/pages/vitals/vitals-field';

export enum AfterAddingVitalsEnum {
  DEFAULT,
  SUCCESS,
  FAILED,
}

export const fetchVitals = createAction(
  'fetchVitals',
  props<{ page: number; limit: number }>()
);

export const fetchVital = createAction('fetchVital', props<{ _id: string }>());

export const totalVitalsHistory = createAction(
  'totalVitalsHistory',
  props<{ total: number }>()
);

export const loadingVitals = createAction(
  'total',
  props<{ loading: boolean }>()
);

export const fetchedVitals = createAction(
  'fetchedVitals',
  props<{ vitals: VitalsDto[] }>()
);

export const fetchedVital = createAction(
  'fetchedVital',
  props<{ vital: VitalsDto }>()
);

export const setVitalFields = createAction(
  'setVitalFields',
  props<{ fields: IVitalField[] }>()
);

export const addVital = createAction(
  'addVitals',
  props<{ vital: VitalsDto, isFromAntenatal: boolean }>()
);

export const addVitalsSuccess = createAction(
  'addVitalsSuccess',
  props<{
    state?: AfterAddingVitalsEnum.DEFAULT | AfterAddingVitalsEnum.SUCCESS;
    isFromAntenatal: boolean;
  }>()
);

export const addVitalsFailure = createAction(
  'addVitalsFailure',
  props<{
    state?: AfterAddingVitalsEnum.DEFAULT | AfterAddingVitalsEnum.FAILED;
    isFromAntenatal: boolean;
  }>()
);
