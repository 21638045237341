import { createReducer, on } from '@ngrx/store';
import {
  Emergency,
  EmergencyCategory,
  fetchEmergenciesSuccess,
  fetchEmergencyCategoriesSuccess,
  selectGuide,
} from './emergency.actions';

const emergenciesReducer = createReducer(
  [] as Emergency[],
  on(fetchEmergenciesSuccess, (state, props) => {
    return props.emergencies;
  })
);
const emergencyCategoriesReducer = createReducer(
  [] as EmergencyCategory[],
  on(fetchEmergencyCategoriesSuccess, (state, props) => {
    return props.emergencyCategories;
  })
);
const selectedGuideReducer = createReducer(
  {} as Emergency,
  on(selectGuide, (state, props) => {
    return props.emergency;
  })
);

const emergencyOptionsReducer = createReducer([
  {
    title: 'Severe Chest Pain',
    key: 'severeChestPain',
  },
  { title: 'Severe Abdominal Pain', key: 'Severe Abdominal Pain', ref: '' },
  { title: 'Sudden Collapse', key: 'Sudden Collapse', ref: '' },
  { title: 'Breathing Difficulty', key: 'Breathing Difficulty', ref: '' },
  { title: 'Bleeding', key: 'Bleeding' },
  { title: 'Sudden Weakness', key: 'Sudden Weakness', ref: '' },
  { title: 'Bite', key: 'Bite', ref: '' },
  { title: 'Pregnancy Crisis', key: 'Pregnancy Crisis', ref: '' },
  { title: 'Convulsion', key: 'Convulsion', ref: '' },
  { title: 'Loss of Conciousness', key: 'Loss of Conciousness', ref: '' },
  { title: 'Road Traffic Accident', key: 'Road Traffic Accident', ref: '' },
]);

export const emergencyReducers = {
  emergencies: emergenciesReducer,
  emergencyCategories: emergencyCategoriesReducer,
  selectedGuide: selectedGuideReducer,
  emergencyOptions: emergencyOptionsReducer,
};
