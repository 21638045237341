import { createReducer, on } from '@ngrx/store';
// import {
//   IUserGroupGroupId,
//   PartialIGroup,
//   PartialIPatient,
//   RegisterPatientData,
// } from 'sdk/kisok-service/v1';
import { environment } from 'src/environments/environment';

import {
  fetchRegistrationPackages, processingRegistration,
  registrationFailed,
  registrationSuccessful,
  restartRegistration,
  storeRegistrationPackages
} from './register.actions';
import { PartialIGroup } from 'kiosk-service-sdk';

export const initRegData: Record<string, any> = {
  fname: '',
  lname: '',
  oname: '',
  sex: '',
  email: '',
  phoneNumber: '',
  address: '',
  nextOfKinFname: '',
  nextOfKinLname: '',
  nextOfKinSex: '',
  nextOfKinPhoneNumber: '',
  nextOfKinRelationship: '',
  nextOfKinAddress: '',
  nextOfKinEmail: '',
  nextOfKinOccupation: '',
  date_of_birth: ''
};

export const registrationReducer = createReducer(
  initRegData,
  on(registrationSuccessful, (state, props) => {
    return state;
  }),
  on(registrationFailed, (state, props) => {
    return props.patientData;
  }),
  on(restartRegistration, (state, props) => {
    return {};
  })
);

const initRegGroupData: PartialIGroup[] = [];
export const registrationPackagesReducer = createReducer(
  initRegGroupData,
  on(storeRegistrationPackages, (state, props) => {
    return props.packages;
  })
);

const initValue: Partial<boolean> = false;

export const processingRegistrationReducer = createReducer(
  initValue,
  on(processingRegistration, (state, props) => props.loading)
);
