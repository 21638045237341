/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  ICreateAppointmentParamsPastConsultantsInner - a model defined in OpenAPI      replaced_at: The replaced_at of this ICreateAppointmentParamsPastConsultantsInner.     consultant_id: The consultant_id of this ICreateAppointmentParamsPastConsultantsInner.     consultant_name: The consultant_name of this ICreateAppointmentParamsPastConsultantsInner.     id: The id of this ICreateAppointmentParamsPastConsultantsInner [Optional].
 */
export interface ICreateAppointmentParamsPastConsultantsInner { 
    replaced_at: string;
    consultantId: string;
    consultantName: string;
    _id?: string;
}

