/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IBeneficiaryUserGroupId } from './iBeneficiaryUserGroupId';


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  PartialIGeneralDetailsBeneficiaryId - a model defined in OpenAPI      id: The id of this PartialIGeneralDetailsBeneficiaryId [Optional].     hospital_number: The hospital_number of this PartialIGeneralDetailsBeneficiaryId [Optional].     user_group_id: The user_group_id of this PartialIGeneralDetailsBeneficiaryId.     ownership: The ownership of this PartialIGeneralDetailsBeneficiaryId [Optional].     created_at: The created_at of this PartialIGeneralDetailsBeneficiaryId [Optional].     updated_at: The updated_at of this PartialIGeneralDetailsBeneficiaryId [Optional].     error: The error of this PartialIGeneralDetailsBeneficiaryId [Optional].
 */
export interface PartialIGeneralDetailsBeneficiaryId { 
    _id?: string;
    hospital_number?: string;
    userGroupId: IBeneficiaryUserGroupId;
    ownership?: string;
    created_at?: string;
    updated_at?: string;
    error?: string;
}

