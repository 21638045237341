import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AdmissionData, AntenatalEnrollmentDto } from 'proxy-sdk';
import { environment } from 'src/environments/environment';
import { HomeActions } from '../interfaces/home-actions';
import { AdmissionReducers } from '../store/admission/admission.reducers';
import { AntenatalEnrollmentReducers } from '../store/antenatal-enrollment/antenatal-enrollment.reducers';
// import { AuthService } from './auth.service';
import { EmergencyService } from './emergency.service';
import { NewAuthService } from './new-auth.service';
import Swal from 'sweetalert2';
import { JoinMeeting } from '../store/video-call/video-call.actions';
import { MatDialog } from '@angular/material/dialog';
import { RatingStaffDialogComponent } from '../components/rating-staff-dialog/rating-staff-dialog.component';
import { DataDeletionRequestComponent } from '../data-deletion-request/data-deletion-request.component';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  antenatalTitle: string = 'My Baby&I';

  actions: HomeActions[] = [
    {
      title: 'Appointments',
      icon: 'event_note',
      class: 'blue-bg',
      disabled: false,
      showInHome: true,
      action: (index) => {
        this.router.navigateByUrl('/appointment');
      },
      requires: 'none',
    },
    {
      title: 'My Care',
      icon: 'comment',
      disabled: false,
      showInHome: true,
      class: 'blue-bg',
      action: (index) => {
        this.router.navigateByUrl('/care');
      },
      requires: 'admission',
    },
    // {
    //   title: 'Kitchen',
    //   icon: 'restaurant',
    //   class: 'blue-bg',
    //   disabled: false,
    //   showInHome: true,

    //   action: (index) => {
    //     this.router.navigateByUrl('/my-kitchen');
    //   },
    //   requires: 'admission',
    // },
    {
      title: 'My Bills',
      icon: 'payment',
      class: 'blue-bg',
      disabled: false,
      showInHome: true,

      action: (index) => {
        this.router.navigateByUrl('/bills');
      },
      requires: 'none',
    },
    {
      title: 'Vitals',
      icon: 'monitor_heart',
      class: 'blue-bg',
      disabled: false,
      showInHome: true,

      action: (index) => {
        this.router.navigateByUrl('/vitals');
      },
      requires: 'none',
    },
    // {
    //   title: 'Visitors',
    //   icon: 'group',
    //   class: 'blue-bg',
    //   disabled: false,
    //   showInHome: true,

    //   action: (index) => {
    //     this.router.navigateByUrl('/visitors');
    //   },
    //   requires: 'admission',
    // },
    {
      title: 'Beneficiaries',
      icon: 'group',
      class: 'blue-bg',
      disabled: false,
      showInHome: true,

      action: (index) => {
        this.router.navigateByUrl('/beneficiaries');
      },
      requires: 'none',
    },
    {
      title: 'Queue',
      icon: 'group',
      class: 'green-bg',
      disabled: false,
      showInHome: true,
      action: (index) => {
        this.router.navigateByUrl('/queue');
      },
      requires: 'none',
    },
    // {
    //   title: 'Queue',
    //   icon: 'group',
    //   class: 'blue-bg',
    //   disabled: environment.production,
    //   action: (index) => {},
    // },
    // {
    //   title: 'Video Chat',
    //   icon: 'video_call',
    //   class: 'blue-bg',
    //   disabled: false,
    //   action: (index) => {
    //     // alert(
    //     //   'You will immediately join a chat with your doctor if you have an appointment.'
    //     // );
    //     // this.router.navigateByUrl('/appointment/list?find-video=yes');

    //     Swal.fire({
    //       title: 'Enter your meeting code',
    //       icon: 'info',
    //       input: 'text',
    //       inputValue: '',
    //       inputLabel: 'Meeting Code',
    //       confirmButtonText: 'Join',
    //       showCancelButton: false,
    //       confirmButtonColor: '#1e88e5',
    //     }).then((res) => {
    //       if(res.isConfirmed){
    //         console.log(res);
    //         const patientName: string = `${this.authService.patient$.value.fname} ${this.authService.patient$.value.lname}`;
    //         this.store.dispatch(JoinMeeting({ meetingId: res.value, attendantId: patientName, participantType: 'host'}))
    //         this.router.navigate(["/chat/lib"])
    //       }
    //     });
    //   },
    //   requires: 'none',
    // },
    {
      title: this.antenatalTitle,
      icon: 'favorite',
      class: 'purple-bg',
      disabled: false,
      showInHome: true,

      action: (index) => {
        this.router.navigateByUrl('/baby-and-i');
      },
      requires: 'antenatal',
    },
    {
      title: 'My Medicals',
      icon: 'health_and_safety',
      class: 'blue-bg',
      disabled: false,
      showInHome: true,
      loading: false,
      isSubscribed: false,
      action: async (index) => {
        this.router.navigateByUrl('/my-health-summary');
      },
      requires: 'none',
    },
    {
      title: 'Emergency',
      icon: 'emergency',
      class: 'red-bg',
      disabled: false,
      showInHome: true,
      loading: false,
      isSubscribed: false,
      action: async (index) => {
        this.emergencyService.haveEmergency();
      },
      requires: 'none',
    },
    {
      title: 'Feedbacks',
      icon: 'feedback',
      disabled: false,
      showInHome: true,
      class: 'blue-bg',
      action: (index) => {
        this.router.navigateByUrl('/care/category/1');
      },
      requires: 'none',
    },
    // {
    //   title: 'Ratings',
    //   icon: 'star',
    //   disabled: false,
    //   class: 'blue-bg',
    //   action: (index) => {
    //     this.dialog.open(RatingStaffDialogComponent, {
    //       data: {
    //         staff_name: "Arinze Aguolu",
    //         staff_id: "9c99ff52-1c47-4688-a05e-0a3a69ba01b0",
    //         appointment_id: "654b5ef21042314825c0f303"
    //       }
    //     })
    //   },
    //   requires: 'none',
    // },
    {
      title: 'Delete My Data',
      icon: 'delete_forever',
      class: 'red-bg',
      disabled: false,
      showInHome: false,
      action: (index) => {
        this.router.navigateByUrl('/removal-request');
      },
      requires: 'none',
    },
    {
      title: 'Sign Out',
      icon: 'exit_to_app',
      class: '',
      disabled: false,
      showInHome: true,

      action: (index) => {
        this.signOut();
      },
      requires: 'none',
    },
  ];

  antenatal?: AntenatalEnrollmentDto;
  admission?: AdmissionData;
  constructor(
    public authService: NewAuthService,
    private router: Router,
    private emergencyService: EmergencyService,
    private dialog: MatDialog,
    private store: Store<AntenatalEnrollmentReducers & AdmissionReducers>
  ) {
    // this.store.select('antenatalEnrollment').subscribe({
    //   next: (data) => {
    //     this.antenatal = data;
    //     console.log(this.antenatal);
    //     this.actions = this.actions.map((value) => {
    //       if (value.requires === 'antenatal') {
    //         value.disabled = true;
    //         if (this.antenatal?._id) {
    //           value.disabled = false;
    //         }
    //       }
    //       return value;
    //     });
    //   },
    // });
    this.store.select('admissionInfo').subscribe({
      next: (data) => {
        this.admission = data;
        this.actions = this.actions.map((value) => {
          if (value.requires === 'admission') {
            value.disabled = true;
            if (this.admission?.id) {
              value.disabled = false;
            }
          }
          return value;
        });
      },
    });
  }

  callAction(homeAction: HomeActions, index: number) {
    if (homeAction.disabled) return;
    homeAction.action(index);
  }

  signOut() {
    window.localStorage.removeItem('rememberMeToken');
    this.authService.signOut();
  }
}
