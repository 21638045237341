/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GrantApprovalRequestDto { 
    /**
     * A String value of id
     */
    id?: number;
    /**
     * A Enum value of approval status
     */
    status?: GrantApprovalRequestDto.StatusEnum;
    /**
     * A boolean value of blocked status
     */
    blocked?: boolean;
    /**
     * A boolean value of approved status
     */
    approved?: boolean;
    /**
     * A strng value of hospital number
     */
    hospital_number?: string;
}
export namespace GrantApprovalRequestDto {
    export type StatusEnum = 'pending' | 'approved' | 'blocked' | 'cancelled';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Approved: 'approved' as StatusEnum,
        Blocked: 'blocked' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
}


