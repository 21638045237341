/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type PaymentStatus = 'paid' | 'pending';

export const PaymentStatus = {
    Paid: 'paid' as PaymentStatus,
    Pending: 'pending' as PaymentStatus
};

