/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PartialAppointment { 
    hospital_number: string;
    patientFirstName: string;
    patientLastName: string;
    serviceKey?: string;
    cost?: string;
    sex?: string;
    hospital_branch?: string;
    departmentName?: string;
    departmentRoute?: string;
    date_of_appointment?: string;
    type_of_appointment?: string;
    status?: string;
    patient?: string;
}

