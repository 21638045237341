/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GormDeletedAt } from './gormDeletedAt';


export interface ModelsNotification { 
    action_param?: string;
    app_action?: string;
    createdAt?: string;
    deletedAt?: GormDeletedAt;
    event?: string;
    icon?: string;
    id?: number;
    is_active?: boolean;
    is_hidden?: boolean;
    is_read?: boolean;
    is_staff?: boolean;
    is_verified_user?: boolean;
    message?: string;
    recipient_id?: string;
    recipient_name?: string;
    recipient_type?: string;
    sender_id?: string;
    sender_name?: string;
    sender_type?: string;
    title?: string;
    updatedAt?: string;
}

