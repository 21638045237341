/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IFollowUpVisit { 
    _id?: object;
    appointmentId: object;
    enrollmentId: object;
    heightOfFundus?: number;
    weight?: number;
    relationOfPresentingPartOfBrim?: string;
    urine?: string;
    oedema?: string;
    presentationAndPosition?: string;
    foetalHeart?: string;
    bp?: string;
    hb?: number;
    remarks?: string;
    returnDate?: string;
    created_at?: string;
    updated_at?: string;
}

