/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AntenatalEnrollmentDto { 
    _id?: string;
    patientFirstName?: string;
    patientLastName?: string;
    patientId: object;
    hospitalNumber: string;
    patientDateOfBirth?: string;
    partnersName?: string;
    partnersOccupation?: string;
    partnersEmployer?: string;
    lastMenstrualPeriod?: string;
    expectedDeliveryDate?: string;
    isExpired?: boolean;
    expirationDate?: string;
    expirationReason?: object;
    totalPreviousPregnancies?: number;
    numberOfLivingChildren?: number;
    durationOfPregnancyInWeeks?: string;
    pregnancyPackageName: string;
    pregnancyPackageId: number;
    pregnancyPackageKey: string;
    pregnancyPackageCost: number;
    appointmentId?: object;
    practitioner?: string;
    practitionerFirstName?: string;
    practitionerLastName?: string;
    practitionerId?: string;
    slug?: string;
    created_at?: string;
    updated_at?: string;
}

