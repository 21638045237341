/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CareGiverDto } from './careGiverDto';
import { ICreateAppointmentParamsQaInner } from './iCreateAppointmentParamsQaInner';
import { ICreateAppointmentParamsLocationCoordinates } from './iCreateAppointmentParamsLocationCoordinates';
import { IAppointmentPastConsultantsInner } from './iAppointmentPastConsultantsInner';


export interface CreateAppointmentDto { 
    _id?: string;
    clinic?: string;
    departmentName?: string;
    departmentRoute?: string;
    departmentId?: string;
    practitioner?: string;
    practitionerFirstName?: string;
    practitionerLastName?: string;
    practitionerId?: string;
    date_of_appointment?: string;
    type_of_consultation?: CreateAppointmentDto.TypeOfConsultationEnum;
    status?: CreateAppointmentDto.StatusEnum;
    paymentStatus?: boolean;
    patient?: object;
    patientHmoCode?: string;
    hmoCode?: string;
    email?: string;
    phone?: string;
    packageName?: string;
    hospital_number?: string;
    sex?: CreateAppointmentDto.SexEnum;
    comment?: string;
    reasons_for_appointment?: Array<string>;
    patientFirstName?: string;
    patientLastName?: string;
    createdByFirstName?: string;
    createdByLastName?: string;
    createdById?: string;
    created_at?: string;
    updated_at?: string;
    pastConsultants?: Array<IAppointmentPastConsultantsInner>;
    doctorsInstruction?: string;
    lastAppointmentId?: object;
    slug?: string;
    hospital_branch?: string;
    age?: number;
    subscriptionId?: object;
    serviceId?: string;
    serviceKey?: string;
    cost?: number;
    completed_at?: string;
    qa?: Array<ICreateAppointmentParamsQaInner>;
    completedComplaints?: Array<string>;
    bookingType?: CreateAppointmentDto.BookingTypeEnum;
    isEmergency?: boolean;
    needsAmbulance?: boolean;
    locationCoordinates?: ICreateAppointmentParamsLocationCoordinates;
    address?: string;
    checkInStatus?: CreateAppointmentDto.CheckInStatusEnum;
    checkInTime?: string;
    checkOutTime?: string;
    callStatus?: CreateAppointmentDto.CallStatusEnum;
    callUrl?: string;
    callDuration?: number;
    callStartTime?: string;
    callEndTime?: string;
    callRecordingUrl?: string;
    careGiver?: CareGiverDto;
}
export namespace CreateAppointmentDto {
    export type TypeOfConsultationEnum = 'first-time' | 'follow-up' | 'referral' | 'ward-round';
    export const TypeOfConsultationEnum = {
        FirstTime: 'first-time' as TypeOfConsultationEnum,
        FollowUp: 'follow-up' as TypeOfConsultationEnum,
        Referral: 'referral' as TypeOfConsultationEnum,
        WardRound: 'ward-round' as TypeOfConsultationEnum
    };
    export type StatusEnum = 'pending' | 'vitals-checked' | 'in-progress' | 'done' | 'cancelled' | 'vitals-in-progress' | 'consulting' | 'see-doctor-vitals' | 'see-doctor-lab' | 'see-doctor-rad';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        VitalsChecked: 'vitals-checked' as StatusEnum,
        InProgress: 'in-progress' as StatusEnum,
        Done: 'done' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        VitalsInProgress: 'vitals-in-progress' as StatusEnum,
        Consulting: 'consulting' as StatusEnum,
        SeeDoctorVitals: 'see-doctor-vitals' as StatusEnum,
        SeeDoctorLab: 'see-doctor-lab' as StatusEnum,
        SeeDoctorRad: 'see-doctor-rad' as StatusEnum
    };
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type BookingTypeEnum = 'self' | 'other';
    export const BookingTypeEnum = {
        Self: 'self' as BookingTypeEnum,
        Other: 'other' as BookingTypeEnum
    };
    export type CheckInStatusEnum = 'pending' | 'checkin' | 'checkout';
    export const CheckInStatusEnum = {
        Pending: 'pending' as CheckInStatusEnum,
        Checkin: 'checkin' as CheckInStatusEnum,
        Checkout: 'checkout' as CheckInStatusEnum
    };
    export type CallStatusEnum = 'active' | 'inactive' | 'completed';
    export const CallStatusEnum = {
        Active: 'active' as CallStatusEnum,
        Inactive: 'inactive' as CallStatusEnum,
        Completed: 'completed' as CallStatusEnum
    };
}


