import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessagingService } from 'proxy-sdk';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  mode = environment.messaging_mode;

  constructor(private messagingService: MessagingService) {}

  getVideoCallUrl(params: GetVideoLinkParams) {
    switch (this.mode) {
      case 'TWILIO':
        return this.twilioUrl(params.hostToken);
      case 'INVIDEO':
        return this.inVideoUrl(params);
      default:
        return this.inVideoUrl(params);
    }
  }

  twilioUrl(hostToken?: string) {
    if (!hostToken) {
      throw new Error('hostToken is required');
    }

    return this.messagingService.messagingControllerGenerateAccessToken({
      hostToken,
    });
    // return this.httpService.post<any>(
    //   `${environment.messaging_url}/get-twilio-token?sessionName=${params.sessionName}&meetingName=${params.meetingName}&hostId=${params.hostId}&duration=${params.duration}`,
    //   {},
    // );
  }

  inVideoUrl(params: GetVideoLinkParams) {
    return of();
    // return this.httpService.post<any>(
    //   `${environment.messaging_url}/generate-video-link?sessionName=${params.sessionName}&meetingName=${params.meetingName}&hostId=${params.hostId}&duration=${params.duration}`,
    //   {},
    // );
  }

  sendSms(params: SendSmsParams) {
    // return this.httpService.post<any>(
    //   `${environment.messaging_url}/send-sms?to=${params.to}&message=${params.message}`,
    //   params,
    // );
  }
}

export interface GetVideoLinkParams {
  hostToken: string;
}

export interface SendSmsParams {
  to: string;
  message: string;
}
