/**
 * home-clinic-proxy-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.1
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GrantApprovalRequest { 
    id?: number;
    uuid?: string;
    email?: string;
    hospital_number?: string;
    patient_name?: string;
    firebase_id?: string;
    status?: GrantApprovalRequest.StatusEnum;
    approved?: boolean;
    blocked?: boolean;
    created?: string;
    updated?: string;
    deletedAt?: string;
}
export namespace GrantApprovalRequest {
    export type StatusEnum = 'pending' | 'approved' | 'blocked' | 'cancelled';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Approved: 'approved' as StatusEnum,
        Blocked: 'blocked' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
}


