import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import {
  AppointmentStateAction,
  fetchAppointmentServices,
  fetchDefaultComplaints,
} from './store/appointment/appointment.actions';
import { storePushNotificationToken } from './store/general/general.actions';
import {
  AppUpdate,
  AppUpdateAvailability,
  AppUpdateInfo,
} from '@capawesome/capacitor-app-update';
import { App } from '@capacitor/app';
import { Platform } from '@angular/cdk/platform';
// import { AuthService } from './services/auth.service';
import { Socket } from 'ngx-socket-io';
import { LoadingService } from './services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { QuickAccessComponent } from './components/quick-access/quick-access.component';
import { LocalNotifications } from '@capacitor/local-notifications';
import { GeneralService } from './services/general.service';
import { NativeSocketService } from './services/native-socket.service';
import { of, skipWhile, take } from 'rxjs';
import { NewAuthService } from './services/new-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'care-app-frontend';
  public appUpdateInfo: AppUpdateInfo | undefined;

  loading = false;

  myaction = () => {
    console.log('custom action');
    alert('WONDERMENT');
  };

  constructor(
    public store: Store<{ appointmentActionState: AppointmentStateAction }>,
    // private authService: AuthService,
    // private socketService: SocketService,
    private readonly platform: Platform,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private generalService: GeneralService,
    private authService: NewAuthService,
    private nativeSocketService: NativeSocketService
  ) {
    this.store.dispatch(fetchAppointmentServices());

    this.loadingService.loading.subscribe((val) => {
      this.loading = val;
    });

    this.store.select('appointmentActionState').subscribe((val) => {
      if (val?.action === 'booking') {
        this.loading = true;
      } else {
        this.loading = false;
      }
    });

    // this.authService.patient$.subscribe((val) => {
    //   if (!val._id) {
    //     this.loading = true;
    //   } else {
    //     this.loading = false;
    //   }
    // });
  }
  setupSocket() {
    console.log('createing socket');
    this.authService.patient$
      .pipe(
        skipWhile((val) => {
          console.log(val);
          return !val?.hospital_number;
        }),
        take(1)
      )
      .subscribe({
        next: (res) => {
          console.log('connecting to socket');
          this.nativeSocketService.connectSocket(
            res?.hospital_number,
            res?.hospital_number,
            true
          );
        },
      });
  }

  ngOnInit() {
    // this.authService.patient$.subscribe({
    //   next: (val) => {
    //     console.log('appser',val)
    //     this.nativeSocketService.connectSocket(val.id!, val.id!, true);
    //   },
    // });
    this.setupSocket();
    if (this.platform.ANDROID || this.platform.IOS) {
      AppUpdate.getAppUpdateInfo().then((appUpdateInfo) => {
        this.appUpdateInfo = appUpdateInfo;
        if (
          this.appUpdateInfo?.updateAvailability ===
          AppUpdateAvailability.UPDATE_AVAILABLE
        ) {
          if (
            this.platform.ANDROID &&
            this.appUpdateInfo.flexibleUpdateAllowed
          ) {
            this.startFlexibleUpdate().then();
          } else {
            this.openAppStore().then();
          }
        }
      });
    }
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      console.log('Push registration success, token: ' + token.value);
      this.store.dispatch(storePushNotificationToken({ token: token.value }));
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        LocalNotifications.schedule({
          notifications: [
            {
              title: notification.title || 'Incoming Video Call',
              body: notification.body || 'Incoming Video call',
              id: 1,
              actionTypeId: 'OPEN_APP',
              extra: null,
            },
          ],
        });
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.createChannel({
      id: 'android_channel',
      name: 'My Channel',
      description: 'This is my custom channel',
      importance: 4,
      sound: 'calm_ringtone.mp3',
      vibration: true,
      lights: true,
      lightColor: 'blue',
    });
  }

  async getCurrentAppVersion(): Promise<void> {}

  async openAppStore(): Promise<void> {
    await AppUpdate.openAppStore();
  }

  public async performImmediateUpdate(): Promise<void> {
    await AppUpdate.performImmediateUpdate();
  }

  public async startFlexibleUpdate(): Promise<void> {
    await AppUpdate.startFlexibleUpdate();
  }

  public async completeFlexibleUpdate(): Promise<void> {
    await AppUpdate.completeFlexibleUpdate();
  }
}
