import { createAction, props } from '@ngrx/store';
import { PatientData } from 'proxy-sdk';
import { GetVideoLinkParams } from 'src/app/services/communication.service';


export const JoinMeeting = createAction(
  'JoinMeeting',
  props<{ meetingId: string, attendantId: string, participantType: 'guest' | 'host'}>()
)

export const LeaveMeeting = createAction(
  'LeaveMeeting',
)

export const startVideoCall = createAction(
  'StartVideoCall',
  props<VideoCallData>()
);

export const endVideoCall = createAction('EndVideoCall');

export const GetVideoLink = createAction(
  'GetVideoLink',
  props<GetVideoLinkParams>()
);

export const GetVideoLinkSuccess = createAction(
  'GetVideoLinkSuccess',
  props<{
    token?: string;
    link?: string;
    originalLink?: string;
    error?: boolean;
  }>()
);

export const GetVideoLinkFailure = createAction('GetVideoLinkFailure');

export interface VideoCallData {
  callerId?: string;
  patient?: PatientData;
  link?: string;
  originalLink?: string;
  hostLink?: string;
  hostToken?: string;
  loading?: boolean;
  token?: string;
}
