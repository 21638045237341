<!-- <mat-nav-list> -->
<div class="requests">
  <div><b *ngIf="!data?.hideWelcomeMessage">Welcome To MyHomeClinic</b></div>
  <div>What do you want to do?</div>

  <div
    *ngFor="let activity of activities"
    class="example-margin request-container"
  >
    <mat-checkbox
      color="primary"
      [checked]="activity.selected"
      #checkbox
      (change)="activity.selected = checkbox.checked"
    >
    </mat-checkbox>
    <div
      class="can-wrap"
      (click)="checkbox.toggle(); activity.selected = checkbox.checked"
    >
      {{ activity.title }}
    </div>
  </div>

  <div class="action-btns">
    <button color="accent" mat-raised-button (click)="closeDialog(true)">
      Cancel
    </button>
    <button [disabled]="cantProceed" color="primary" mat-raised-button (click)="createAppointment()">
      Proceed
    </button>
  </div>
</div>
<!-- </mat-nav-list> -->
