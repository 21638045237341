import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, Inject, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[appTemperature]',
})
export class TemperatureDirective {
  @Input('normal') normal = 0;
  @Input('low') low = 0;
  @Input('fever') fever = 0;
  @Input('displayDefaultText') displayDefaultText = true;
  @Input('customText') customText = '';
  @Input('showIcon') showIcon = false;

  iconContainer: ComponentRef<MatIcon> = this.viewContainer.createComponent<MatIcon>(MatIcon);
  textSpan: HTMLElement = this.document.createElement('b');
  
  constructor(
    private eleRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: Document,
    private viewContainer: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    
    this.eleRef.nativeElement.appendChild(this.iconContainer.location.nativeElement);
    this.eleRef.nativeElement.appendChild(this.textSpan);
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
  }

  ngOnChanges(): void {
    this.iconContainer.instance._elementRef.nativeElement.innerHTML = this.returnResponse().icon!;
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
    this.eleRef.nativeElement.style.color = this.returnResponse().color;
  }

  returnResponse() {
    if (!this.normal || !this.low || !this.fever)
      return { text: '', color: '', icon: '' };
    switch (true) {
      case this.low < 36.5:
        return {
          text: this.displayDefaultText
            ? 'Low Temperature for adult'
            : this.customText,
          color: 'orange',
          icon: 'arrow_downward'
        };
      case this.normal >= 36.5 && this.normal <= 37.5:
        return {
          text: this.displayDefaultText ? 'Normal for adult' : this.customText,
          color: 'green',
          icon: "check",

        };
      case this.fever > 37.5:
        return {
          text: this.displayDefaultText ? 'Fever for adult' : this.customText,
          color: 'red',
          icon: 'arrow_upward'
        };
      default:
        return {
          text: this.displayDefaultText ? '' : this.customText,
          color: '',
          icon: ''
        };
    }
  }
}
