import { createAction, props } from '@ngrx/store';
import {
  AppointmentDto,
  AppointmentServiceDto,
  CreateAppointmentDto,
  PatientData
} from 'proxy-sdk';

export const fetchAppointments = createAction(
  'AppointmentList',
  props<{ firebaseId: string }>()
);

export const bookAppointment = createAction(
  'BookAppointment',
  props<{ appointment: CreateAppointmentDto }>()
);

export const bookQuickAppointment = createAction('BookQuickAppointment');

export const appointmentActionState = createAction(
  'ChangeAppointmentActionState',
  props<AppointmentStateAction>()
);

export const fetchAppointmentServices = createAction('LoadAppointmentServices');
export const storeAppointmentServices = createAction(
  'StoreAppointmentServices',
  props<{ services: AppointmentServiceDto[] }>()
);

export const fetchDefaultComplaints = createAction(
  'FetchDefaultComplaints',
  props<{ sex: PatientData['sex'], dob: string }>()
);
export const storeDefaultComplaints = createAction(
  'StoreDefaultComplaints',
  props<{ complaints: Record<string, any> }>()
);

export const fetchAppointmentList = createAction(
  'fetchAppointmentList',
  props<{ filter: {} }>()
);

export const storeAppointmentList = createAction(
  'storeAppointmentList',
  props<{ appointments: Array<AppointmentDto>; }>()
);

export const fetchingAppointmentList = createAction(
  'fetchingAppointmentList',
  props<{ loading: boolean }>()
);

// export const storeFetchedAppointments = createAction('StoreFetchedAppointments', props<{appointments}>);

export type AppointmentStateAction = {
  action:
    | 'booking'
    | 'fetching'
    | 'bookingSuccess'
    | 'bookingFailed'
    | 'fetchingSuccess'
    | 'fetchingFailed'
    | 'default';
};
