import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AntenatalService, AppointmentService } from 'proxy-sdk';
import { catchError, map, of, switchMap } from 'rxjs';
import { capitalizeFirstLetter } from 'src/app/functions';
import { AntenatalEnrollmentService } from 'src/app/services/antenatal-enrollment.service';
import { NewAuthService } from 'src/app/services/new-auth.service';
// import { AuthService } from "src/app/services/auth.service";
import {
  clearAntenatalDiagnosis,
  clearAntenatalDiagnosisByKey,
  clearWeeklyChecklist,
  fetchAnsweredWeeklyChecklist,
  fetchAntenatalDiagnosis,
  fetchAntenatalDiagnosisByKey,
  fetchedAnsweredWeeklyChecklist,
  fetchedAntenatalDiagnosis,
  fetchedAntenatalDiagnosisByKey,
  fetchedWeeklyChecklist,
  fetchWeeklyChecklist,
  loadingDiagnosisDataStatus,
  loadingWeeklyDataStatus,
  submitAnsweredWeeklyChecklist,
} from './weekly-checklist.actions';

export const DEFAULT_WEEKLY_CHECKLIST_KEY_PREFIX = 'antenatal-week-';
@Injectable()
export class WeeklyChecklistEffect {
  constructor(
    private actions$: Actions,
    private appointmentService: AppointmentService,
    private authService: NewAuthService,
    private antenatalService: AntenatalService,
    private antenatalEnrollmentService: AntenatalEnrollmentService,
    private store: Store
  ) {}

  fetchWeeklyChecklistService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchWeeklyChecklist),
      switchMap((d) => {
        this.store.dispatch(loadingWeeklyDataStatus({ loading: true }));
        return this.appointmentService
          .appointmentControllerFetchComplaintsByKeys(
            `${DEFAULT_WEEKLY_CHECKLIST_KEY_PREFIX}${d.week}`,
            capitalizeFirstLetter(this.authService.patient$.value?.sex || ''),
            this.authService.patient$.value?.date_of_birth
          )
          .pipe(
            map((data: any) => {
              this.store.dispatch(loadingWeeklyDataStatus({ loading: false }));
              return fetchedWeeklyChecklist(data[0]);
            }),
            catchError((error) => {
              this.store.dispatch(loadingWeeklyDataStatus({ loading: false }));
              return of(clearWeeklyChecklist());
            })
          );
      })
    );
  });

  fetchAnsweredWeeklyChecklistService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAnsweredWeeklyChecklist),
      switchMap(() => {
        this.store.dispatch(loadingDiagnosisDataStatus({ loading: true }));
        return this.antenatalService
          .antenatalControllerGetQuestionAnswersByEnrollmentId(
            this.antenatalEnrollmentService.antenatal?._id!,
            this.antenatalEnrollmentService.weeks,
            undefined,
            undefined,
            0,
            100
          )
          .pipe(
            map((data) => {
              if (data.length > 0) {
                this.store.dispatch(
                  fetchAntenatalDiagnosis({
                    qa: data.map((val) => `${val.questionKey}${val.answerKey}`),
                    cqa: data.map(
                      (val) =>
                        `${DEFAULT_WEEKLY_CHECKLIST_KEY_PREFIX}${this.antenatalEnrollmentService.weeks}${val.questionKey}${val.answerKey}`
                    ),
                    precision: 59,
                    sex: 'Female',
                  })
                );
              } else {
                this.store.dispatch(
                  loadingDiagnosisDataStatus({ loading: false })
                );
              }
              return fetchedAnsweredWeeklyChecklist({ qa: data });
            }),
            catchError((error) => {
              this.store.dispatch(
                loadingDiagnosisDataStatus({ loading: false })
              );
              return of(fetchedAnsweredWeeklyChecklist({ qa: [] }));
            })
          );
      })
    );
  });

  submitAnsweredWeeklyChecklistService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(submitAnsweredWeeklyChecklist),
      switchMap((d) => {
        this.store.dispatch(loadingDiagnosisDataStatus({ loading: true }));
        return this.antenatalService
          .antenatalControllerSubmitQuestionWithAnswer(d.qa)
          .pipe(
            map((data: any) => {
              this.store.dispatch(
                loadingDiagnosisDataStatus({ loading: false })
              );
              this.store.dispatch(fetchAnsweredWeeklyChecklist());
              return fetchedAnsweredWeeklyChecklist({ qa: data });
            }),
            catchError((error) => {
              this.store.dispatch(
                loadingDiagnosisDataStatus({ loading: false })
              );
              return of(fetchedAnsweredWeeklyChecklist({ qa: [] }));
            })
          );
      })
    );
  });

  runDiagnosisService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAntenatalDiagnosis),
      switchMap((d) => {
        this.store.dispatch(loadingDiagnosisDataStatus({ loading: true }));
        return this.appointmentService
          .appointmentControllerRunDiagnosis(d)
          .pipe(
            map((data: any) => {
              this.store.dispatch(
                loadingDiagnosisDataStatus({ loading: false })
              );
              return fetchedAntenatalDiagnosis(data.result[0]);
            }),
            catchError((error) => {
              this.store.dispatch(
                loadingDiagnosisDataStatus({ loading: false })
              );
              return of(clearAntenatalDiagnosis());
            })
          );
      })
    )
  );

  fetchDiagnosisService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAntenatalDiagnosisByKey),
      switchMap((d) => {
        this.store.dispatch(loadingDiagnosisDataStatus({ loading: true }));
        return this.appointmentService
          .appointmentControllerGetDiagnosisByKey([d.key])
          .pipe(
            map((data: any) => {
              this.store.dispatch(
                loadingDiagnosisDataStatus({ loading: false })
              );
              return fetchedAntenatalDiagnosisByKey(data[0]);
            }),
            catchError((error) => {
              this.store.dispatch(
                loadingDiagnosisDataStatus({ loading: false })
              );
              return of(clearAntenatalDiagnosisByKey());
            })
          );
      })
    )
  );
}
