/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CopyHealthSubscription } from './copyHealthSubscription';


export interface CopyHealthFetchHealthSubscriptionsResponseDto { 
    data?: Array<CopyHealthSubscription>;
    status?: object;
    message: CopyHealthFetchHealthSubscriptionsResponseDto.MessageEnum;
    /**
     * An Array of string values for fields names to be populated
     */
    error?: CopyHealthFetchHealthSubscriptionsResponseDto.ErrorEnum;
}
export namespace CopyHealthFetchHealthSubscriptionsResponseDto {
    export type MessageEnum = 'REQUEST SUCCESSFUL' | 'REQUEST FAILED' | 'REQUEST IN PROGRESS';
    export const MessageEnum = {
        Successful: 'REQUEST SUCCESSFUL' as MessageEnum,
        Failed: 'REQUEST FAILED' as MessageEnum,
        InProgress: 'REQUEST IN PROGRESS' as MessageEnum
    };
    export type ErrorEnum = 'INVALID_REQUEST' | 'INTERNAL_SERVER_ERROR' | 'INVALID_PARAMETER' | 'ACCESS_DENIED' | 'UNAUTHORIZED';
    export const ErrorEnum = {
        InvalidRequest: 'INVALID_REQUEST' as ErrorEnum,
        InternalServerError: 'INTERNAL_SERVER_ERROR' as ErrorEnum,
        InvalidParameter: 'INVALID_PARAMETER' as ErrorEnum,
        AccessDenied: 'ACCESS_DENIED' as ErrorEnum,
        Unauthorized: 'UNAUTHORIZED' as ErrorEnum
    };
}


