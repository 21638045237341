/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RatingOption } from './ratingOption';


export interface CreateRatingDto { 
    staff_name: string;
    staff_id: string;
    message: RatingOption;
    comment: string;
    hospital_number: string;
    appointment_id: string;
}

