/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface QueueEntry { 
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    queue_patient: object;
    queue: object;
    queue_position: number;
    time_in: string;
    time_out?: string;
    status: QueueEntry.StatusEnum;
    note?: string;
    priority?: number;
}
export namespace QueueEntry {
    export type StatusEnum = 'IN' | 'OUT' | 'CANCELLED';
    export const StatusEnum = {
        In: 'IN' as StatusEnum,
        Out: 'OUT' as StatusEnum,
        Cancelled: 'CANCELLED' as StatusEnum
    };
}


