/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateAppointmentData { 
    department_name: string;
    department_route: string;
    type_of_consultation: string;
    sex: string;
    comment: string;
    hospital_branch?: string;
    patient_first_name: string;
    patient_last_name: string;
    patient_id: string;
    hospital_number: string;
    date_of_appointment?: string;
    service_key: string;
    cost: number;
}

