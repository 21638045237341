import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  template: `
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex>
        <mat-icon *ngFor="let _ of looper(total); let i = index" style="color: {{star_color(rating)}};" (click)="select(i)" [style.cursor]="editable ? 'pointer' : 'default'" >
          {{ i >= this.floor(rating) ? "star_outlined" : "star"}}
        </mat-icon>
      </div>
      <div fxFlex="nogrow" *ngIf="editable" >
        <mat-icon  (click)="select(-1)" color="warn" style="cursor: pointer;" >
          delete
        </mat-icon>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class StarRatingComponent implements OnInit {

  @Input() total: number = 5;
  @Input() rating: number = 3;

  @Input() editable: boolean = false;

  @Input() goodRating: number = 4;
  @Input() averageRating: number = 3;

  @Output() changed: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  looper(count: number): any[] {
    if (count > 0)
      return new Array(count);
    return [];
  }


  star_color(rating: number): string {
    if (this.floor(rating) >= this.goodRating) {
      return "#08D271";
    } else if (this.floor(rating) >= this.averageRating) {
      return "#FDE030";
    }
    return "#EB5757";
  }

  select(value: number) {
    if (this.editable) {
      this.rating = value + 1;
      this.changed.emit(this.rating);
    }
  }


  floor(value: any) {
    return Math.floor(value);
  }

}
