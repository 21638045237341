/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentDtoPayer } from './paymentDtoPayer';
import { Wallet } from './wallet';
import { Bill } from './bill';


export interface PaymentDto { 
    bills: Array<Bill>;
    payer: PaymentDtoPayer;
    wallet: Wallet;
    walletTransactionBeneficiary: PaymentDto.WalletTransactionBeneficiaryEnum;
}
export namespace PaymentDto {
    export type WalletTransactionBeneficiaryEnum = 'SELF' | 'OTHERS';
    export const WalletTransactionBeneficiaryEnum = {
        Self: 'SELF' as WalletTransactionBeneficiaryEnum,
        Others: 'OTHERS' as WalletTransactionBeneficiaryEnum
    };
}


