import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { debounceTime, switchMap, map, of, Observable } from 'rxjs';

@Component({
  selector: 'sal-input',
  templateUrl: './sal-input.component.html',
  styleUrls: ['./sal-input.component.scss']
})
export class SalInputComponent implements OnInit {

  @Input() attributes: SalInputConfig = {
    type: 'text',
    appearance: 'outline',
    formControlName: 'control',
    formGroup: new FormGroup({}),
    format: "yyyy-mm-dd"
  };

  @Input() onChange: Function = (val: any) => {
    console.log('val', val);
    return;
  };

  @Output() emitter: EventEmitter<any> = new EventEmitter();

  maxDate: Date = new Date();

  constructor() {}

  ngOnInit(): void {
    this.attributes.formGroup.controls[
      this.attributes?.formControlName
    ].valueChanges
      .pipe(
        debounceTime(200),
        switchMap((searchString) => {
          return (
            this.attributes.autocomplete?.resultListPipe(searchString).pipe(
              map((val) => {
                this.attributes.autocomplete!.options = val;
                if (!this.attributes.autocomplete?.optionLabel) {
                  this.attributes.autocomplete!.optionLabel = (x) => x;
                }
                if (!this.attributes.autocomplete?.onClick) {
                  this.attributes.autocomplete!.onClick = (x) => {
                    this.attributes.formGroup.patchValue({
                      [this.attributes?.formControlName]: x,
                    });
                  };
                }
              })
            ) || of([])
          );
        })
      )
      .subscribe();
  }

  toggleVisibility($event: MouseEvent) {
    this.attributes.type = this.attributes.type === 'text' ? 'password' : 'text';
  }

  getLengthErrorValue(formControlName: string, errorCode: string) {
    return this.attributes.formGroup.controls[formControlName]?.errors?.[errorCode]?.requiredLength
  }
}

export interface SalInputConfig {
  type: 'text' | 'number' | 'date' | 'email' | 'phone' | 'password';
  appearance: MatFormFieldAppearance;
  formControlName: string;
  formGroup: FormGroup;
  label?: string;
  options?: { title: string; value: string | number; default?: boolean }[];
  multiple?: boolean;
  autocomplete?: {
    resultListPipe: (val: string) => Observable<any[]>;
    optionLabel?: (x: any) => string;
    onClick?: (val: any) => any;
    options?: Array<any>;
  };
  onChange?: Function;
  required?: boolean
  format?:string
}
