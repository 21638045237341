import { createAction, props } from '@ngrx/store';
import { AppointmentDto, PatientData } from 'proxy-sdk';

export const fetchBeneficiaries = createAction(
  'fetchBeneficiaries',
  props<{ maxAge: number }>()
);
export const fetchingBeneficiaries = createAction(
  'fetchingBeneficiaries',
  props<{ loading: boolean }>()
);

export const fetchedBeneficiaries = createAction(
  'fetchedBeneficiaries',
  props<{ beneficiaries: PatientData[] }>()
);

export const fetchBeneficiary = createAction(
  'fetchBeneficiary',
  props<{ hospitalNumber: String }>()
);
export const fetchingBeneficiary = createAction(
  'fetchingBeneficiary',
  props<{ loading: boolean }>()
);

export const fetchedBeneficiary = createAction(
  'fetchedBeneficiary',
  props<{ beneficiary: PatientData }>()
);

export const fetchBeneficiaryAppointments = createAction(
  'fetchBeneficiaryAppointments',
  props<{ hospitalNumber: string }>()
);

export const fetchedBeneficiaryAppointments = createAction(
  'fetchedBeneficiaryAppointments',
  props<{ appointments: AppointmentDto[] }>()
);
export const fetchingBeneficiaryAppointments = createAction(
  'fetchingBeneficiaryAppointments',
  props<{ loading: boolean }>()
);
