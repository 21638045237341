/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  Do not edit the class manually.  PartialIGroup - a model defined in OpenAPI      id: The id of this PartialIGroup [Optional].     title: The title of this PartialIGroup [Optional].     number_of_persons: The number_of_persons of this PartialIGroup [Optional].     cost: The cost of this PartialIGroup [Optional].     is_default: The is_default of this PartialIGroup [Optional].     is_deleted: The is_deleted of this PartialIGroup [Optional].     created_at: The created_at of this PartialIGroup [Optional].     updated_at: The updated_at of this PartialIGroup [Optional].     error: The error of this PartialIGroup [Optional].     is_public: The is_public of this PartialIGroup [Optional].
 */
export interface PartialIGroup { 
    _id?: string;
    title?: string;
    numberOfPersons?: number;
    cost?: number;
    isDefault?: boolean;
    isDeleted?: boolean;
    created_at?: string;
    updated_at?: string;
    error?: string;
    isPublic?: boolean;
}

