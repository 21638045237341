<div style="height: 100vh" fxLayout="column" fxLayoutAlign="center center">
  <img alt="logo" height="150px" src="/assets/images/logo.png" />
  <div class="loadingio-spinner-pulse-qjeg9w1w9tj">
    <div class="ldio-mgvs6e7ph3j">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<style type="text/css">
  @keyframes ldio-mgvs6e7ph3j-1 {
    0% {
      top: 36px;
      height: 128px;
    }
    50% {
      top: 60px;
      height: 80px;
    }
    100% {
      top: 60px;
      height: 80px;
    }
  }
  @keyframes ldio-mgvs6e7ph3j-2 {
    0% {
      top: 41.99999999999999px;
      height: 116.00000000000001px;
    }
    50% {
      top: 60px;
      height: 80px;
    }
    100% {
      top: 60px;
      height: 80px;
    }
  }
  @keyframes ldio-mgvs6e7ph3j-3 {
    0% {
      top: 48px;
      height: 104px;
    }
    50% {
      top: 60px;
      height: 80px;
    }
    100% {
      top: 60px;
      height: 80px;
    }
  }
  .ldio-mgvs6e7ph3j div {
    position: absolute;
    width: 30px;
  }
  .ldio-mgvs6e7ph3j div:nth-child(1) {
    left: 35px;
    background: #1e88e5;
    animation: ldio-mgvs6e7ph3j-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.2s;
  }
  .ldio-mgvs6e7ph3j div:nth-child(2) {
    left: 85px;
    background: #53a4ea;
    animation: ldio-mgvs6e7ph3j-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.1s;
  }
  .ldio-mgvs6e7ph3j div:nth-child(3) {
    left: 135px;
    background: #aaceee;
    animation: ldio-mgvs6e7ph3j-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: undefineds;
  }

  .loadingio-spinner-pulse-qjeg9w1w9tj {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
  }
  .ldio-mgvs6e7ph3j {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-mgvs6e7ph3j div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */
</style>
