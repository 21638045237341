import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { VisitorRequestService } from 'proxy-sdk';
import { catchError, map, of, switchMap } from 'rxjs';
// import { AuthService } from 'src/app/services/auth.service';
import {
  fetchedVisitors,
  fetchVisitor,
  fetchVisitors,
  generateVisitorCode,
  generatingVisitorCodeStatus,
  loadingVisitors,
  searchVisitors,
  singleVisitor,
  totalVisitors,
} from './visitors.actions';

@Injectable()
export class VisitorsEffect {
  constructor(
    private action$: Actions,
    private store: Store,
    private visitorRequestService: VisitorRequestService
  ) {}

  fetchVisitorService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fetchVisitor),
      switchMap((props) => {
        this.store.dispatch(loadingVisitors({ loading: true }));
        return this.visitorRequestService
          .visitorRequestControllerFetchVisitorRequest(
            { _id: props._id } as any,
            undefined,
            undefined,
            0,
            1
          )
          .pipe(
            map((data) => {
              this.store.dispatch(loadingVisitors({ loading: false }));
              return singleVisitor({ visitor: data.data[0] });
            }),
            catchError((error) => {
              this.store.dispatch(loadingVisitors({ loading: false }));
              return of(singleVisitor({ visitor: {} as any }));
            })
          );
      })
    );
  });

  fetchVisitorsService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fetchVisitors),
      switchMap((props) => {
        this.store.dispatch(loadingVisitors({ loading: true }));
        return this.visitorRequestService
          .visitorRequestControllerFetchVisitorRequest(
            {} as any,
            undefined,
            '-created_at',
            props.page,
            props.limit
          )
          .pipe(
            map((data) => {
              this.store.dispatch(loadingVisitors({ loading: false }));
              this.store.dispatch(totalVisitors({ total: data.count }));
              return fetchedVisitors({ visitors: data.data ?? [] });
            }),
            catchError((error) => {
              this.store.dispatch(totalVisitors({ total: 0 }));
              this.store.dispatch(loadingVisitors({ loading: false }));
              return of(fetchedVisitors({ visitors: [] }));
            })
          );
      })
    );
  });

  searchVisitorsService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(searchVisitors),
      switchMap((props) => {
        this.store.dispatch(loadingVisitors({ loading: true }));
        return this.visitorRequestService
          .visitorRequestControllerSearchVisitorRequest(
            props.searchString,
            {} as any,
            undefined,
            '-created_at',
            props.page,
            props.limit
          )
          .pipe(
            map((data) => {
              this.store.dispatch(totalVisitors({ total: data.count }));
              this.store.dispatch(loadingVisitors({ loading: false }));
              return fetchedVisitors({ visitors: data.data ?? [] });
            }),
            catchError((error) => {
              this.store.dispatch(totalVisitors({ total: 0 }));
              this.store.dispatch(loadingVisitors({ loading: false }));
              return of(fetchedVisitors({ visitors: [] }));
            })
          );
      })
    );
  });

  generateVisitorCodeService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(generateVisitorCode),
      switchMap((data) => {
        this.store.dispatch(generatingVisitorCodeStatus({ loading: true }));
        return this.visitorRequestService
          .visitorRequestControllerCreateVisitorRequestWithCode(data.visitor)
          .pipe(
            map((data) => {
              this.store.dispatch(
                generatingVisitorCodeStatus({ loading: false })
              );
              return singleVisitor({ visitor: data as any });
            }),
            catchError((error) => {
              this.store.dispatch(
                generatingVisitorCodeStatus({ loading: false })
              );
              return of(singleVisitor({ visitor: {} as any }));
            })
          );
      })
    );
  });
}
