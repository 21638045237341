import { createReducer, on } from "@ngrx/store";
import { AdmissionData } from "proxy-sdk";
import { fetchedAdmissionInfo } from "./admission.actions";

const admissionInfoReducer = createReducer(
  {},
  on(fetchedAdmissionInfo, (state, props) => props.admission)
);

export const admissionReducers = {
  admissionInfo: admissionInfoReducer
};

export interface AdmissionReducers {
  admissionInfo: AdmissionData;
}