/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Menu } from './menu';


export interface FoodCategory { 
    id: number;
    name: string;
    description?: string;
    menus: Array<Menu>;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

