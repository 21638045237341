/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FoodCategory } from './foodCategory';
import { MenuStatus } from './menuStatus';
import { MenuImage } from './menuImage';
import { TimeGroup } from './timeGroup';
import { TimeTable } from './timeTable';


export interface Menu { 
    id: number;
    name: string;
    price?: number;
    is_available?: boolean;
    description?: string;
    category?: object;
    foodCategory: FoodCategory;
    foodCategoryId?: number;
    timeGroup: TimeGroup;
    timeGroupId?: number;
    images: Array<MenuImage>;
    menu_order_details: Array<MenuStatus>;
    timeTables: Array<TimeTable>;
    average_rating?: number;
    slug?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

