import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, Inject, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[appSp02]',
})
export class Sp02Directive {
  @Input('normal') normal = 0;
  @Input('lowSp02') lowSp02 = 0;
  @Input('displayDefaultText') displayDefaultText = true;
  @Input('customText') customText = '';
  @Input('showIcon') showIcon = false;

  iconContainer: ComponentRef<MatIcon> = this.viewContainer.createComponent<MatIcon>(MatIcon);
  textSpan: HTMLElement = this.document.createElement('b');

  constructor(
    private eleRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: Document,
    private viewContainer: ViewContainerRef
  ) {

  }

  ngAfterViewInit(): void {
    
    this.eleRef.nativeElement.appendChild(this.iconContainer.location.nativeElement);
    this.eleRef.nativeElement.appendChild(this.textSpan);
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
    // this.eleRef.nativeElement.style.color = this.returnResponse().color
  }

  ngOnChanges(): void {
    this.iconContainer.instance._elementRef.nativeElement.innerHTML = this.returnResponse().icon!;
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
    this.eleRef.nativeElement.style.color = this.returnResponse().color;
  }

  returnResponse() {
    if (!this.normal || !this.lowSp02)
      return {
        text: this.displayDefaultText ? '' : this.customText,
        color: '',
        icon: ''
      };
    switch (true) {
      case this.lowSp02 < 90:
        return {
          text: this.displayDefaultText
            ? 'Low Sp02 for adult'
            : this.customText,
          color: 'red',
          icon: 'arrow_downward'
        };
      case this.normal >= 95 && this.normal <= 100:
        return {
          text: this.displayDefaultText ? 'Normal for adult' : this.customText,
          color: 'green',
          icon: 'arrow_upward'
        };
      default:
        return {
          text: this.displayDefaultText ? '' : this.customText,
          color: '',
          icon: ''
        };
    }
  }
}
