import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { PatientService } from "proxy-sdk";
import { catchError, map, of, switchMap } from "rxjs";
import { fetchAdmissionInfo, fetchedAdmissionInfo } from "./admission.actions";

@Injectable()
export class AdmissionEffect {

  constructor(
    private actions$: Actions,
    private patientService: PatientService
  ) {}

  fetchAdmissionInfoService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAdmissionInfo),
      switchMap(() => this.patientService.patientControllerGetPatientAdmissionInfo().pipe(
        map((data) => fetchedAdmissionInfo({ admission: data[0] })),
        catchError((error) => {
          return of(fetchedAdmissionInfo({ admission: {} as any }));
        })
      ))
    );
  });

}
