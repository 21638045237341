/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MenuStatus } from './menuStatus';


export interface Order { 
    id: number;
    ordered_by: string;
    ordered_by_name: string;
    room_number?: string;
    hospital_number?: string;
    hospital_id?: string;
    status?: object;
    accepted_by?: string;
    delivered_by?: string;
    notes?: string;
    is_reservation: boolean;
    reservation_date?: string;
    time_completed?: string;
    total_cost: number;
    amount_paid?: number;
    discount?: number;
    rating?: number;
    payment_status?: object;
    order_details: Array<MenuStatus>;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

