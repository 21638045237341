/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateTimeGroupDto { 
    name: string;
    description?: string;
    /**
     * start_hour and start_min will be extracted from this
     */
    start_time: string;
    /**
     * end_hour and end_min will be extracted from this
     */
    end_time: string;
    /**
     * An array of numbers between 0 and 6 inclusive that specifies days this timegroup is valid. E.g. An array of [0, 2, 3] means this time group is available on Sunday (0), Tuesday (2) and Wednesday (3)
     */
    days_available: Array<number>;
    /**
     * A number that specifies how important this timegroup is. The higher the number, the higher the priority
     */
    priority?: number;
    status?: CreateTimeGroupDto.StatusEnum;
}
export namespace CreateTimeGroupDto {
    export type StatusEnum = 'active' | 'inactive';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Inactive: 'inactive' as StatusEnum
    };
}


