/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdmissionDataBedRooms } from './admissionDataBedRooms';


export interface AdmissionDataBed { 
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    bedName?: string;
    cost?: number;
    bedType?: string;
    description?: string;
    isOccupied?: boolean;
    roomId?: number;
    rooms?: AdmissionDataBedRooms;
}

