/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CopyHealthSubscription { 
    title: string;
    alt_title: string;
    key: string;
    health_subscription_items: Array<object>;
    health_subscription_groups: Array<object>;
    items_order: Array<number>;
    duration: number;
    slug: string;
    id: number;
    release_state: object;
    released_by: string;
    release_audience: object;
    created: string;
    updated: string;
    deletedAt?: string;
}

