import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[appImgFallback]'
})
export class ImgFallbackDirective {
  @Input() appImgFallback: string = "avatar";
  constructor(
    private eRef: ElementRef
  ) {}

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = this.eRef.nativeElement as HTMLImageElement;
    let type: string;
    switch (this.appImgFallback) {
      case 'avatar':
        type = 'avatar.png';
        break;
      default:
        type = 'avatar.png';
    }
    element.src = '/assets/images/' + type;
  }

}
