import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppointmentService } from 'proxy-sdk';
import { catchError, finalize, map, of, switchMap, tap } from 'rxjs';
import {
  appointmentActionState,
  bookAppointment,
  fetchAppointmentServices,
  fetchDefaultComplaints,
  fetchAppointmentList,
  storeAppointmentServices,
  storeDefaultComplaints,
  fetchingAppointmentList,
  storeAppointmentList,
} from './appointment.actions';
import * as dayjs from 'dayjs';

@Injectable()
export class AppointmentEffect {
  constructor(
    private actions$: Actions,
    private appointmentService: AppointmentService,
    private snackBar: MatSnackBar,
    private store: Store,
    private router: Router
  ) {}

  fetchAppointmentServices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAppointmentServices),
      switchMap((val) =>
        this.appointmentService
          .appointmentControllerFetchAppointmentServices()
          .pipe(
            map((data) => storeAppointmentServices({ services: data })),
            catchError((error) => {
              setTimeout(() => {
                this.store.dispatch(fetchAppointmentServices());
              }, 10000);
              return of(storeAppointmentServices({ services: [] }));
            })
          )
      )
    );
  });

  fetchDefaultComplaints$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchDefaultComplaints),
      switchMap((val) => {
        return this.appointmentService
          .appointmentControllerFetchMainComplaints(val.sex)
          .pipe(
            map((data) => {
              return storeDefaultComplaints({ complaints: data });
            }),
            catchError((error) => {
              this.store.dispatch(storeDefaultComplaints({ complaints: [] }));
              return of(storeDefaultComplaints({ complaints: [] }));
            })
          );
      })
    );
  });

  bookAppointment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(bookAppointment),
      tap(() => {
        this.store.dispatch(appointmentActionState({ action: 'booking' }));
      }),
      switchMap((val) => {
        this.store.dispatch(appointmentActionState({ action: 'booking' }));
        return this.appointmentService
          .appointmentControllerCreateAppointment(val.appointment)
          .pipe(
            map((res: any) => {
              this.snackBar.open(res.message ?? 'Request Sent');
              // if (val.appointment.isEmergency && res._id) {
              //   Swal.fire({
              //     title: 'Do you want to put a call to the hospital?',
              //     confirmButtonText: 'Yes',
              //     showDenyButton: true,
              //     denyButtonText: 'No',
              //   }).then((ans) => {
              //     if (ans.isConfirmed) {
              //       window.open('tel:+2347044000138', '_self');
              //     }
              //   });
              // }
              return appointmentActionState({ action: 'bookingSuccess' });
            }),
            catchError((error) => {
              if (val.appointment.isEmergency) {
                this.router.navigateByUrl('');
              }
              console.log(error?.message);
              this.snackBar.open(
                error?.message ?? 'Something went wrong. Please try again.'
              );
              return of(appointmentActionState({ action: 'bookingFailed' }));
            })
          );
      })
    );
  });

  fetchAppointmentList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAppointmentList),
      switchMap(() => {
        this.store.dispatch(fetchingAppointmentList({ loading: true }));
        return this.appointmentService
          .appointmentControllerFetchAppointments(
            10,
            0,
            dayjs().startOf('day').toISOString(),
            dayjs().add(6, 'months').endOf('day').toISOString()
          )
          .pipe(
            map((data) => {
              this.store.dispatch(fetchingAppointmentList({ loading: false }));
              return storeAppointmentList({
                appointments: data
                  .filter((val) =>
                    dayjs().startOf('day').isBefore(val.date_of_appointment)
                  )
                  .sort((a, b) =>
                    dayjs(a.date_of_appointment).diff(b.date_of_appointment) > 0
                      ? -1
                      : 1
                  ),
              });
            }),
            catchError((error) => {
              this.store.dispatch(fetchingAppointmentList({ loading: false }));
              return of(storeAppointmentList({ appointments: [] as any }));
            })
          );
      })
    );
  });
}
