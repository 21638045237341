import { createAction, props } from "@ngrx/store";
import { AntenatalQuestionAnswerDto, RunDiagnosisDto } from "proxy-sdk";
import { ComplaintModel } from "src/app/interfaces/qa";
import { RunDiagnosisResult } from "./weekly-checklist.reducers";


export const fetchWeeklyChecklist = createAction(
  'fetchWeeklyChecklist',
  props<{ week: number; }>()
);

export const clearWeeklyChecklist = createAction(
  'clearWeeklyChecklist',
);

export const fetchedWeeklyChecklist = createAction(
  'fetchedWeeklyChecklist',
  props<ComplaintModel>()
);

export const fetchAnsweredWeeklyChecklist = createAction(
  'fetchAnsweredWeeklyChecklist',
);

export const submitAnsweredWeeklyChecklist = createAction(
  'submitAnsweredWeeklyChecklist',
  props<{ qa: AntenatalQuestionAnswerDto[]; }>()
);

export const fetchedAnsweredWeeklyChecklist = createAction(
  'fetchedAnsweredWeeklyChecklist',
  props<{ qa: AntenatalQuestionAnswerDto[]; }>()
);

export const fetchAntenatalDiagnosis = createAction(
  'fetchAntenatalDiagnosis',
  props<RunDiagnosisDto>()
);

export const fetchedAntenatalDiagnosis = createAction(
  'fetchedAntenatalDiagnosis',
  props<RunDiagnosisResult>()
);

export const clearAntenatalDiagnosis = createAction(
  'clearAntenatalDiagnosis',
);

export const fetchAntenatalDiagnosisByKey = createAction(
  'fetchAntenatalDiagnosisByKey',
  props<{ key: string; }>()
);

export const fetchedAntenatalDiagnosisByKey = createAction(
  'fetchedAntenatalDiagnosisByKey',
  props<any>()
);

export const clearAntenatalDiagnosisByKey = createAction(
  'clearAntenatalDiagnosisByKey',
);

export const loadingWeeklyDataStatus = createAction(
  'loadingWeeklyDataStatus',
  props<{ loading: boolean; }>()
);

export const loadingDiagnosisDataStatus = createAction(
  'loadingDiagnosisDataStatus',
  props<{ loading: boolean; }>()
);
