import { createAction, props } from '@ngrx/store';
import { PartialIGroup, PartialIPatient } from 'kiosk-service-sdk';


export const runRegistration = createAction(
  'runRegistration',
  props<{ patientData: PartialIPatient, packageId: string }>()
);

export const registrationSuccessful = createAction(
  'registrationSuccessful',
  props<{ patientData: PartialIPatient }>()
);

export const registrationFailed = createAction(
  'registrationFailed',
  props<{ patientData: PartialIPatient }>()
);

export const restartRegistration = createAction('resetRegistration');

export const fetchRegistrationPackages = createAction(
  'fetchRegistrationPackages'
);

export const storeRegistrationPackages = createAction(
  'storeRegistrationPackages',
  props<{ packages: PartialIGroup[] }>()
);

export const processingRegistration = createAction(
  'processingRegistration',
  props<{ loading: boolean }>()
);
