<body lang=EN-PH link="#0563C1" vlink="#954F72" style='tab-interval:36.0pt'>

    <div class=WordSection1>

        <p class=MsoNormal align=right style='text-align:right;line-height:120%;
background:white'><span lang="" style='font-size:12.0pt;line-height:120%;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-color-alt:windowtext'>Last updated: January 1, 2022</span><span lang="" style='font-size:12.0pt;line-height:120%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman"'></span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Please
read this End User License Agreement carefully before clicking the Agree
button, downloading or using MyHomeClinic app.</span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>By
clicking the I Agree button, downloading or using the Application, you are
agreeing to be bound by the terms and conditions of this Agreement. If you do
not agree too the terms of this Agreement, do not click on the I Agree button
and do not download or use the Application. </span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>SavealifeMedisoft,
grants you a revocable, non-Dexclusive, non- transferable, limited license to
download, install and use the Application solely for your personal, non-commercial
purposes strictly in accordance with the terms of this Agreement.</span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b style='mso-bidi-font-weight:normal'><span lang="" style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif'>Restrictions </span></b></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>By
using our Services, you agree on behalf of yourselt, your users and your
attendees, not to (modify, prepare derivative works of, or reverse engineer,
our Services; (i) knowingly or negligently use Our Services in a way that abuses
or disrupts our networks, user accounts, or the Services; (ii) transmit through
the Services any harassing, indecent, obscene, fraudulent, or unlawful
material; (iv) market, or resell the Services to any third party; (v) use the
Services in violation of applicable laws, or regulations; (vi) use the Services
to send unauthorized advertising, or spam; (vii) harvest, collect, or gather
user data without their consent; or (vii) transmit through the Services any
material that may infringe the intellectual property, Privacy, or other rights
of third parties. </span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b style='mso-bidi-font-weight:normal'><span lang="" style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif'>Modifications to Application </span></b>
        </p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>SavealifeMedisoft
reserves the right to modify, suspend or discontinue, temporarily or
permanently, the Application or any service to which it connects, with or
without notice and without liability to you. The Modifications to Application
section is for apps that will be updated or regularly maintained.</span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b style='mso-bidi-font-weight:normal'><span lang="" style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif'>Proprietary Rights and
MyHomeClinic. </span></b></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>You
acknowledge that we or our licensors retain all proprietary right, title and
interest in the Services, our name, logo (MyHomeClinic), and any related
intellectual property rights, including, without limitation, all modifications,
enhancements, derivative works, and upgrades thereto. You agree that you will
not use or register any trademark, service mark, business name, domain name or
social media account name or handle which incorporates in whole or in part the
MyHomeClinic app or is similar to any of these. You agree to comply with our
Branding Guidelines, available on request, which are incorporated into this
Agreement by reference.</span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b style='mso-bidi-font-weight:normal'><span lang="" style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif'>Fees and Payment. </span></b></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>You
agree to pay all applicable, undisputed fees for the Services on the terms set
forth on the invoice. Any and all payments you make to us for access to the
Services are final and non-refundable. You are responsible for all fees and charges
imposed by your data transmission providers related to your access and use of
the Services. You are responsible for providing accurate and current billing.
contact and payment information to us or any third party. You agree that we may
take steps to verify whether your payment method is valid, charge your payment
card or bill you for all amounts due for your use of the Services, and
automatically update your payment card information using software designed to
do so in the event your payment card on file is no longer valid. You agree that
your credit card information and related personal data may be provided to third
parties for payment processing and fraud prevention purposes. We may Suspend or
terminate your Services if at any time we determine that your payment information
is inaccurate or not current, and you are responsible for fees and overdraft
charges that we may incur when we charge your card for payment. We will not
agree to submit invoices via any customer procure-to-pay online portal or
Electronic Data Interchange (EDI) portals. We reserve the right to update the
price for Services at any time after your Initial Term, and price changes will
be effective as of your next billing cycle. We will notify you of any price
changes by publishing on our website, app, emailing, quoting or invoicing you.</span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b style='mso-bidi-font-weight:normal'><span lang="" style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif'>Term and Conditions</span></b></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>This
Agreement shall remain in effect until terminated by SavealifeMedisoft or you. SavealifeMedisoft
may, in its sole discretion, at any time and for any or no reason, suspend or
terminate this Agreement with or without prior notice. This Agreement will
terminate immediately, without prior notice from SavealifeMedisoft in the event
that you fail to comply with any provision of this Agreement. You may also
terminate this Agreement by deleting the Application and all copies thereof
from your mobile device or from your desktop. Upon termination of this
Agreement, you shall cease all use of the Application and delete all copies of
the Application from your mobile device or from your desktop. Severability If
any provision of this Agreement is held to be unenforceable or Agreement is
held to be unenforceable or invalid, such provision will be changed and
interpreted to accomplish the objectives of such provision to the greatest
extent possible under applicable law and the remaining provisions will continue
in full force and effect. </span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><span
            lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Legal
Liability Limitation</span></b></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>SavealifeMedisoft
is absolved from all liabilities from any prescriptions based on the answers
supplied by the user. This includes but not limited to the side effects of
drugs or any health danger from the drugs as the user is expected to read
carefully the details of the drug information contained in the drugs leaflets
or booklets. Similarly, the App is not a substitute or replacement to the
services of the user's Doctor and need to visit the <span style='mso-bidi-font-weight:
bold'>hospital.</span><b style='mso-bidi-font-weight:normal'></b></span>
        </p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>You
agree that SavealifeMedisoft shall, in no event, be liable for any consequential,
incidental, indirect, special, punitive, or other loss or damage whatsoever or
for loss of business profits, business interruption, computer failure, loss of
business information, or other loss arising out of or caused by your use of or
inability to use the service, even if SavealifeMedisoft has been advised of the
possibility of such damage. in no event shall SavealifeMedisoft's entire
liability to you in respect of any service, whether direct or indirect, exceed
the fees paid by you towards such service.</span></p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b style='mso-bidi-font-weight:normal'><span lang="" style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif'>Amendments to this Agreement </span></b>
        </p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>SavealifeMedisoft
reserves the right, at its sole discretion, to modify or replace this Agreement
at any time. If a revision to this material is available, we will provide
without any prior notice. What constitutes a material change will be determined
at our sole discretion. Contact Information If you have any queries regarding
any of our terms, please contact us by e-mail: <a
                href="mailto:developers@drsavealife.com">{{"developers@drsavealife.com"}}</a></span>
        </p>

        <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span lang="" style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'></span>
        </p>

    </div>

</body>
