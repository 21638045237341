/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CopySurveySurveyRecordFilterProps { 
    /**
     * A String value
     */
    assigned_to_id?: string;
    /**
     * A String value
     */
    completed_by_id?: string;
    /**
     * A String value
     */
    dob?: string;
    /**
     * A String value
     */
    id?: number;
    /**
     * A String value
     */
    last_answered_question_id?: number;
    /**
     * A String value
     */
    requested_by_id?: string;
    /**
     * A ENUM value of general sex [MALE< FEMALE]
     */
    sex?: CopySurveySurveyRecordFilterProps.SexEnum;
    /**
     * A Enum value of SurveySurveyRecordStatus
     */
    status?: CopySurveySurveyRecordFilterProps.StatusEnum;
    /**
     * A String value
     */
    survey_id?: string;
    /**
     * A enum value of SurveySurveyType
     */
    survey_type?: CopySurveySurveyRecordFilterProps.SurveyTypeEnum;
    /**
     * A String value
     */
    user_id?: string;
    /**
     * A String value
     */
    user_identity?: string;
    /**
     * A String value
     */
    user_name?: string;
    /**
     * A enum value of GeneralUserType
     */
    user_type?: CopySurveySurveyRecordFilterProps.UserTypeEnum;
    /**
     * A String value
     */
    uuid?: string;
}
export namespace CopySurveySurveyRecordFilterProps {
    export type SexEnum = 'FEMALE' | 'MALE';
    export const SexEnum = {
        Female: 'FEMALE' as SexEnum,
        Male: 'MALE' as SexEnum
    };
    export type StatusEnum = 'PENDING' | 'IN_PROGRESS' | 'SUBMITTED' | 'APPROVED' | 'REJECTED' | 'CANCELLED' | 'COMPLETED' | 'DELETED';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Submitted: 'SUBMITTED' as StatusEnum,
        Approved: 'APPROVED' as StatusEnum,
        Rejected: 'REJECTED' as StatusEnum,
        Cancelled: 'CANCELLED' as StatusEnum,
        Completed: 'COMPLETED' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type SurveyTypeEnum = 'HEALTH_CHECK' | 'WEEKLY_ANTENATAL' | 'VITALS' | 'WARD_ROUND' | 'HAND_OVER_NOTE' | 'BABY_GROWTH_MONITOR';
    export const SurveyTypeEnum = {
        HealthCheck: 'HEALTH_CHECK' as SurveyTypeEnum,
        WeeklyAntenatal: 'WEEKLY_ANTENATAL' as SurveyTypeEnum,
        Vitals: 'VITALS' as SurveyTypeEnum,
        WardRound: 'WARD_ROUND' as SurveyTypeEnum,
        HandOverNote: 'HAND_OVER_NOTE' as SurveyTypeEnum,
        BabyGrowthMonitor: 'BABY_GROWTH_MONITOR' as SurveyTypeEnum
    };
    export type UserTypeEnum = 'STAFF' | 'PATIENT';
    export const UserTypeEnum = {
        Staff: 'STAFF' as UserTypeEnum,
        Patient: 'PATIENT' as UserTypeEnum
    };
}


