import { createReducer, on } from '@ngrx/store';
import { FetchBillsResponse, InitPaymentResponse, Wallet } from 'proxy-sdk';
import {
  fetchingBills,
  fetchingWallets,
  storeBeneficiaryBills,
  storeBills,
  storeInitPaymentResp,
  storeWallets,
} from './bill.actions';

const fetchedBills = createReducer(
  [] as FetchBillsResponse[],
  on(storeBills, (state, props) => {
    return props.groupedBills.map((val) =>
      !val.category ? { ...val, category: 'UNCATEGORIZED' } : val
    );
  })
);

const fetchedBeneficiaryBills = createReducer(
  [] as FetchBillsResponse[],
  on(storeBeneficiaryBills, (state, props) => {
    return props.groupedBills.map((val) =>
      !val.category ? { ...val, category: 'UNCATEGORIZED' } : val
    );
  })
);

const initPaymentResponse = createReducer(
  {} as InitPaymentResponse,
  on(storeInitPaymentResp, (state, props) => {
    return props.paymentInfo;
  })
);

const fetchingBillsState = createReducer(
  { status: false },
  on(fetchingBills, (state, props) => {
    return props;
  })
);

const fetchedWallets = createReducer(
  [] as Wallet[],
  on(storeWallets, (state, props) => {
    console.log("WALLETS:", props)
    return props.wallets;
  })
);

const fetchingWalletsState = createReducer(
  { status: false },
  on(fetchingWallets, (state, props) => {
    return props;
  })
);

export const billReducers = {
  fetchedBills,
  fetchedBeneficiaryBills,
  initPaymentResponse,
  fetchingBillsState,
  fetchedWallets,
  fetchingWalletsState,
};

export interface IBillReducerData {
  fetchedBills: FetchBillsResponse[];
  fetchedBeneficiaryBills: FetchBillsResponse[];
  initPaymentResponse: InitPaymentResponse;
  fetchingBillsState: { status: boolean };
  fetchedWallets: Wallet[];
  fetchingWalletsState: { status: boolean };
}
