/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PartialApproval { 
    id?: number;
    uuid?: string;
    email?: string;
    hospital_number?: string;
    patient_name?: string;
    firebase_id?: string;
    notification_token?: string;
    status?: PartialApproval.StatusEnum;
    profile_type?: PartialApproval.ProfileTypeEnum;
    approved?: boolean;
    slug?: string;
    blocked?: boolean;
    created?: string;
    updated?: string;
    deletedAt?: string;
}
export namespace PartialApproval {
    export type StatusEnum = 'pending' | 'approved' | 'blocked' | 'cancelled';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Approved: 'approved' as StatusEnum,
        Blocked: 'blocked' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
    export type ProfileTypeEnum = 'PATIENT' | 'STAFF';
    export const ProfileTypeEnum = {
        Patient: 'PATIENT' as ProfileTypeEnum,
        Staff: 'STAFF' as ProfileTypeEnum
    };
}


