import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss'],
})
export class CalenderComponent implements OnInit, AfterContentInit {
  currentDate: Date = new Date();
  today: Date = new Date();
  @Input() selectedDate?: Date;
  @Input() minDate = new Date();
  @Input() showDropdown: boolean = false;
  @Input() maxMonths = 6;

  @Output() dateSelected: EventEmitter<Date> = new EventEmitter();

  daysOfTheWeeks: string[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  constructor() {}
  ngAfterContentInit(): void {
    this.setMonth(
      new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1)
    );
  }

  ngOnInit(): void {
    if (this.selectedDate) {
      this.currentDate = this.selectedDate;
      this.selectDate(this.selectedDate.getDate());
    } else {
      this.currentDate.setDate(this.today.getDate());
    }
  }

  showMonths(): Date[] {
    const months: Date[] = [];
    let startMonth = this.minDate.getMonth();
    let startYear = this.minDate.getFullYear();
    for (let x = 0; x < this.maxMonths; x++) {
      months.push(new Date(startYear, startMonth + x, 1));
    }
    return months;
  }

  getDate(date: number): Date {
    return new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      date
    );
  }

  selectDate(date: number): void {
    this.selectedDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      date
    );
    this.dateSelected.emit(this.selectedDate);
  }

  greaterThanToday(date: number): boolean {
    return dayjs(this.getDate(date)).isAfter(
      dayjs(this.today).subtract(1, 'day').endOf('day')
    );
  }

  isDateEqual(dateA?: Date, dateB?: Date): boolean {
    if (dateA == undefined || dateB == undefined) return false;
    return (
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getDate() === dateB.getDate() &&
      dateA.getFullYear() === dateB.getFullYear()
    );
  }

  getDaysInMonth(): number {
    return new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() + 1,
      0
    ).getDate();
  }

  generateRange(days: number): number[] {
    return new Array(days);
  }

  nextMonth(): void {
    if (this.canGoForward()) {
      this.setMonth(
        new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth() + 1,
          1
        )
      );
    }
    this.dateSelected.emit(this.selectedDate);
  }

  setMonth(date: Date): void {
    this.currentDate = date;
    // this.selectedDate = undefined;
    this.dateSelected.emit(this.selectedDate);
  }

  prevMonth(): void {
    if (this.canGoBack()) {
      this.setMonth(
        new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth() - 1,
          1
        )
      );
    }
  }

  canGoBack(): boolean {
    const presentMonth = new Date(this.minDate);
    return presentMonth.getTime() < this.currentDate.getTime();
  }

  canGoForward(): boolean {
    const presentMonth = new Date(
      this.minDate.getFullYear(),
      this.minDate.getMonth() + this.maxMonths - 1,
      1
    );
    return presentMonth.getTime() > this.currentDate.getTime();
  }
}
