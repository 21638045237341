<div>

  <div class="main-content">
    <div *ngFor="let iconLocation of iconLocations" class="icon" (click)="menuData[iconLocation]?.callback()"
      [style.flex-grow]="menuData[iconLocation]?.isHome ? 1 : 0">
      <img alt="logo" src="/assets/images/logo.png" routerLink="/home" style="height: 50px;"
        *ngIf="menuData[iconLocation]?.isHome else notHome" />
      <ng-template #notHome>
        <mat-icon [matBadge]="menuData[iconLocation]?.count" matBadgePosition="before" matBadgeSize="small"
          [matBadgeHidden]="(menuData[iconLocation]?.count ?? 0) < 1" *ngIf="menuData[iconLocation]?.icon"
          [style.color]="menuData[iconLocation]?.color">{{
          menuData[iconLocation]?.icon
          }}</mat-icon>
        <span class="title" *ngIf="menuData[iconLocation]?.text">{{ menuData[iconLocation]?.text }}</span>
      </ng-template>
    </div>
  </div>
</div>