/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartialApproval } from './partialApproval';


export interface FetchApprovalPayload { 
    filter: PartialApproval;
    searchString: string;
    limit: number;
    page: number;
    orderField: string;
    order: FetchApprovalPayload.OrderEnum;
}
export namespace FetchApprovalPayload {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
}


