/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CopyGeneralErrorMessage { 
    code?: CopyGeneralErrorMessage.CodeEnum;
    message?: string;
}
export namespace CopyGeneralErrorMessage {
    export type CodeEnum = 'INVALID_REQUEST' | 'INTERNAL_SERVER_ERROR' | 'INVALID_PARAMETER' | 'ACCESS_DENIED' | 'UNAUTHORIZED';
    export const CodeEnum = {
        InvalidRequest: 'INVALID_REQUEST' as CodeEnum,
        InternalServerError: 'INTERNAL_SERVER_ERROR' as CodeEnum,
        InvalidParameter: 'INVALID_PARAMETER' as CodeEnum,
        AccessDenied: 'ACCESS_DENIED' as CodeEnum,
        Unauthorized: 'UNAUTHORIZED' as CodeEnum
    };
}


