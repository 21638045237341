/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Order } from './order';
import { Menu } from './menu';


export interface MenuStatus { 
    id: number;
    order: Order;
    orderId: number;
    menu: Menu;
    menuId?: number;
    menuCost?: number;
    quantity?: number;
    status?: object;
    rating?: number;
    updated_by_full_name?: string;
    updated_by_staff_id?: string;
    completed_at?: string;
    comment?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

