/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilteredEmergencyRecord } from './filteredEmergencyRecord';


export interface EmergencyRecordFilter { 
    filter?: FilteredEmergencyRecord;
    /**
     * optional string to be used for search
     */
    searchString?: string;
    /**
     * field to use when searching
     */
    searchField?: string;
    /**
     * Enforce accuracy. Value ranges from 0 - 1
     */
    searchPrecision?: number;
    /**
     * optional numeric value of limit of data to be returned
     */
    limit?: number;
    /**
     * optional numeric value of page of data to be returned
     */
    page?: number;
    /**
     * optional string value of field named 
     */
    orderBy?: string;
    /**
     * optional enum value of order type  eg. ASC | DESC
     */
    order?: EmergencyRecordFilter.OrderEnum;
    /**
     * optional array value of field names to be used for relationship popultations
     */
    relations?: Array<string>;
    startDate?: string;
    endDate?: string;
    dateField?: string;
}
export namespace EmergencyRecordFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
}


