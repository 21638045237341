/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateComplaintSubmissionProgressDto { 
    updated_by_full_name: string;
    updated_by_staff_id: string;
    notes?: string;
    rating?: number;
    complaintSubmissionId: number;
    status?: CreateComplaintSubmissionProgressDto.StatusEnum;
    completed_at?: string;
}
export namespace CreateComplaintSubmissionProgressDto {
    export type StatusEnum = 'pending' | 'inprogress' | 'submitted' | 'resolved' | 'cannotresolve';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Inprogress: 'inprogress' as StatusEnum,
        Submitted: 'submitted' as StatusEnum,
        Resolved: 'resolved' as StatusEnum,
        Cannotresolve: 'cannotresolve' as StatusEnum
    };
}


