/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ComplaintDetail } from './complaintDetail';
import { ComplaintCategory } from './complaintCategory';
import { ComplaintSubmission } from './complaintSubmission';
import { ComplaintType } from './complaintType';


export interface Complaint { 
    id: number;
    unique_key: string;
    type: string;
    comment?: string;
    complaintCategory: ComplaintCategory;
    complaintCategoryId?: number;
    complaintType: ComplaintType;
    complaintTypeId: number;
    priority?: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    complaint_details: Array<ComplaintDetail>;
    complaint_summaries: Array<ComplaintSubmission>;
}

