import { DOCUMENT } from '@angular/common';
import { ComponentRef, Directive, ElementRef, Inject, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[appBmi]'
})
export class BmiDirective {

  @Input('globalBmi') globalBmi = 0
  @Input('weight') weight = 0;
  @Input('height') height = 0;
  @Input('displayDefaultText') displayDefaultText = true;
  @Input('customText') customText = '';
  @Input('showIcon') showIcon = false;

  iconContainer: ComponentRef<MatIcon> = this.viewContainer.createComponent<MatIcon>(MatIcon);
  textSpan: HTMLElement = this.document.createElement('b');


  constructor(
    // private renderer: Renderer2, 
    private eleRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: Document,
    private viewContainer: ViewContainerRef
    ) {
  }

  ngAfterViewInit(): void {
    this.eleRef.nativeElement.appendChild(this.iconContainer.location.nativeElement);
    this.eleRef.nativeElement.appendChild(this.textSpan);
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
  }

  ngOnChanges(): void {
    this.iconContainer.instance._elementRef.nativeElement.innerHTML = this.returnResponse().icon!;
    this.textSpan.innerHTML = `${this.returnResponse().text}`;
    this.eleRef.nativeElement.style.color = this.returnResponse().color;
  }

  returnResponse() {
    if (this.weight > 0 || this.height > 0) {
      this.globalBmi =  Math.round(this.weight / Math.pow((this.height/100), 2)*10)/ 10
    }

    if (!this.weight && !this.height && !this.globalBmi) return { text: this.displayDefaultText ? "" : this.customText, color: "", icon: "" }
    if (this.globalBmi <= 18.5) {
      return { text: this.displayDefaultText ? "Underweight" : this.customText, color: "orange", icon: "arrow_downward" }
    } else if (this.globalBmi > 18.5 && this.globalBmi < 25) {
      return { text: this.displayDefaultText ? "Normal weight" : this.customText, color: "green", icon: "check" }
    } else if (this.globalBmi >= 25.0 && this.globalBmi < 30) {
      return { text: this.displayDefaultText ? "Overweight" : this.customText, color: "red", icon: "arrow_upward" }
    } else if (this.globalBmi >= 30.0 && this.globalBmi < 35) {
      return { text: this.displayDefaultText ?  "Obesity stage 1" : this.customText, color: "red", icon: "arrow_upward" }
    } else if (this.globalBmi >= 35.0 && this.globalBmi < 40) {
      return { text: this.displayDefaultText ?  "Obesity stage 2" : this.customText, color: "red", icons: "arrow_upward" }
    }else if (this.globalBmi > 40) {
      return { text: this.displayDefaultText ? "Obesity stage 3 (Morbid Obesity)" : this.customText, color: "red", icons: "arrow_upward" }
    }else {
      return { text: this.displayDefaultText ?  "" : this.customText, color: "", icons: "" }
    }
  }

}
