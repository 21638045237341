/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CopyQuestionsAndAnswer { 
    answer?: string;
    answer_id?: string;
    answer_type?: object;
    createdAt?: string;
    created_at?: string;
    deletedAt?: object;
    id?: number;
    order_index?: number;
    question: string;
    question_id: string;
    survey_record_id: number;
    update_at?: string;
    updatedAt?: string;
    uuid?: string;
}

