/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CopyFetchHealthSubscriptionDtoFilter } from './copyFetchHealthSubscriptionDtoFilter';


export interface CopyFetchHealthSubscriptionDto { 
    /**
     * This initiates a search when present
     */
    searchString?: string;
    filter: CopyFetchHealthSubscriptionDtoFilter;
    /**
     * A Numeric Value for limit
     */
    limit?: number;
    /**
     * A Numeric value for Page
     */
    page?: number;
    /**
     * An Array of string values for fields names to be populated
     */
    relations?: Array<string>;
}

