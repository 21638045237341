import { createAction, props } from '@ngrx/store';
import { AntenatalEnrollmentDto } from 'proxy-sdk';

export const fetchAntenatalEnrollment = createAction(
  'fetchAntenatalEnrollment'
);

export const fetchedAntenatalEnrollment = createAction(
  'fetchedAntenatalEnrollment',
  props<AntenatalEnrollmentDto>()
);

export const clearAntenatalEnrollment = createAction(
  'clearAntenatalEnrollment'
);