/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CopyGeneralErrorMessage } from './copyGeneralErrorMessage';
import { CopySurveyQuestionAndAnswersWithComments } from './copySurveyQuestionAndAnswersWithComments';


export interface CopySurveyQuestionAndAnswerResponse { 
    message?: CopySurveyQuestionAndAnswerResponse.MessageEnum;
    status?: CopySurveyQuestionAndAnswerResponse.StatusEnum;
    data?: CopySurveyQuestionAndAnswersWithComments;
    error?: CopyGeneralErrorMessage;
}
export namespace CopySurveyQuestionAndAnswerResponse {
    export type MessageEnum = 'REQUEST SUCCESSFUL' | 'REQUEST FAILED' | 'REQUEST IN PROGRESS';
    export const MessageEnum = {
        Successful: 'REQUEST SUCCESSFUL' as MessageEnum,
        Failed: 'REQUEST FAILED' as MessageEnum,
        InProgress: 'REQUEST IN PROGRESS' as MessageEnum
    };
    export type StatusEnum = 'SUCCESS' | 'FAILED';
    export const StatusEnum = {
        Success: 'SUCCESS' as StatusEnum,
        Failed: 'FAILED' as StatusEnum
    };
}


