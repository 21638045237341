/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ImmunizationPatientData { 
    _id?: string;
    patientFirstName?: string;
    patientLastName?: string;
    patientId: object;
    hospitalNumber: string;
    patientDateOfBirth?: string;
    patientAgeInWeeks?: number;
    sex?: object;
    appointmentId?: object;
    immunizationId?: object;
    immunizationUniqueCode?: string;
    immunizationCost?: number;
    dateTaken?: string;
    status?: object;
    paymentStatus?: object;
    doneByStaffId?: string;
    doneByStaffFirstName?: string;
    doneByStaffLastName?: string;
    hasBeenReceived?: boolean;
    immunizationAppointmentId?: object;
    created_at?: string;
    updated_at?: string;
}

