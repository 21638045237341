/**
 * home-clinic-proxy-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.1
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IError } from './iError';
import { IResponse } from './iResponse';


export interface SubmitForApproval200Response { 
    error: boolean;
    code: number;
    message: string;
    result?: object;
    details?: object;
}

