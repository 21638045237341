/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ImmunizationAppointmentData { 
    _id?: string;
    patientFirstName?: string;
    patientLastName?: string;
    sex?: object;
    hospitalNumber: string;
    email?: string;
    phone?: string;
    appointmentDate: string;
    patientId: string;
    patientDateOfBirth?: string;
    patientAgeInWeeks?: number;
    status?: object;
    bookedByStaffId: string;
    bookedByStaffFirstName: string;
    bookedByStaffLastName: string;
    patientHmoCode?: string;
    hmoCode?: string;
    hmoId?: string;
    hmoName?: string;
    created_at?: string;
    updated_at?: string;
}

