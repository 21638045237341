import { Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @ViewChild('mySidenav') sideNav!: ElementRef;

  constructor(
    public navigationService: NavigationService
  ) {}

  ngOnInit(): void {}

  openNav() {
    console.log(this.sideNav);
    this.sideNav.nativeElement.style.width = '100vw';
  }

  closeNav() {
    this.sideNav.nativeElement.style.width = '0';
  }

}
