import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BloodPressureDirective } from './blood-pressure.directive';
import { PulseRateDirective } from './pulse-rate.directive';
import { RespiratoryRateDirective } from './respiratory-rate.directive';
import { BmiDirective } from './bmi.directive';
import { TemperatureDirective } from './temperature.directive';
import { Sp02Directive } from './sp02.directive';



@NgModule({
  declarations: [
    BloodPressureDirective,
    PulseRateDirective,
    RespiratoryRateDirective,
    BmiDirective,
    TemperatureDirective,
    Sp02Directive
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BloodPressureDirective,
    PulseRateDirective,
    RespiratoryRateDirective,
    BmiDirective,
    TemperatureDirective,
    Sp02Directive
  ]
})
export class DirectivesModule { }
