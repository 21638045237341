import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  GetVideoLink,
  GetVideoLinkFailure,
  GetVideoLinkSuccess,
} from './video-call.actions';
import { map, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';

@Injectable()
export class VideoCallEffects {
  constructor(
    private actions$: Actions,
    private commsService: CommunicationService
  ) {}

  getVideoLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetVideoLink),
      switchMap((params) => {
        console.log('effect running', params);
        return this.commsService
          .getVideoCallUrl(params)
          .pipe(
            map((res) =>
              res.token ? GetVideoLinkSuccess(res) : GetVideoLinkFailure()
            )
          );
      })
    )
  );
}
