import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PaymentStatus } from 'kiosk-service-sdk';
import { DefaultService } from 'proxy-sdk';
import { catchError, EMPTY, map, of, switchMap, tap } from 'rxjs';
import {
  fetchBeneficiaryBills,
  fetchBills,
  fetchWallets,
  fetchingBills,
  fetchingWallets,
  initPayment,
  payWithWallet,
  payWithWalletBeneficiary,
  storeBeneficiaryBills,
  storeBills,
  storeInitPaymentResp,
  storeWallets,
} from './bill.actions';
import { Browser } from '@capacitor/browser';
import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable()
export class BillsEffect {
  constructor(
    private actions$: Actions,
    private billsService: DefaultService,
    private store: Store,
    private router: Router
  ) {}
  fetchBills$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchBills),
      tap(() => {
        LoadingService.showLoading();
        this.store.dispatch(fetchingBills({ status: true }));
      }),
      switchMap((filter) =>
        this.billsService
          .financeControllerGetBills({
            startDate: filter.startDate.toISOString(),
            endDate: filter.endDate.toISOString(),
            status: 'PENDING' as any,
          })
          .pipe(
            map((resp) => {
              LoadingService.endLoading({
                title: 'Bills',
                text: 'Bills fetched successfully',
                icon: 'success',
                duration: 2000,
              });
              return storeBills({ groupedBills: resp || [] });
            }),
            catchError((e) => {
              LoadingService.endLoading({
                title: 'Bills',
                text: 'Bills fetching failed',
                icon: 'error',
                iconColor: '',
                duration: 2000,
              });

              return of(storeBills({ groupedBills: [] }));
            })
          )
      ),
      tap(() => {
        this.store.dispatch(fetchingBills({ status: false }));
      })
    );
  });
  fetchBeneficiaryBills$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchBeneficiaryBills),
      tap(() => {
        this.store.dispatch(fetchingBills({ status: true }));
      }),
      switchMap((filter) =>
        this.billsService
          .financeControllerGetBills(
            {
              startDate: filter.startDate.toISOString(),
              endDate: filter.endDate.toISOString(),
              status: 'PENDING' as any,
            },
            filter.hospitalNumber
          )
          .pipe(
            map((resp) => storeBeneficiaryBills({ groupedBills: resp || [] })),
            catchError((e) => of(storeBeneficiaryBills({ groupedBills: [] })))
          )
      ),
      tap(() => {
        this.store.dispatch(fetchingBills({ status: false }));
      })
    );
  });

  fetchWallets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchWallets),
      tap(() => {
        this.store.dispatch(fetchingWallets({ status: true }));
      }),
      switchMap((filter) =>
        this.billsService.financeControllerFetchWallets().pipe(
          map((resp) => storeWallets({ wallets: resp['list'] || [] })),
          catchError((e) => of(storeWallets({ wallets: [] })))
        )
      ),
      tap(() => {
        this.store.dispatch(fetchingWallets({ status: false }));
      })
    );
  });

  initPayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initPayment),
      switchMap((data) =>
        this.billsService
          .financeControllerInitiatePayment(data.paymentInfo)
          .pipe(
            map((resp) => {
              if (resp.link) {
                Browser.open({ url: resp.link });
                this.router.navigateByUrl('/home');
              }
              return storeInitPaymentResp({ paymentInfo: resp });
            })
          )
      )
    );
  });

  payWithWallet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(payWithWallet),
      switchMap((data) =>
        this.billsService.financeControllerChargeWallet(data.paymentInfo).pipe(
          map((resp) => {
            if (resp) {
              console.log(resp);
              this.store.dispatch(fetchWallets());
            }
            return fetchBills({
              startDate: dayjs().subtract(6, 'months').toDate(),
              endDate: dayjs().toDate(),
            });
          })
        )
      )
    );
  });

  payWithWalletBeneficiary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(payWithWalletBeneficiary),
      switchMap((data) =>
        this.billsService.financeControllerChargeWallet(data.paymentInfo).pipe(
          map((resp) => {
            if (resp) {
              console.log(resp);
              this.store.dispatch(fetchWallets());
            }
            return fetchBeneficiaryBills({
              hospitalNumber: data.hospital_number,
              startDate: dayjs().subtract(6, 'months').toDate(),
              endDate: dayjs().toDate(),
            });
          })
        )
      )
    );
  });
}
