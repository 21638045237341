import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  TwilioService,
  FirebaseService as SalFirebaseService,
} from 'sdk/notification/v1';
import { NewAuthService } from './new-auth.service';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { JoinMeeting } from '../store/video-call/video-call.actions';
import { MatDialog } from '@angular/material/dialog';
import { IncomingCallComponent } from '../components/incoming-call/incoming-call.component';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  sal_deviceId_key = 'sal_deviceId';
  sal_deviceId_time_key = 'sal_deviceId_time';
  sal_meeting_key = 'sal_meeting';
  deviceId = this.generateDeviceId();
  events = new BehaviorSubject<any>({});
  socketEvents = new BehaviorSubject<any>({});
  actionsEvents = new BehaviorSubject<'END_CALL' | 'ACCEPT_CALL' | 'DEFAULT'>(
    'DEFAULT'
  );
  generalEvents = new BehaviorSubject<
    'DEFAULT' | 'ON_A_CALL' | 'RINGING' | 'DIALING'
  >('DEFAULT');
  onACall = false;
  activeMeetingUUID?: string;
  activeMeetingData: any;

  private _ws?: WebSocket;

  constructor(
    private router: Router,
    private twilioService: TwilioService,
    private salFirebaseService: SalFirebaseService,
    private authService: NewAuthService,
    private store: Store,

    private dialog: MatDialog
  ) {
    this.checkExistingMeeting();
    this.socketEvents.subscribe({
      next: (val) => {
        switch (val?.event) {
          case 'VIDEO_CALL':
            if (this.generalEvents.value !== 'DEFAULT') return;
            this.dialog.open(IncomingCallComponent, {
              data: {
                callerName: val.data?.from,
                callerId: val.data?.id,
                callerImage: val.data?.image,
                meetingUUID: val.data?.uuid,
              },
            });
            this.generalEvents.next('RINGING');
            break;
          case 'REJECTED':
            this.resetEvents();
            break;
          case 'ENDED':
            this.resetEvents();
            break;
          default:
            break;
          // case 'ACCEPTED':
          //   this.storeLastMeetingInfo({
          //     uuid: val?.uuid,
          //     time: new Date().toISOString(),
          //   });
          //   this.joinMeeting(val['uuid']);
        }
      },
    });

    this.actionsEvents.subscribe({
      next: (val) => {
        switch (val) {
          case 'ACCEPT_CALL':
            this.router.navigate(['/chat/lib']);
            break;
          case 'END_CALL':
            this.resetEvents();
            break;
          default:
            break;
        }
      },
    });
  }

  resetEvents() {
    this.actionsEvents.next('DEFAULT');
    this.generalEvents.next('DEFAULT');
    this.socketEvents.next(undefined);
  }

  checkExistingMeeting() {
    if (this.lastMeetingInfo.uuid) {
      if (confirm('You have an existing call. Do you want to rejoin?')) {
        this.joinMeeting(this.lastMeetingInfo.meetingId, this.lastMeetingInfo.participantType);
      }
    } else {
      this.storeLastMeetingInfo({});
    }
  }
  get patient() {
    return this.authService.patient$.value;
  }
  get patientName(): string {
    return `${this.patient.fname} ${this.patient.lname}`;
  }

  generateDeviceId(): string {
    const existingId = localStorage.getItem(this.sal_deviceId_key);
    if (existingId) {
      return existingId;
    }

    const id =
      'CLIENT-' + Math.random().toString(36).slice(2, 9) + new Date().getTime();

    localStorage.setItem(this.sal_deviceId_key, id);
    localStorage.setItem(
      this.sal_deviceId_time_key,
      new Date().getTime().toString()
    );
    return id;
  }

  set ws(val: WebSocket | undefined) {
    this._ws = val;
  }

  get ws() {
    return this._ws;
  }

  storeLastMeetingInfo(meeting: any) {
    localStorage.setItem(this.sal_meeting_key, JSON.stringify(meeting));
  }

  get lastMeetingInfo() {
    return JSON.parse(localStorage.getItem(this.sal_meeting_key) || '{}');
  }

  setupRoom() {
    const meetingName = `${this.patientName} ${Date.now().toString()}`;
    return this.twilioService.twilioCreateMeetingPost({
      hostId: this.patient.id,
      meetingName,
    });
  }

  joinMeeting(meetindId: string, participantType: 'guest' | 'host', platform : 'twilio' | 'zego' = 'zego') {
    let meetingData = {
      meetingId: meetindId,
      attendantId: this.patientName,
      participantType
    }
    this.storeLastMeetingInfo(meetingData)
    if(platform === 'twilio'){

    
    this.store.dispatch(
      JoinMeeting(meetingData)
    );
    this.router.navigate(['/chat/lib']);
  } else if(platform === 'zego') {
    this.router.navigateByUrl(
      `/chat/calling?localParticipantType=guest&meetingUUID=${meetingData.meetingId}`
    );

  }
}
}
