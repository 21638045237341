<div>
  <div class="calender-header">
    <div style="flex: 1; text-align: center">
        <button mat-button [matMenuTriggerFor]="monthDropDown"
        *ngIf="showDropdown">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      <mat-menu #monthDropDown="matMenu" yPosition="below">
        <button
          mat-menu-item
          *ngFor="let monthDate of showMonths()"
          (click)="setMonth(monthDate)"
        >
          {{ monthDate | date : "MMMM Y" }}
        </button>
      </mat-menu>
      {{ currentDate | date : "MMMM Y" }}
    </div>
    <div>
      <mat-icon
        [style.color]="canGoBack() ? '#FAFCFE' : '#78818A'"
        (click)="prevMonth()"
        >arrow_back_ios</mat-icon
      >
      <mat-icon
        [style.color]="canGoForward() ? '#FAFCFE' : '#78818A'"
        (click)="nextMonth()"
        >arrow_forward_ios</mat-icon
      >
    </div>
  </div>
  <div class="calender-body">
    <div class="calender-week-title" *ngFor="let x of daysOfTheWeeks">
      {{ x }}
    </div>
    <div
      class="calender-week-title"
      *ngFor="let x of generateRange(currentDate.getDay())"
    ></div>
    <div
      class="calender-date-item"
      [class.active]="isDateEqual(getDate(i + 1), this.selectedDate)"
      [class.today]="isDateEqual(getDate(i + 1), this.today)"
      (click)="greaterThanToday(i + 1) ? selectDate(i + 1) : ''"
      *ngFor="let x of generateRange(getDaysInMonth()); let i = index"
    >
      {{ i + 1 < 10 ? "0" + (i + 1) : i + 1 }}
    </div>
  </div>
</div>
