import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService as SalNotificationService } from 'sdk/notification/v1';
import { environment } from 'src/environments/environment';
import { GeneralService } from './general.service';
import { MatDialog } from '@angular/material/dialog';
import { IncomingCallComponent } from '../components/incoming-call/incoming-call.component';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class NativeSocketService {

// Generate a new UUID (v4)
  newUuid: string = uuidv4();
  socket!: WebSocket;
  socketEvents = new BehaviorSubject<Record<string, any>>({});
  socketHolder = new BehaviorSubject<WebSocket | null>(null);

  constructor(
    private salNotificationService: SalNotificationService,
    private generalService: GeneralService
  ) {}

  sendMessage(data: any){
    console.log(this.socket)
    this.socket.send(data);
  }

  connectSocket = (
    room: string,
    participantId: string = '',
    isLocal: boolean = false
  ) => {
    let interval: any;
    this.socket = new WebSocket(
      `${environment.NOTIFICATION_SOCKET_URL_V2}/${this.newUuid}$${room}`
    );
    console.log('soooocccckkkeeet', this.socket);
    this.socketHolder.next(this.socket);
    this.socket.onopen = () => {
      this.socket.send(JSON.stringify({ message: 'ping' }));

      interval = setInterval(() => {
        this.socket.send(JSON.stringify({ message: 'ping' }));
      }, 30000);
    };

    this.socket.onmessage = (event) => {
      try {
        const parsedMessage = JSON.parse(event.data);
        if (parsedMessage.data.id !== participantId) {
          this.generalService.socketEvents.next(parsedMessage);
        }
      } catch {}
    };
    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      clearInterval(interval);
    };

    this.socket.onclose = async (closeEvent) => {
      console.log('"CLOSED SOCKET', closeEvent.timeStamp);
      setTimeout(() => {
        clearInterval(interval);

        this.connectSocket(room, participantId, isLocal);
      });
    };
    return;
  };
}

export interface ISocketData {
  event?:
    | 'RINGING'
    | 'ACCEPTED'
    | 'REJECTED'
    | 'ENDED'
    | 'PINGING'
    | 'VIDEO_CALL';
  data?: any;
}
