import { createReducer, on } from '@ngrx/store';
import { VitalsDto } from 'proxy-sdk';
import { IVitalField } from 'src/app/pages/vitals/vitals-field';
import {
  AfterAddingVitalsEnum,
  addVitalsFailure,
  addVitalsSuccess,
  fetchedVital,
  fetchedVitals,
  loadingVitals,
  setVitalFields,
  totalVitalsHistory,
} from './vitals.actions';

const vitalsReducer = createReducer(
  [] as VitalsDto[],
  on(fetchedVitals, (state, props) => {
    return props.vitals;
  })
);
const vitalReducer = createReducer(
  {} as VitalsDto,
  on(fetchedVital, (state, props) => {
    return props.vital;
  })
);
const totalVitalsHistoryReducer = createReducer(
  0,
  on(totalVitalsHistory, (state, props) => {
    return props.total;
  })
);
const fetchingVitalsReducer = createReducer(
  false,
  on(loadingVitals, (state, props) => {
    return props.loading;
  })
);

const selectedFieldsReducer = createReducer(
  [] as IVitalField[],
  on(setVitalFields, (state, props) => {
    return props.fields;
  })
);

const afterAddingVitalsReducer = createReducer(
  { state: AfterAddingVitalsEnum.DEFAULT, isFromAntenatal: false },
  on(addVitalsSuccess, ( props) => {
    return props;
  }),
  on(addVitalsFailure, ( props) => {
    return props;
  })
);

export const vitalsReducers = {
  vital: vitalReducer,
  vitals: vitalsReducer,
  selectedFields: selectedFieldsReducer,
  fetchingVitals: fetchingVitalsReducer,
  totalVitalsHistory: totalVitalsHistoryReducer,
  afterAddingVitals: afterAddingVitalsReducer,
};

export interface VitalsReducers {
  vitals: VitalsDto[];
  selectedFields: IVitalField[];
  vital: VitalsDto;
  fetchingVitals: boolean;
  totalVitalsHistory: number;
  afterAddingVitals: { state: AfterAddingVitalsEnum };
}
