/**
 * Care App Version 2 Backend
 * This is the version of the care app that is built on Nestjs and PostgreSQL
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Report } from './report';
import { Complaint } from './complaint';


export interface ComplaintDetail { 
    id: number;
    report: Report;
    reportId: number;
    complaint: Complaint;
    complaintId?: number;
    status?: object;
    rating?: number;
    updated_by_full_name?: string;
    updated_by_staff_id?: string;
    completed_at?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

