/**
 * Home Clinic Mobile
 * Home Clinic Mobile App Proxy Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientDataNextOfKin } from './patientDataNextOfKin';
import { PatientDataHmo } from './patientDataHmo';
import { AdditionalHistory } from './additionalHistory';


export interface PatientData { 
    _id?: string;
    fname: string;
    lname: string;
    oname: string;
    sex: string;
    email: string;
    date_of_birth: string;
    isConfirmedDateOfBirth?: boolean;
    address: string;
    phoneNumber: string;
    altPhoneNumber: string;
    hospital_number: string;
    old_hospital_number?: string;
    isAlive?: boolean;
    familyId?: string;
    maritalStatus: string;
    stateOfOrigin: string;
    localGovernment: string;
    tribe: string;
    religion: string;
    occupation: string;
    imageUrl: string;
    genotype: string;
    bloodGroup: string;
    nextOfKin: PatientDataNextOfKin;
    hmo: PatientDataHmo;
    beneficiaryId: string;
    userGroupId: string;
    registeredByFirstName: string;
    registeredByLastName: string;
    registeredByUserName: string;
    isAdmitted: boolean;
    additionalHistories: Array<AdditionalHistory>;
    created_at: string;
    updated_at: string;
    slug: string;
    __v: number;
    expirationDate: string;
    failed_pin_attempts: number;
    uid: string;
    pin: string;
}

