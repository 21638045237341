<div class="main" *ngIf="isCalling">
  <sal-incoming-call
    (call-action)="handleCallAction($event)"
    [config]="{
      audio,
      image: data.callerImage || '/assets/images/avatar.png',
      name: data.callerName
    }"
  ></sal-incoming-call>
</div>
