import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading = new BehaviorSubject(false);
  timeOuts: { key: string; timeOut: any }[] = [];

  constructor() {}

  showLoading() {
    const key = new Date().getTime();
    // Show loading state
    Swal.fire({
      text: 'Loading',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    // Simulate asynchronous operation
    this.timeOuts.push({
      key: key.toString(),
      timeOut: setTimeout(() => {
        // Hide loading state and show result
        if (this.loading.value) {
          this.simpleEndLoading();
        }
        clearTimeout(
          this.timeOuts.find((val) => val.key === key.toString())?.timeOut
        );
      }, 3000),
    });
  }

  endLoading({
    title,
    text,
    icon,
    iconColor = '#0074f0',
    duration = 500,
  }: {
    title: string;
    text: string;
    icon: SweetAlertIcon;
    iconColor?: string;
    duration?: number;
  }) {
    Swal.fire({
      title,
      text,
      icon,
      iconColor,
      showConfirmButton: true,
      timer: duration,
    });
    this.loading.next(false);
  }

  simpleEndLoading({ title }: { title: string } = { title: 'Loading' }) {
    Swal.fire({
      text: title,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timer: 1500,
      willOpen: () => {
        Swal.showLoading();
      },
    });
  }

  static showLoading() {
    // Show loading state
    Swal.fire({
      title: 'Loading',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    // Simulate asynchronous operation
    setTimeout(() => {
      // Hide loading state and show result
    }, 2000);
  }

  static endLoading({
    title,
    text,
    icon,
    iconColor = '#0074f0',
    duration = 500,
  }: {
    title: string;
    text: string;
    icon: SweetAlertIcon;
    iconColor?: string;
    duration?: number;
  }) {
    Swal.fire({
      title,
      text,
      icon,
      iconColor,
      showConfirmButton: true,
      timer: duration,
    });
  }
}
