import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { VitalsService } from 'proxy-sdk';
import { catchError, map, of, switchMap } from 'rxjs';
// import { AuthService } from "src/app/services/auth.service";
import {
  addVital,
  addVitalsFailure,
  addVitalsSuccess,
  AfterAddingVitalsEnum,
  fetchedVital,
  fetchedVitals,
  fetchVital,
  fetchVitals,
  loadingVitals,
  totalVitalsHistory,
} from './vitals.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class VitalsEffect {
  constructor(
    private action$: Actions,
    private store: Store,
    // private authService: AuthService,
    private vitalsService: VitalsService,
    private snackbar: MatSnackBar
  ) {}

  fetchVitalsService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fetchVitals),
      switchMap((data) => {
        this.store.dispatch(loadingVitals({ loading: true }));
        return this.vitalsService
          .vitalsControllerFetchVital(
            {} as any,
            undefined,
            '-created_at',
            data.page,
            data.limit
          )
          .pipe(
            map((data) => {
              this.store.dispatch(loadingVitals({ loading: false }));
              this.store.dispatch(
                totalVitalsHistory({ total: data.count as any })
              );
              return fetchedVitals({ vitals: data.data ?? [] });
            }),
            catchError((error) => {
              this.store.dispatch(loadingVitals({ loading: false }));
              return of(fetchedVitals({ vitals: [] }));
            })
          );
      })
    );
  });

  fetchVitalService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fetchVital),
      switchMap((prop) => {
        this.store.dispatch(loadingVitals({ loading: true }));
        return this.vitalsService
          .vitalsControllerFetchVital(
            { _id: prop._id } as any,
            undefined,
            '-created_at',
            0,
            1
          )
          .pipe(
            map((data) => {
              this.store.dispatch(loadingVitals({ loading: false }));
              this.store.dispatch(
                totalVitalsHistory({ total: data.count as any })
              );
              return fetchedVital({ vital: data.data![0] });
            }),
            catchError((error) => {
              this.store.dispatch(loadingVitals({ loading: false }));
              return of(fetchedVital({ vital: {} as any }));
            })
          );
      })
    );
  });

  addVitalsService$ = createEffect(() => {
    return this.action$.pipe(
      ofType(addVital),
      switchMap((d) =>
        this.vitalsService.vitalsControllerAddOneVital(d.vital).pipe(
          map((data) => {
            this.store.dispatch(fetchVitals({ page: 0, limit: 50 }));
            this.store.dispatch(
              addVitalsSuccess({ isFromAntenatal: d.isFromAntenatal })
            );
            this.snackbar.open('Vitals added successsully', 'SUCCESS');
            setTimeout(
              () =>
                this.store.dispatch(
                  addVitalsSuccess({ isFromAntenatal: d.isFromAntenatal })
                ),
              5000
            );
            return d.isFromAntenatal ? fetchedVital({vital: {} as any}) : fetchedVital({ vital: data });
          }),
          catchError((error) => {
            this.snackbar.open(error.error.message ?? error.message, 'ERROR');
            this.store.dispatch(
              addVitalsFailure({ isFromAntenatal: d.isFromAntenatal })
            );
            setTimeout(() => {
              this.store.dispatch(
                addVitalsFailure({ isFromAntenatal: d.isFromAntenatal })
              ),
                5000;
            });
            return of(fetchedVital({ vital: {} as any }));
          })
        )
      )
    );
  });
}
