import { createAction, props } from "@ngrx/store";
import { VisitorRequestDto } from "proxy-sdk";

export const fetchVisitor = createAction(
  'fetchVisitor',
  props<{ _id: string; }>()
);

export const fetchVisitors = createAction(
  'fetchVisitors',
  props<{ page: number; limit: number; }>()
);

export const totalVisitors = createAction(
  'totalVisitors',
  props<{ total: number; }>()
);

export const loadingVisitors = createAction(
  'total',
  props<{ loading: boolean; }>()
);

export const searchVisitors = createAction(
  'searchVisitors',
  props<{ page: number; limit: number; searchString: string; }>()
);

export const fetchedVisitors = createAction(
  'fetchedVisitors',
  props<{ visitors: VisitorRequestDto[]; }>()
);

export const singleVisitor = createAction(
  'singleVisitor',
  props<{ visitor: VisitorRequestDto; }>()
);

export const generateVisitorCode = createAction(
  'generateVisitorCode',
  props<{ visitor: VisitorRequestDto; }>()
);

export const generatingVisitorCodeStatus = createAction(
  'generatingVisitorCodeStatus',
  props<{ loading: boolean; }>()
);