/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelsVideoRoom { 
    /**
     * When set to true, indicates that the participants in the room will only publish audio. No video tracks will be allowed. Group rooms only.
     */
    audio_only?: boolean;
    date_created?: string;
    date_updated?: string;
    duration?: number;
    empty_room_timeout?: number;
    end_time?: string;
    max_participant_duration?: number;
    /**
     * The maximum number of concurrent Participants allowed in the room.
     */
    max_participants?: number;
    record_participants_on_connect?: boolean;
    /**
     * The unique string that we created to identify the Room resource.
     */
    sid?: string;
    status?: string;
    type?: string;
    unique_name?: string;
    unused_room_timeout?: number;
}

